import { useValueSelector } from "../../utils/hooks";
import { MetadataObject } from "../../utils/mongoSchema";
import { selectMetadataValue, zipMetadata } from "../../utils/schemaMetadata";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SocialQuestions from "./SocialQuestions";

function DateQuestions() {
	const [serviceMetadata, setServiceMetadata] = useValueSelector({
		defaultValue: () => {
			return [] as MetadataObject[];
		},
		venueValue: (venueData) => {
			return [];
		},
		vendorValue: (vendorData) => {
			return vendorData.vendorMetadata;
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(
					prevValue.vendorMetadata,
					partialValue
				)
			};
		},
		updateVenueValue: (prevValue, partialValue) => {
			return prevValue;
		}
	});

	const VENDOR_SERVICE_DATE = selectMetadataValue(serviceMetadata, "VENDOR_SERVICE_DATE", (new Date()).toISOString()) as string;

	const dateValue = (inputVal: string | Date) => {
		const parsedInputDate = new Date(inputVal);
		const dateElements = [
			parsedInputDate.getFullYear(),
			(parsedInputDate.getMonth() + 1).toString().padStart(2, "0"),
			(parsedInputDate.getDate()).toString().padStart(2, "0")
		];
		return dateElements.join("-");
	};

	return (
		<>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>
						Since when are you providing services?
					</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					fullWidth
					type={"date"}
					value={
						VENDOR_SERVICE_DATE ?
							dateValue(VENDOR_SERVICE_DATE)
							:
							dateValue(new Date())
					}
					onChange={(e) => {
						setServiceMetadata([
							...serviceMetadata,
							{
								propertyName: "VENDOR_SERVICE_DATE",
								propertyType: "STRING",
								propertyValue: (new Date(e.target.value)).toISOString()
							}
						]);
					}}
				/>
			</Grid>
		</>
	);
}

export function CommonVendorQuestions() {
	return (
		<>
			<SocialQuestions />
			<Grid item xs={12}>
				<Divider sx={{ my: 2 }} />
			</Grid>
			<DateQuestions />
			<Grid item xs={12}>
				<Divider sx={{ my: 2 }} />
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>
						Add or Update Pricing Details
					</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Link to={"/dashboard/pricing"}>
					Go to Pricing Page
				</Link>
			</Grid>
		</>
	);
}