import { Fragment, useContext, useEffect, useState } from "react";
import ServiceContext from "../../utils/serviceContext";
import { MetadataObject, VendorQuestionnaireSchema } from "../../utils/mongoSchema";
import { makeAPIRequest } from "../../utils/apiHandler";
import { GetVendorQuestionnaireQuery, NoParams } from "../../utils/apiRequests";
import { GetVendorQuestionnaireResponse } from "../../utils/apiResponses";
import { Box, Divider, Grid } from "@mui/material";
import { BoolSelectQuestion } from "../questionnaire/BoolSelect";
import { NumericSelectQuestion } from "../questionnaire/NumericSelect";
import { TextSelectQuestion } from "../questionnaire/TextSelect";
import { NumericInputQuestion } from "../questionnaire/NumericInput";
import { TextInputQuestion } from "../questionnaire/TextInput";
import { TextMultipleQuestion } from "../questionnaire/TextMultiple";
import { useValueSelector } from "../../utils/hooks";
import { zipMetadata } from "../../utils/schemaMetadata";
import { NumericMultipleQuestion } from "../questionnaire/NumericMultiple";
import { BitFieldQuestion } from "../questionnaire/BitField";
import { ServiceTypes } from "../questionnaire/ServiceTypes";
import { CommonVendorQuestions } from "./CommonVendorQuestions";

type VendorQuestionProps = Omit<VendorQuestionnaireSchema, "vendorCategory" | "vendorSubcategory">

function VendorQuestion(props: VendorQuestionProps) {
	const {
		questionType,
		vendorMetadataType,
		vendorMetadataDependencies,
		vendorMetadataKey,
		vendorMetadataDefaultValue,
		questionText,
		questionOptions
	} = props;

	const [zipComplete, setZipComplete] = useState(false);

	const [vendorMetadata, setVendorMetadata] = useValueSelector({
		defaultValue: () => {
			return [] as MetadataObject[];
		},
		vendorValue: (vendorData) => {
			return vendorData.vendorMetadata;
		},
		venueValue: () => {
			return [];
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue)
			};
		},
		updateVenueValue: (prevValue, partialValue) => {
			return prevValue;
		}
	});

	useEffect(() => {
		const zippedMetadata = [
			// @ts-ignore
			{
				propertyName: vendorMetadataKey,
				propertyType: vendorMetadataType,
				propertyValue: vendorMetadataDefaultValue
			},
			...vendorMetadata
			// Will overwrite with existing value
		];
		// @ts-ignore
		setVendorMetadata(zippedMetadata);
		setZipComplete(true);
	}, []);

	if (!zipComplete) {
		return null;
	}

	const metadataDependenciesMet = vendorMetadata.every((metadataObj) => {
		const { propertyName, propertyValue } = metadataObj;
		const isDependency = vendorMetadataDependencies.some((value) => {
			const { dependencyKey } = value;
			if (dependencyKey === propertyName) {
				return true;
			}
			return false;
		});
		if (!isDependency) {
			return true;
			// Non-deps are assumed true
		}
		const dependencyState = vendorMetadataDependencies.find((dependencyObj) => {
			return dependencyObj.dependencyKey === propertyName;
		});
		if (dependencyState === undefined) {
			return false;
		}
		return (dependencyState.dependencyValue === propertyValue);

	});

	if (!metadataDependenciesMet) {
		return null;
	}

	if (questionType === "BOOL_SELECT") {
		return (
			<>
				<BoolSelectQuestion
					key={vendorMetadataKey}
					questionType={"BOOL_SELECT"}
					vendorMetadataType={"BOOLEAN"}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as boolean}
					questionOptions={questionOptions}
					questionText={questionText}
					vendorMetadataDependencies={vendorMetadataDependencies}
				/>
				<Grid item xs={12} >
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}

	if (questionType === "NUMERIC_SELECT") {
		return (
			<>
				<NumericSelectQuestion
					key={vendorMetadataKey}
					questionText={questionText}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDependencies={vendorMetadataDependencies}
					questionOptions={questionOptions}
					questionType={"NUMERIC_SELECT"}
					vendorMetadataType={"NUMBER"}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as number}
				/>
				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}

	if (questionType === "TEXT_SELECT") {
		return (
			<>
				<TextSelectQuestion
					key={vendorMetadataKey}
					questionText={questionText}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDependencies={vendorMetadataDependencies}
					questionOptions={questionOptions}
					questionType={"TEXT_SELECT"}
					vendorMetadataType={"STRING"}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as string}
				/>
				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}

	if (questionType === "NUMERIC_INPUT") {
		return (
			<>
				<NumericInputQuestion
					key={vendorMetadataKey}
					questionText={questionText}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDependencies={vendorMetadataDependencies}
					questionOptions={[]}
					questionType={"NUMERIC_INPUT"}
					vendorMetadataType={"NUMBER"}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as number}
				/>
				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}

	if (questionType === "TEXT_INPUT") {
		return (
			<>
				<TextInputQuestion
					key={vendorMetadataKey}
					questionText={questionText}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDependencies={vendorMetadataDependencies}
					questionOptions={[]}
					questionType={"TEXT_INPUT"}
					vendorMetadataType={"STRING"}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as string}
				/>
				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}

	if (questionType === "TEXT_MULTIPLE") {
		return (
			<>
				<TextMultipleQuestion
					key={vendorMetadataKey}
					questionText={questionText}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDependencies={vendorMetadataDependencies}
					questionOptions={[]}
					questionType={"TEXT_MULTIPLE"}
					vendorMetadataType={"ARRAY_STRING"}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as string[]}
				/>
				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}

	if (questionType === "NUMERIC_MULTIPLE") {
		return (
			<>
				<NumericMultipleQuestion
					key={vendorMetadataKey}
					questionText={questionText}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDependencies={vendorMetadataDependencies}
					questionOptions={[]}
					questionType={"NUMERIC_MULTIPLE"}
					vendorMetadataType={"ARRAY_NUMBER"}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as number[]}
				/>
				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}

	if (questionType === "BITFIELD") {
		return (
			<>
				<BitFieldQuestion
					questionText={questionText}
					vendorMetadataKey={vendorMetadataKey}
					vendorMetadataDependencies={vendorMetadataDependencies}
					questionOptions={questionOptions}
					questionType={"BITFIELD"}
					vendorMetadataType={"NUMBER"}
					vendorMetadataDefaultValue={vendorMetadataDefaultValue as number}
				/>
				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
				</Grid>
			</>
		);
	}
	return null;
}

export function VendorQuestionnaire() {
	const { serviceInfo } = useContext(ServiceContext);
	const { serviceType, serviceData } = serviceInfo;

	const [vendorQuestions, setVendorQuestions] = useState<Omit<VendorQuestionnaireSchema, "vendorCategory" | "vendorSubcategory">[]>([]);

	useEffect(() => {
		if (serviceType !== "VENDOR") {
			return;
		}

		const fetchQuestions = async () => {
			const { vendorCategory, vendorSubcategory } = serviceData;
//   console.log(vendorCategory, vendorSubcategory,"serviceData Type")
			const {
				isSuccess,
				isError,
				requestError,
				responseData,
				responseCode
			} = await makeAPIRequest<GetVendorQuestionnaireResponse, NoParams, NoParams, GetVendorQuestionnaireQuery>({
				requestMethod: "GET",
				requestEndpoint: "/dashboard/vendor-questionnaire",
				queryParams: {
					vendorCategory: vendorCategory,
					vendorSubcategory: vendorSubcategory
				}
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { vendorQuestions: responseQuestions } = responseData;
					setVendorQuestions(responseQuestions);
				}
			}
		};

		fetchQuestions();
	}, [
		serviceData ?
			serviceType === "VENDOR" ?
				serviceData.vendorSlug :
				serviceData.venueSlug
			: null,
		serviceType
	]);

	if (serviceType === "VENUE" || serviceType === null) {
		return null;
	}

	return (
		<Box sx={{ flexGrow: 1 }} style={{width:"100%"}}>
			<Grid container spacing={2} sx={{ alignItems: "center", width:"100%" }}>
				<ServiceTypes />
				{
					vendorQuestions.map((questionData, questionIdx) => {
						return (
							<Fragment key={`${questionIdx}-${questionData.vendorMetadataKey}-group`}>
								<VendorQuestion
									key={`${questionIdx}-${questionData.vendorMetadataKey}`}
									{...questionData}
								/>
							</Fragment>
						);
					})
				}
				<CommonVendorQuestions />
			</Grid>
		</Box>
	);
}