import { Typography } from "@mui/material";
import React from "react";

type RequiredTextProps = {
	children?: React.ReactNode
}

export function RequiredText({ children }: RequiredTextProps) {
	return (
		<Typography>
			{children}
			<b style={{ color: "red" }}>
				*
			</b>
		</Typography>
	);
}