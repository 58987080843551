import React, { useEffect, useMemo, useState } from "react";
import { API_HOST, API_VERSION, useAPIRequest } from "../../utils/apiHandler";
import { Avatar, Box, Button, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { stringAvatar } from "./ChatCard";
import { useTheme } from "@mui/material/styles";
import Iconify from "../../components/iconify";
import { io } from "socket.io-client";
import { GetConversationMessagesResponse } from "../../utils/apiResponses";
import { Conversation } from "./Chat";

type Message = GetConversationMessagesResponse["conversationMessages"][number]

function ServiceMessage({ messageContent, messageTimestamp, serviceName }: {
	messageContent: Message["messageData"]["messageContent"],
	messageTimestamp: Message["messageData"]["messageTimestamp"],
	serviceName: Conversation["conversationData"]["serviceName"]
}) {
	const theme = useTheme();

	const messageDate = new Date(messageTimestamp);

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
			gap: 1
		}}>
			<Box sx={{
				display: "flex",
				gap: 1,
				flexDirection: "row-reverse",
				width: "100%"
			}}>
				<Avatar {...stringAvatar(serviceName)} />
				<Box sx={{
					border: "1px solid grey",
					borderRadius: 2,
					backgroundColor: theme.palette.background.default,
					maxWidth: "75%",
					overflowWrap: "break-word",
					padding: 1
				}}>
					<Typography>
						{messageContent}
					</Typography>
				</Box>
			</Box>
			<Typography color={theme.palette.text.secondary} align={"right"}>
				{messageDate.toLocaleString("en-IN")}
			</Typography>
		</Box>
	);
}

function UserMessage({ messageContent, messageTimestamp, userName }: {
	messageContent: Message["messageData"]["messageContent"],
	messageTimestamp: Message["messageData"]["messageTimestamp"],
	userName: Conversation["conversationData"]["userName"]
}) {
	const theme = useTheme();

	const messageDate = new Date(messageTimestamp);

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
			gap: 1
		}}>
			<Box sx={{
				display: "flex",
				gap: 1,
				flexDirection: "row",
				width: "100%"
			}}>
				<Avatar {...stringAvatar(userName)} />
				<Box sx={{
					border: "1px solid grey",
					borderRadius: 2,
					backgroundColor: theme.palette.info.light,
					maxWidth: "75%",
					overflowWrap: "break-word",
					padding: 1
				}}>
					<Typography>
						{messageContent}
					</Typography>
				</Box>
			</Box>
			<Typography color={theme.palette.text.secondary} align={"left"}>
				{messageDate.toLocaleString("en-IN")}
			</Typography>
		</Box>
	);
}

function ChatScreen({ conversationId, conversationData }: Conversation) {
	const {
		userMail,
		userName,
		serviceType,
		serviceSlug,
		serviceMail,
		serviceName,
		serviceLastReadMessage,
		userLastReadMessage,
		conversationStatus
	} = conversationData;

	const [messageText, setMessageText] = useState("");
	const [messageFetchComplete, setMessageFetchComplete] = useState(false);
	const [chatMessages, setChatMessages] = useState<Message[]>([]);
	const [socketConnected, setSocketConnected] = useState(false);
	const [messageQueue, setMessageQueue] = useState<string[]>([]);

	const theme = useTheme();

	const {
		isLoading,
		isSuccess,
		isError,
		responseCode,
		responseData,
		requestError
	} = useAPIRequest<GetConversationMessagesResponse>({
		requestMethod: "GET",
		requestEndpoint: "/conversations/:conversationId/messages",
		urlParams: {
			conversationId: conversationId
		},
		requestDeps: [conversationId]
	});

	const handleSendMessage = (event: any) => {
		event.preventDefault();
		if (messageText.trim() !== "") {
			setMessageQueue((messageQueue) => {
				const updatedQueue = [
					...messageQueue,
					messageText
				];
				return updatedQueue;
			});
		}
	};

	useEffect(() => {
		if (!isLoading) {
			if (isSuccess && responseData) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { conversationMessages } = responseData;
					setChatMessages(conversationMessages);
					setMessageFetchComplete(true);
				}
			}
		}
	}, [isLoading, isSuccess, responseData]);

	const ioHandle = useMemo(() => {
		const ioHandle = io(
			`${API_HOST}/api/${API_VERSION}/conversations/${conversationId}`,
			{
				withCredentials: true
			}
		);
		console.log("hiwo",ioHandle)
		if (messageFetchComplete) {
			ioHandle.on("connect", () => {
				setSocketConnected(true);
			});

			ioHandle.on("disconnect", () => {
				setSocketConnected(false);
			});

			ioHandle.on("connect_error", (err) => {
				console.error(err);
			});

			ioHandle.on("messageReceived", (messagePayload) => {
				const { messageData } = messagePayload;
				const { messageId } = messageData;
				delete messageData["messageId"];

				setChatMessages((prevMessages) => {
					return [
						...prevMessages,
						{
							messageId: messageId,
							messageData: messageData
						}
					];
				});

				ioHandle.emitWithAck("messageRead", {
					messageId: messageId
				}).then((ackData) => {
					const { responseStatus } = ackData;
					if (responseStatus === "SUCCESS") {
						console.debug(`Acknowledged message ${messageId} to server`);
					}
				}).catch((err) => {
					console.error(err);
				});
			});

		}

		return ioHandle;
	}, [messageFetchComplete, conversationId]);

	useEffect(() => {
		console.log("aaa gaya", ioHandle.connected)
		if (ioHandle.connected) {
			const queueCopy = [...messageQueue];
			console.log(queueCopy, "qwe")
			const firstElementOfQueue = queueCopy[0] || undefined;
			if (firstElementOfQueue === undefined) {
				return;
			}

			ioHandle.emitWithAck(
				"messageSent",
				{ messageText: firstElementOfQueue }
			).then((ackData) => {
				const { responseStatus } = ackData;
				if (responseStatus === "SUCCESS") {
					const { messageId } = ackData;
					const filteredMessages = queueCopy.slice(1);

					setMessageQueue(filteredMessages);
					setMessageText("");
					setChatMessages((prevMessages) => {
						return [
							...prevMessages,
							{
								messageId: messageId,
								messageData: {
									messageContent: firstElementOfQueue,
									messageTimestamp: (new Date()).toISOString(),
									messageAuthorType: "SERVICE",
									messageUnread: false
								}
							}
						];
					});
				}
			}).catch((err) => {
				console.error(err);
			});
		}
	}, [ioHandle, messageQueue]);

	useEffect(() => {
		return () => {
			ioHandle?.disconnect();
		};
	}, [ioHandle, conversationId]);

	useEffect(() => {
		setChatMessages([]);
		setMessageQueue([]);
		setMessageFetchComplete(false);
	}, [conversationId]);

	return (
		<Grid container spacing={2} sx={{ pr: 1 }}>
			<Grid item xs={1}>
				<Avatar
					{...stringAvatar(userName)}
				/>
			</Grid>
			<Grid item xs={10}>
				<Typography
					color={theme.palette.text.primary}
					variant={"h4"}
				>
					{userName}
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<Typography
					color={theme.palette.text.secondary}
				>
					{conversationStatus}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", overflowY: "scroll", flexDirection: "column", height: "35vh", gap: 4 }}>
					<Typography
						color={theme.palette.text.secondary}
						align={"center"}
					>
						<i>This is the start of your conversation with {userName}</i>
					</Typography>
					{
						chatMessages.map((messageObj) => {
							const { messageData } = messageObj;
							const { messageContent, messageTimestamp, messageAuthorType, messageUnread } = messageData;
							if (messageAuthorType === "SERVICE") {
								return (
									<ServiceMessage
										messageContent={messageContent}
										messageTimestamp={messageTimestamp}
										serviceName={serviceName}
									/>
								);
							} else if (messageAuthorType === "USER") {
								return (
									<UserMessage
										messageContent={messageContent}
										messageTimestamp={messageTimestamp}
										userName={userName}
									/>
								);
							} else {
								return null;
							}
						})
					}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					multiline
					placeholder={"Type your message here ..."}
					InputProps={{
						endAdornment: (
							<InputAdornment position={"end"}>
								<Button onClick={handleSendMessage} disabled={messageText.trim() === ""}>
									Send
									{/* @ts-ignore */}
									<Iconify icon={"material-symbols:send-outline"} />
								</Button>
							</InputAdornment>
						)
					}}
					value={messageText}
					onChange={(e) => {
						setMessageText(e.target.value);
					}}
				/>
			</Grid>
		</Grid>
	);
}

export default ChatScreen;
