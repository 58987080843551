import { CircularProgress, Divider, Grid, Link, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useServiceRender, useValueSelector } from "../utils/hooks";
import ServiceContext, { useInvalidField } from "../utils/serviceContext";
import { RequiredText } from "../components/common";
import { manageMedia } from "../utils/mediaHandler";
import React, { useContext, useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import LocationSelect, { LocationSelectProps } from "../sections/profile/LocationSelect";
import { checkImageFileDimensions, IMAGE_DIMENSIONS } from "../utils/commonUtils";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
//@ts-ignore
import locationIcon from "../assets/placeholder.png";
import { GpsFixed } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ProfilePage() {
	const mapRef = useRef<any>()
	const [imageLoader,setImageLoader]=useState(false)
	const [imageErrorSuccess,setImageErrorSuccess]=useState({error:false,sucess:false})


	const [inputFocused, setInputFocused] = useState(false);
	const googleMapApiKey = 'AIzaSyDDTVXtLCVdXpmm6wt03zY3eqM-KR0WtxQ';
	const icon = L.icon({
		iconUrl: locationIcon,
		iconSize: [30, 30],
		iconAnchor: [14, 30],
	});
	const {
		serviceInfo: { isPartialInfo, serviceType }
	} = useContext(ServiceContext);
	const [profileData, setBasicProfileData] = useValueSelector({
		defaultValue: () => {
			return {
				serviceSlug: "",
				serviceName: "",
				serviceDescription: "",
				serviceLocality: "",
				serviceImageObjectKey: "",
				serviceLat: 0,
				serviceLong: 0,
				serviceCoverImageURL: ""
			};
		},
		venueValue: (venueData) => {
			return {
				serviceSlug: venueData.venueSlug,
				serviceName: venueData.venueName,
				serviceDescription: venueData.venueDescription,
				serviceImageObjectKey: venueData.venueCoverImageObjectKey,
				serviceLat: venueData.venueLatitude,
				serviceLong: venueData.venueLongitude,
				// @ts-ignore
				serviceCoverImageURL: venueData.venueCoverImageURL
			};
		},
		vendorValue: (vendorData) => {
			return {
				serviceSlug: vendorData.vendorSlug,
				serviceName: vendorData.vendorName,
				serviceDescription: vendorData.vendorDescription,
				serviceImageObjectKey: vendorData.vendorCoverImageObjectKey,
				serviceLat: vendorData.vendorLatitude,
				serviceLong: vendorData.vendorLongitude,
				// @ts-ignore
				serviceCoverImageURL: vendorData.vendorCoverImageURL
			};
		},
		updateVendorValue: (prevValue, partialValue) => {
			const { serviceLat, serviceLong, serviceDescription, serviceName, serviceImageObjectKey } = partialValue;


			return {
				...prevValue,
				vendorLatitude: serviceLat,
				vendorLongitude: serviceLong,
				vendorName: serviceName,
				vendorDescription: serviceDescription,
				vendorCoverImageObjectKey: serviceImageObjectKey
			};
		},
		updateVenueValue: (prevValue, partialValue) => {
			const { serviceDescription, serviceName, serviceImageObjectKey, serviceLat, serviceLong } = partialValue;

			return {
				...prevValue,
				venueDescription: serviceDescription,
				venueName: serviceName,
				venueCoverImageObjectKey: serviceImageObjectKey,
				venueLongitude: serviceLong,
				venueLatitude: serviceLat
			};
		}
	});

	const { serviceName, serviceDescription, serviceSlug, serviceLat, serviceLong, serviceCoverImageURL } = profileData;
	const [serviceDescriptionData, setServiceDescriptionData] = useState(serviceDescription);

	const [categoryData, _ignoreSetCatgeoryData] = useValueSelector({
		defaultValue: () => {
			return {
				serviceCategory: "",
				serviceSubcategory: ""
			};
		},
		venueValue: (venueData) => {
			return {
				serviceCategory: "",
				serviceSubcategory: ""
			};
		},
		vendorValue: (vendorData) => {
			return {
				serviceCategory: vendorData.vendorCategory,
				serviceSubcategory: vendorData.vendorSubcategory
			};
		}
	});

	const categoryRender = useServiceRender({
		fallbackElement: null,
		vendorElement: (
			<>
				<Grid xs={12} md={4} item>
					<RequiredText>
						<b>Service Category</b>
					</RequiredText>
				</Grid>
				<Grid xs={12} md={8} item>
					<Select disabled value={categoryData.serviceCategory}>
						<MenuItem value={categoryData.serviceCategory}>{categoryData.serviceCategory}</MenuItem>
					</Select>
				</Grid>
				<Grid xs={12} md={4} item>
					<RequiredText>
						<b>Service Sub-Category</b>
					</RequiredText>
				</Grid>
				<Grid xs={12} md={8} item>
					<Select disabled value={categoryData.serviceSubcategory}>
						<MenuItem value={categoryData.serviceSubcategory}>{categoryData.serviceSubcategory}</MenuItem>
					</Select>
				</Grid>
			</>
		)
	});

	const isInvalidName = useInvalidField({
		venueField: "venueName",
		vendorField: "vendorName"
	});

	const invalidDesc = useInvalidField({
		venueField: "venueDescription",
		vendorField: "vendorDescription"
	});

	const invalidCity = useInvalidField({
		venueField: "venueCity",
		vendorField: "vendorCity"
	});

	const invalidCoverImage = useInvalidField({
		venueField: "venueCoverImageObjectKey",
		vendorField: "vendorCoverImageObjectKey"
	});

	const uploadCoverImage = async (fileObj: File) => {
		if (serviceType === null){
			setImageErrorSuccess({error:true,sucess:false})
			setImageLoader(false)
			return;
		}

		const [isImageValid, imageDimensions] = await checkImageFileDimensions(fileObj, IMAGE_DIMENSIONS.COVER_IMAGE);



		// if (!isImageValid) {
		// 	alert(`Please upload cover image of size (${IMAGE_DIMENSIONS.COVER_IMAGE.imgWidth}x${IMAGE_DIMENSIONS.COVER_IMAGE.imgHeight}). Current image is (${imageDimensions.imgWidth}x${imageDimensions.imgHeight})`);
		// 	return;
		// }

		const mediaResponses = await manageMedia({
			mediaFiles: [fileObj],
			fileMethod: "PUT",
			objectKeyGenFn: (fileHandle, fileIdx, reqMethod) => {
				const fileChunks = fileHandle.name.split(".");
				const fileExt = fileChunks[fileChunks.length - 1];
				return `media/${serviceType.toLowerCase()}/${serviceSlug}/cover-image`;
			}
		});

		const coverImageUploadResponse = mediaResponses[0];
		if (coverImageUploadResponse === undefined){
			setImageErrorSuccess({error:true,sucess:false})
			setImageLoader(false)
			return;
		} 



	
		const { requestAcknowledged, requestStatus } = coverImageUploadResponse;
		if (requestAcknowledged && requestStatus === "ACK") {
			setBasicProfileData({
				...profileData,
				serviceImageObjectKey: coverImageUploadResponse.objectKey
			});
			setImageLoader(false)
			setImageErrorSuccess({error:false,sucess:true})
			
		}else{
			setImageLoader(false)
			setImageErrorSuccess({error:true,sucess:false})
			
		}

	};

	const parseCity = (cityString: string): Pick<LocationSelectProps, "cityName" | "stateCode"> => {
		const reMatches = /^(?<cityName>.+)\s\((?<stateCode>\w{2})\)$/i.exec(cityString);
		if (reMatches === null) {
			return {
				stateCode: null,
				cityName: null
			};
		}

		const { cityName, stateCode } = reMatches.groups || {};
		if (cityName === undefined || stateCode === undefined) {
			return {
				cityName: null,
				stateCode: null
			};
		}

		return {
			cityName: cityName,
			stateCode: stateCode
		};
	};

	const [serviceCity, setServiceCity] = useValueSelector({
		defaultValue: () => {
			return {
				stateCode: null,
				cityName: null
			} as Pick<LocationSelectProps, "cityName" | "stateCode">;
		},
		venueValue: (venueData) => {
			return parseCity(venueData.venueCity);
		},
		vendorValue: (vendorData) => {
			return parseCity(vendorData.vendorCity);
		},
		updateVenueValue: (prevValue, partialValue) => {
			const { stateCode, cityName } = partialValue;
			if (stateCode === null || cityName === null) {
				return prevValue;
			}
			return {
				...prevValue,
				venueCity: `${cityName} (${stateCode})`
			};
		},
		updateVendorValue: (prevValue, partialValue) => {
			const { cityName, stateCode } = partialValue;
			if (cityName === null || stateCode === null) {
				return prevValue;
			}
			return {
				...prevValue,
				vendorCity: `${cityName} (${stateCode})`
			};
		}
	});

	function setInputError(arg0: string) {
		throw new Error("Function not implemented.");
	}

	const handleInputChange = (e: any) => {
		const value = e.target.value.slice(0, 1000);

		setServiceDescriptionData(value);
		setBasicProfileData({
			...profileData,
			serviceDescription: value
		});

	};






	function getCurrentLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					flyMyLocation([latitude, longitude])
					setBasicProfileData({
						...profileData,
						serviceLat: latitude,
						serviceLong: longitude
					});
				},
				(error) => {
					console.error('Error getting location:', error);
				}
			);
		} else {
			console.error('Geolocation is not supported by this browser.');
		}
	}
	function flyMyLocation(array: any) {
		if (mapRef.current)
			mapRef.current.flyTo(array, 14, { animate: true });
	}
	function AddMarkerToClick() {
		useMapEvents({
			click(e: any) {
				if (!inputFocused) {
					setBasicProfileData({
						...profileData,
						serviceLat: e.latlng.lat,
						serviceLong: e.latlng.lng
					});
				}
			},
		});

		return null;
	}

	return (
		<>
			<Helmet>
				<title> Profile | Mangal Weddings Dashboard </title>
			</Helmet>
			<Stack spacing={2}>
				<Stack spacing={2}>
					<Typography variant={"h4"}>Basic Profile</Typography>
					<Typography>
						Fill in your basic service details here. Make sure your information matches the services you
						provide
					</Typography>
				</Stack>
				<Divider sx={{ my: 2 }} />
				<Grid container spacing={2} sx={{ alignItems: "center", width: "100%" }}>
					<Grid xs={12} md={4} item>
						<RequiredText>
							<b>What is the name of your establishment?</b>
						</RequiredText>
					</Grid>
					<Grid xs={12} md={8} item>
						<TextField
							fullWidth
							defaultValue={serviceName}
							placeholder={"Your Service Name"}
							error={isInvalidName}
							onChange={(e) => {
								setBasicProfileData({
									...profileData,
									serviceName: e.target.value
								});
							}}
						/>
					</Grid>
					<Grid xs={12} md={4} item>
						<RequiredText>
							<b>Enter a brief description of your services</b>
						</RequiredText>
					</Grid>
					<Grid xs={12} md={8} item>
						<TextField
							fullWidth
							defaultValue={serviceDescription}
							error={invalidDesc}
							multiline
							minRows={3}
							inputProps={{ maxLength: 1000 }}
							placeholder={"Your Service Description"}
							onChange={handleInputChange}
							maxRows={6}
						/>
						<p style={{ cursor: "none", fontSize: "15px", marginTop: "0px", float: "right" }}>{serviceDescription.length}/1000</p>
					</Grid>
					<>
						<Grid xs={12} md={4} item>
							<RequiredText>
								<b>Which city are you located in?</b>
							</RequiredText>
						</Grid>
						<Grid item xs={12} md={8}>
							<LocationSelect
								cityName={serviceCity.cityName || null}
								stateCode={serviceCity.stateCode || null}
								onSelect={({ stateCode, cityName }) => {
									if (`${stateCode}` === "null" || `${cityName}` === "null") {
										return;
									}
									setServiceCity({
										cityName: cityName,
										stateCode: stateCode
									});
								}}
								isInvalid={invalidCity}
							/>
						</Grid>
					</>
					{isPartialInfo ? null : (
						<>
							<Grid item xs={12} md={4}>
								<RequiredText>
									<b>
										Pick Location on Map
									</b>
								</RequiredText>
							</Grid>
							{/* <div style={{border:"2px solid red",margin: "15px 40px", width: "60%",display:"flex",flexDirection:"column",justifyContent:"space-between",alignItems:"center",gap:"5px" }}> */}
							<Grid item xs={12} md={8}>
								<MapContainer
									// @ts-ignore
									ref={mapRef}
									attributionControl={false}
									center={profileData.serviceLat === 0 && profileData.serviceLong === 0 ? [28.6139, 77.2090] : [profileData.serviceLat, profileData.serviceLong]}
									zoom={11}
									style={{ boxShadow: "2px 2px 8px #888888", position: "relative", cursor: "context-menu", height: '400px', width: '80%' }}
								>
									<TileLayer
										url={`https://maps.googleapis.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}&key=${googleMapApiKey}`}
										maxZoom={30}
									/>
									<AddMarkerToClick />
									{/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"4px",position:"absolute",top:"5px",right:"10px",width:"80%",zIndex:1000}}>
										<div style={{width:"100%",padding:"3px",background:"white",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"10px",boxShadow:"1px 1px 2px black"}}>
											<SearchIcon/>
											<input spellCheck={false} style={{letterSpacing:"1.25px",fontSize:"18px",fontFamily:"Roboto",border:0,outline:"none",padding:"6px",width:"100%"}} type="text" placeholder="Search Google Maps"/>
											<MyLocationIcon/>
										</div>
                                        <div style={{borderRadius:"6px",boxShadow:"1px 1px 2px black",width:"90%",background:"white"}}>

										</div>
									</div> */}
									<Marker position={profileData.serviceLat === 0 && profileData.serviceLong === 0 ? [28.6139, 77.2090] : [profileData.serviceLat, profileData.serviceLong]} icon={icon} />
								</MapContainer>
								<button onClick={getCurrentLocation} style={{ margin: "15px", width: "105px", cursor: "pointer", background: "blue", padding: "5px", color: "white", border: "0", borderRadius: "8px", fontSize: "16px" }} >Locate Me</button>
							</Grid>
							{/* </div> */}
							<Grid xs={12} md={4} item>
								<RequiredText>
									<b>
										Upload Cover Image
										{/* ({`${IMAGE_DIMENSIONS.COVER_IMAGE.imgWidth}x${IMAGE_DIMENSIONS.COVER_IMAGE.imgHeight}`}) */}
									</b>
									
								</RequiredText>
								

							</Grid>
							<Grid xs={12} md={6} item>
								<TextField
									fullWidth
									inputProps={{
										accept:".jpg, .jpeg, .png",
									}}
									type={"file"}
									error={invalidCoverImage}
									onChange={(e: any) => {
											// @ts-ignore
											// console.log(e.target.files[0])
											if((e.target.files || []).length > 0) {
												// @ts-ignore
												// setImageError(false)
												setImageErrorSuccess({error:false,sucess:false})
												setImageLoader(true)
												// console.log("aya to ha",e.target.files[0])
												uploadCoverImage(
													// @ts-ignore
													e.target.files[0]
												);
											}
										
									}}
								/>
								<div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"15px"}}><p style={{ color: "red", fontSize: "13px"}}>Allowed file types: JPG, JPEG, PNG </p>{imageLoader?<CircularProgress style={{width:"20px",height:"20px"}} />:null}{imageErrorSuccess.error?<ErrorIcon style={{width:"25px",height:"25px",color:"red"}}/>:null}{imageErrorSuccess.sucess?<CheckCircleIcon style={{width:"25px",height:"25px",color:"green"}}/>:null}</div>
							</Grid>

							<Grid item xs={12} md={2}>
								<Link href={serviceCoverImageURL} target={"_blank"}>
									View Current Image
								</Link>
							</Grid>

						</>
					)}
				</Grid>
				<ToastContainer />
			</Stack>
		</>
	);
}
