import { Box, Button, Divider, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ServiceContext from "../utils/serviceContext";
import { Link, useNavigate } from "react-router-dom";
import useResponsive from "../hooks/useResponsive";
import { ListingStatus, VendorCategoriesSchema, VendorSchema, VenueSchema } from "../utils/mongoSchema";
import { makeAPIRequest, useAPIRequest } from "../utils/apiHandler";
import { GetListingTicketResponse, GetVendorCategoriesResponse } from "../utils/apiResponses";
import { ServicePremiumStatus } from "../utils/commonUtils";
import { useServiceRender, useValueSelector } from "../utils/hooks";
import { GetVendorAdminTicketParams, GetVenueAdminTicketParams } from "../utils/apiRequests";
import ProfileContext from "../utils/profileContext";
import Iconify from "../components/iconify";
import { Helmet } from "react-helmet-async";
import categoryData from "../sections/dashboard/vendor-categories.json"
//@ts-ignore
import locationIcon from "../assets/placeholder.png";
//@ts-ignore
import tophand from "../assets/topHand.png";
import styled, { keyframes } from "styled-components"

const moveUpDown = keyframes`
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0); /* Adjust the distance */
  }
  100% {
    transform: translateY(-20px);
  }
`;
const HandImg = styled.img`
width: 50px;
  height: 50px;
  position: absolute;
  bottom: -150%;
  left:10%;
  animation: ${moveUpDown} 2s linear infinite; 
`;

const MainLoaderDiv=styled.div`


`;




export default function DashboardPage() {
	const isS = useResponsive("down", "md");
	const [notification, SetNotification] = useState(false)

	const [vendorCategory, setVendorCategory] = useState<string>("_");
	const [vendorSubcategory, setVendorSubcategory] = useState<string>("_")

	const [listingAdminTickets, setListingAdminTickets] = useState<GetListingTicketResponse["listingTickets"]>([]);

	const profileData = useContext(ProfileContext);

	const [currentListingInfo, DO_NOT_CALL_NO_EFFECT] = useValueSelector({
		defaultValue: () => {
			return {
				serviceSlug: "",
				listingStatus: "PENDING" as ListingStatus,
				premiumStatus: ServicePremiumStatus.BASIC,
				viewCount: 0
			};
		},
		venueValue: (venueData) => {
			return {
				serviceSlug: venueData.venueSlug,
				listingStatus: venueData.venueListingStatus,
				premiumStatus: venueData.venuePremiumStatus,
				viewCount: venueData.venueViewCount
			};
		},
		vendorValue: (vendorData) => {
			return {
				serviceSlug: vendorData.vendorSlug,
				listingStatus: vendorData.vendorListingStatus,
				premiumStatus: vendorData.vendorPremiumStatus,
				viewCount: vendorData.vendorViewCount
			};
		}
	});

	const {
		isSuccess,
		isError,
		requestError,
		responseCode,
		responseData,
		isLoading
	} = useAPIRequest<GetVendorCategoriesResponse>({
		requestMethod: "GET",
		requestEndpoint: "/meta/vendor-categories"
	});

	useEffect(() => {

	}, [isLoading, isSuccess, responseData]);

	const {
		serviceInfo: { isPartialInfo, serviceData, serviceType },
		saveServiceInfo,
		setServiceInfo
	} = useContext(ServiceContext);

//
	useEffect(() => {
		if (serviceType && serviceData) {
			if (serviceType === "VENDOR" && (serviceData as VendorSchema).vendorListingStatus === "APPROVED" && (serviceData as VendorSchema).vendorLatitude == 0) {
				SetNotification(true)
			} else if (serviceType === "VENUE" && (serviceData as VenueSchema).venueListingStatus === "APPROVED" && (serviceData as VenueSchema).venueLatitude == 0) {
				SetNotification(true)
			}
		}
	}, [serviceType, serviceData])


	const navigate = useNavigate();

	useEffect(() => {
		if (serviceType === null) {
			return;
		}

		if (isPartialInfo) {
			return;
		}

		const fetchListingTickets = async () => {
			if (serviceType === "VENUE") {
				const {
					isSuccess,
					responseData,
					requestError,
					isError,
					responseCode
				} = await makeAPIRequest<GetListingTicketResponse, GetVenueAdminTicketParams>({
					requestEndpoint: "/venues/:venueSlug/listing-status",
					requestMethod: "GET",
					urlParams: {
						venueSlug: currentListingInfo.serviceSlug
					}
				});
				if (isSuccess && responseData?.responseStatus === "SUCCESS") {
					const { listingTickets } = responseData;
					setListingAdminTickets(listingTickets);
				}
			} else {
				const {
					isSuccess,
					responseData,
					requestError,
					isError,
					responseCode
				} = await makeAPIRequest<GetListingTicketResponse, GetVendorAdminTicketParams>({
					requestEndpoint: "/vendors/:vendorSlug/listing-status",
					requestMethod: "GET",
					urlParams: {
						vendorSlug: currentListingInfo.serviceSlug
					}
				});

				if (isSuccess && responseData?.responseStatus === "SUCCESS") {
					const { listingTickets } = responseData;
					setListingAdminTickets(listingTickets);
				}
			}
		};

		fetchListingTickets();
	}, [currentListingInfo.serviceSlug, isPartialInfo, serviceType]);


	// collection data for category and subcategory
	const vendorCategories = Array.from(
		new Set(
			categoryData.map((categoryDat) => {
				return categoryDat.vendorCategory;
			})
		)
	);

	const vendorCategoryTexts = Array.from(
		new Set(
			categoryData.map((categoryDat) => {
				return categoryDat.categoryText;
			})
		)
	);

	const vendorSubcategoryDataCurrent = vendorCategory === "_" ? [] : categoryData.filter((categoryObj) => {
		return categoryObj.vendorCategory === vendorCategory;
	});

	const createProfile = (profileType: "VENUE" | "VENDOR") => {
		if (profileType === "VENDOR") {
			setServiceInfo({
				isPartialInfo: true,
				serviceType: "VENDOR",
				serviceData: {
					vendorMetadata: [],
					vendorSlug: "",
					vendorServiceRate: -1,
					vendorServiceTypes: [],
					vendorPackages: [],
					vendorCity: "",
					vendorDescription: "",
					vendorName: "",
					vendorCategory: vendorCategory,
					vendorSubcategory: vendorSubcategory,
					vendorRatingAverage: 0,
					vendorListingStatus: "PENDING",
					vendorRatingCount: 0,
					vendorPremiumStatus: ServicePremiumStatus.BASIC,
					vendorCoverImageObjectKey: "static/cover-image.svg",
					vendorViewCount: 0,
					vendorLatitude: 0,
					vendorLongitude: 0,
					vendorCoordinates: {
						type: "Point",
						coordinates: [0, 0]
					}
				} satisfies VendorSchema,
				invalidFields: ["vendorName", "vendorDescription", "vendorCity"]
			});
		} else {
			setServiceInfo({
				isPartialInfo: true,
				serviceType: "VENUE",
				serviceData: {
					venueSlug: "",
					venueMetadata: [],
					venuePricingRate: [0, 0, 0],
					venueBaseRate: 0,
					venueName: "",
					venueDescription: "",
					venueListingStatus: "PENDING",
					venueCity: "",
					venueLocationTypes: [],
					venueLocations: [],
					venueMinCapacity: 1,
					venueMaxCapacity: 1,
					venueEnvironment: [],
					venueRatingAverage: 0,
					venueRatingCount: 0,
					venuePremiumStatus: ServicePremiumStatus.BASIC,
					venueCoverImageObjectKey: "static/cover-image.svg",
					venueViewCount: 0,
					venueCoordinates: {
						type: "Point",
						coordinates: [
							0, 0
						]
					},
					venueLatitude: 0,
					venueLongitude: 0
				} satisfies VenueSchema,
				invalidFields: ["venueName", "venueDescription", "venueCity"]
			});
		}

		navigate("/dashboard/profile");
	};

	const LISTING_COLORS: Record<ListingStatus, string> = {
		APPROVED: "lightgreen",
		PENDING: "lightblue",
		REJECTED: "lightsalmon",
		UNLISTED: "lightsalmon"
	};

	const PREMIUM_TEXT: Record<ServicePremiumStatus, string> = {
		[ServicePremiumStatus.BASIC]: "Basic",
		[ServicePremiumStatus.PREMIUM]: "Premium",
		[ServicePremiumStatus.HIGHLIGHT]: "Highlight"
	};

	const PREMIUM_COLORS: Record<ServicePremiumStatus, string> = {
		[ServicePremiumStatus.BASIC]: "lightgrey",
		[ServicePremiumStatus.HIGHLIGHT]: "palegoldenrod",
		[ServicePremiumStatus.PREMIUM]: "gold"
	};


	const fullUserDashboard = (
		<Stack spacing={2} sx={{ flexGrow: 1 }}>
			<Stack spacing={2}>
				<Typography variant={"h4"}>
					{
						isPartialInfo ? (
							"Let's get started"
						) : (
							"My Dashboard"
						)
					}
				</Typography>
				<Typography sx={{ pl: 2 }}>
					<ul>
						<li>Contact <a href={"mailto:info@maangalbazaar.com"}>info@maangalbazaar.com</a> to update your
							status</li>
					</ul>
				</Typography>
			</Stack>
			<Divider />
			<Stack
				sx={{ flexGrow: 1 }}
			>
				<Grid container spacing={1}>
					<Grid item xs={12} md={3}>
						<Box
							sx={{
								backgroundColor: "lightblue",
								borderRadius: 4,
								px: 2,
								py: 4,
								boxShadow: "5px 5px 0px 0px darkgrey",
								border: "1px solid black"
							}}
						>
							<Typography
								align={"center"}
							>
								<span>
									Hello
								</span>
								{
									profileData === null ? (
										<span>{" There!"}</span>
									) : (
										<>
											<span>
												{`, `}
											</span>
											<b>
												{profileData.userFullName}
											</b>
										</>
									)
								}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={3}>
						<Box
							sx={{
								backgroundColor: LISTING_COLORS[currentListingInfo.listingStatus],
								borderRadius: 4,
								px: 2,
								py: 4,
								boxShadow: "5px 5px 0px 0px darkgrey",
								border: "1px solid black"
							}}
						>
							<Typography
								align={"center"}
							>
								<span>
									Your listing is
								</span>
								<b style={{ textTransform: "capitalize" }}>
									{` ${currentListingInfo.listingStatus.toLowerCase()}`}
								</b>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={3}>
						<Box
							sx={{
								backgroundColor: LISTING_COLORS["APPROVED"],
								borderRadius: 4,
								px: 2,
								py: 4,
								boxShadow: "5px 5px 0px 0px darkgrey",
								border: "1px solid black"
							}}
						>
							<Typography
								align={"center"}
							>
								<span>
									Your listing has
								</span>
								<b style={{ textTransform: "capitalize" }}>
									{` ${currentListingInfo.viewCount} `}
								</b>
								<span>
									views
								</span>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={3}>
						<Box
							sx={{
								backgroundColor: PREMIUM_COLORS[currentListingInfo.premiumStatus as ServicePremiumStatus],
								borderRadius: 4,
								px: 2,
								py: 4,
								boxShadow: "5px 5px 0px 0px darkgrey",
								border: "1px solid black"
							}}
						>
							<Typography
								align={"center"}
							>
								<b style={{ textTransform: "capitalize" }}>
									{` ${PREMIUM_TEXT[currentListingInfo.premiumStatus as ServicePremiumStatus]} `}
								</b>
								<span>
									Listing
								</span>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={8} sx={{ display: "flex" }}>
						<Box
							sx={{
								borderRadius: 4,
								p: 2,
								flexGrow: 1,
								boxShadow: "5px 5px 0px 0px darkgrey",
								border: "1px solid black",
								display: "flex"
							}}
						>
							<Stack spacing={1} sx={{ flexGrow: 1 }}>
								<Typography variant={"h5"}>
									Manage your Listing
								</Typography>
								<Divider sx={{ py: 0 }} />
								{
									isPartialInfo ? (
										<Box sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexGrow: 1
										}}>
											<Typography>
												<i>
													{"Return to this page after "}
													<Link to={"/dashboard/profile"}>{"creating your listing"}</Link>
												</i>
											</Typography>
										</Box>
									) : (
										<Grid container sx={{ alignItems: "center", flexGrow: 1 }} spacing={1}>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<b>Edit Your Profile</b>
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<Link to={"/dashboard/profile"}>Take me there!</Link>
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Divider sx={{ py: 0 }} />
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<b>Add or Remove Features</b>
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<Link to={"/dashboard/features"}>Take me there!</Link>
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Divider sx={{ py: 0 }} />
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<b>Add Photos to your Portfolio</b>
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<Link to={"/dashboard/portfolio/photos"}>Take me there!</Link>
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Divider sx={{ py: 0 }} />
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<b>Showcase albums in your Portfolio</b>
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<Link to={"/dashboard/portfolio/albums"}>Take me there!</Link>
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Divider sx={{ py: 0 }} />
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<b>Add Videos to your Portfolio</b>
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<Link to={"/dashboard/portfolio/videos"}>Take me there!</Link>
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Divider sx={{ py: 0 }} />
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<b>Add Pricing Data</b>
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<Link to={"/dashboard/pricing"}>Take me there!</Link>
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Divider sx={{ py: 0 }} />
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<b>Chat with Users</b>
												</Typography>
											</Grid>
											<Grid item xs={12} md={6}>
												<Typography align={"center"}>
													<Link to={"/dashboard/chat"}>Take me there!</Link>
												</Typography>
											</Grid>
										</Grid>
									)
								}
							</Stack>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} sx={{ display: "flex" }}>
						<Box sx={{
							backgroundColor: "whitesmoke",
							overflowY: "scroll",
							display: "flex",
							flexDirection: "column",
							gap: 1,
							borderRadius: 4,
							p: 2,
							boxShadow: "5px 5px 0px 0px darkgrey",
							border: "1px solid black",
							flexGrow: 1
						}}>
							<Typography variant={"h6"}>
								{`Listing Updates (${listingAdminTickets.length})`}
							</Typography>
							<Divider sx={{ py: 0 }} />
							{
								listingAdminTickets.length > 0 ? (
									<Stack sx={{
										overflowY: "scroll",
										flexGrow: 1,
										borderRadius: 2,
										border: "1px solid darkgrey",
										p: 1,
										maxHeight: "40vh"
									}} spacing={1}>
										{
											listingAdminTickets.map((adminTick) => {
												const {
													serviceExistingListingStatus,
													serviceUpdatedListingStatus,
													ticketId,
													adminComment,
													updatedFields
												} = adminTick;

												return (
													<Box
														key={ticketId}
														sx={{
															border: "1px solid darkgrey",
															borderRadius: 2,
															p: 2,
															backgroundColor: "white"
														}}
													>
														<Stack spacing={1}>
															<Stack spacing={1} direction={"row"} sx={{
																justifyContent: "space-between",
																alignItems: "center"
															}}>
																<Box
																	sx={{
																		borderRadius: 2,
																		p: 1,
																		// @ts-ignore
																		backgroundColor: LISTING_COLORS[serviceExistingListingStatus]
																	}}
																>
																	{serviceExistingListingStatus}
																</Box>
																<Iconify icon={"eva:arrow-forward-fill"} />
																<Box
																	sx={{
																		borderRadius: 2,
																		p: 1,
																		// @ts-ignore
																		backgroundColor: LISTING_COLORS[serviceUpdatedListingStatus]
																	}}
																>
																	{serviceUpdatedListingStatus}
																</Box>
															</Stack>
															<Divider sx={{ py: 0 }} />
															<Typography>
																{adminComment}
															</Typography>
															<Typography variant={"body2"}>
																{`${updatedFields.length ? updatedFields.length : "No"} fields updated`}
															</Typography>
															<Divider sx={{ py: 0 }} />
															<Typography variant={"body2"}>
																<i>
																	Ticket ID: {ticketId}
																</i>
															</Typography>
														</Stack>
													</Box>
												);
											})
										}
									</Stack>
								) : (
									<Typography align={"center"}>
										<i>No Messages To Display</i>
									</Typography>
								)
							}
						</Box>
					</Grid>
				</Grid>
			</Stack>
		</Stack>
	);

	const dashboardView = useServiceRender({
		fallbackElement: (
			<Stack spacing={2} sx={{ flexGrow: 1 }}>
				<Typography variant={"h4"}>
					{
						isPartialInfo ? (
							"Let's get started"
						) : (
							"My Dashboard"
						)
					}
				</Typography>
				<Divider />
				<Grid container sx={{ alignItems: "center", flexGrow: 1 }}>
					<Grid item xs={12} md={6}
						sx={{
							borderRight: isS ? "none" : "1px solid darkgrey",
							flexGrow: "1 !important"
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexGrow: 1,
								justifyContent: "center",
								alignItems: "center",
								minHeight: "100%"
							}}
						>
							<Stack spacing={2}
								sx={{
									flexGrow: 1,
									py: 2,
									px: 2,
									alignItems: "center"
								}}
							>
								<Typography variant={"h5"}>
									Venue Owner
								</Typography>
								<Typography>
									You provide services such as -
								</Typography>
								<Typography>
									<ul>
										<li>Banquet Halls</li>
										<li>Hotels</li>
										<li>In-house catering</li>
										<li>In-house dining</li>
										<li>Stay-over</li>
									</ul>
								</Typography>
								<br />
								<Button
									fullWidth
									variant={"contained"}
									onClick={() => {
										createProfile("VENUE");
									}}
								>
									I am a Venue Owner
								</Button>
							</Stack>
						</Box>
					</Grid>
					{
						isS ? (
							<Grid item xs={12}>
								<Divider></Divider>
							</Grid>
						) : (
							null
						)
					}
					<Grid item xs={12} md={6}
						sx={{
							flexGrow: "1 !important"
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexGrow: 1,
								justifyContent: "center",
								alignItems: "center",
								minHeight: "100%"
							}}
						>
							<Stack spacing={2}
								sx={{
									flexGrow: 1,
									py: 2,
									px: 2,
									alignItems: "center"
								}}
							>
								<Typography variant={"h5"}>
									Service Vendor
								</Typography>
								<Typography>
									You provide services such as -
								</Typography>
								<Typography>
									<ul>
										<li>Customised Jewellery</li>
										<li>Gifts, Invitations, Packages</li>
										<li>Makeup, Clothing and Beauty</li>
										<li>Planning Assistance, Event Management</li>
										<li>Ritual and Proceeding Coordination</li>
									</ul>
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<Select
											fullWidth
											defaultValue={"_"}
											placeholder={"Select Category"}
											onChange={(e) => {
												setVendorCategory(e.target.value);
												setVendorSubcategory("_");
											}}
										>
											<MenuItem value={"_"} disabled>Select Category</MenuItem>
											{
												vendorCategories.map((currCat, categoryIndex) => {
													const categoryText = vendorCategoryTexts[categoryIndex];

													return (
														<MenuItem
															key={currCat}
															value={currCat}
														>
															{categoryText}
														</MenuItem>
													);
												})
											}
										</Select>
									</Grid>
									<Grid item xs={12} md={6}>
										<Select
											fullWidth
											value={vendorSubcategory}
											disabled={vendorCategory === "_"}
											placeholder={"Select Sub-Category"}
											onChange={(e) => {
												setVendorSubcategory(e.target.value);
											}}
										>
											<MenuItem value={"_"} disabled>Select Sub-Category</MenuItem>
											{
												vendorSubcategoryDataCurrent.map((subcatData) => {
													const {
														vendorSubcategory: currSubcat,
														subcategoryText
													} = subcatData;

													return (
														<MenuItem
															key={currSubcat}
															value={currSubcat}>
															{subcategoryText}
														</MenuItem>
													);
												})
											}
										</Select>
									</Grid>
								</Grid>
								<br />
								<Button
									fullWidth
									disabled={
										vendorCategory === "_"
										||
										vendorSubcategory === "_"

									}
									variant={"contained"}
									onClick={() => {
										createProfile("VENDOR");
									}}
								>
									{
										(
											vendorCategory === "_"
											||
											vendorSubcategory === "_"
										) ? (
											"Select Category and Subcategory to proceed"
										) : (
											`I am a ${vendorCategoryTexts[vendorCategories.indexOf(vendorCategory)]} Service Vendor`
										)
									}
								</Button>
							</Stack>
						</Box>
					</Grid>
				</Grid>
			</Stack>
		),
		vendorElement: fullUserDashboard,
		venueElement: fullUserDashboard
	});

	if (profileData === null) {
		return null;
	}

	return (
		<>
		<MainLoaderDiv>

		</MainLoaderDiv>
		{
			notification ? <div style={{ gap: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", background: "#ffebee", position: "absolute", top: "-20px", right: 0, zIndex: "9999", width: "100%", minHeight: "70px", padding: "5px 20px" ,boxShadow:" 0 4px 2px -2px rgba(0, 0, 0, 0.2)"}}>
				<div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
					<img style={{ width: "25px", height: "27px" }} src={locationIcon} alt=".." />
					<p style={{ wordBreak: "break-all", fontSize: "20px", fontWeight: "500", fontFamily: "monospace" }}>Now You Can Set Your Google Map location </p>
				</div>
				<div style={{ position: "relative" }}>
					<Button onClick={() => navigate("/dashboard/profile")} style={{ cursor: "pointer", fontSize: "16px" }} variant={"contained"}>Go</Button>
					<HandImg alt=".." src={tophand} />
				</div>
			</div> : null
		}
			<Helmet>
				<title> Home | Mangal Weddings Dashboard </title>
			</Helmet>
			{dashboardView}
		</>
	);
}