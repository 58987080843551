import { MetadataObject, VendorQuestionnaireSchema } from "../../utils/mongoSchema";
import { useValueSelector } from "../../utils/hooks";
import { selectMetadataValue, zipMetadata } from "../../utils/schemaMetadata";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

type BitFieldQuestionProps =
	Omit<VendorQuestionnaireSchema, "vendorCategory" | "vendorSubcategory" | "questionType" | "vendorMetadataType" | "vendorMetadataDefaultValue">
	& {
	questionType: "BITFIELD",
	vendorMetadataType: "NUMBER",
	vendorMetadataDefaultValue: number
}

export function BitFieldQuestion(props: BitFieldQuestionProps) {
	const {
		questionText,
		vendorMetadataType,
		vendorMetadataDependencies,
		vendorMetadataKey,
		vendorMetadataDefaultValue,
		questionOptions
	} = props;

	const [vendorMetadata, setVendorMetadata] = useValueSelector({
		defaultValue: () => {
			return [] as MetadataObject[];
		},
		vendorValue: (vendorData) => {
			return vendorData.vendorMetadata;
		},
		venueValue: () => {
			return [];
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue)
			};
		},
		updateVenueValue: (prevValue, partialValue) => {
			return prevValue;
		}
	});

	const currentMetadataValue = selectMetadataValue(vendorMetadata, vendorMetadataKey, vendorMetadataDefaultValue as number) as number;

	const isBitSet = (bitVal: number) => {
		return (currentMetadataValue & bitVal) > 0;
	};

	return (
		<>
			<Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
				<Typography>
					<b>
						{questionText}
					</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Grid container spacing={1}>
					{
						questionOptions.map((questionData, questionIdx) => {
							const { optionText, optionValue } = questionData;

							return (
								<Grid item xs={12} sm={6} md={4} key={(optionValue as number).toString()}>
									<FormControlLabel
										control={
											<Checkbox
												checked={isBitSet(optionValue as number)}
												onChange={(e) => {
													setVendorMetadata([
														...vendorMetadata,
														{
															propertyName: vendorMetadataKey,
															propertyType: "NUMBER",
															propertyValue: (currentMetadataValue ^ optionValue as number)
														}
													]);
												}}
											/>
										}
										label={optionText}
									/>
								</Grid>
							);
						})
					}
				</Grid>
			</Grid>
		</>
	);
}