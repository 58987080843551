import { MetadataObject, VendorQuestionnaireSchema } from "../../utils/mongoSchema";
import { useValueSelector } from "../../utils/hooks";
import { selectMetadataValue, zipMetadata } from "../../utils/schemaMetadata";
import { Grid, MenuItem, Select, Typography } from "@mui/material";

type BoolSelectQuestionProps =
	Omit<VendorQuestionnaireSchema, "vendorCategory" | "vendorSubcategory" | "questionType" | "vendorMetadataType" | "vendorMetadataDefaultValue">
	& {
	questionType: "BOOL_SELECT",
	vendorMetadataType: "BOOLEAN",
	vendorMetadataDefaultValue: boolean
}

export function BoolSelectQuestion(props: BoolSelectQuestionProps) {
	const {
		questionText,
		questionOptions,
		vendorMetadataType,
		vendorMetadataDependencies,
		vendorMetadataKey,
		vendorMetadataDefaultValue
	} = props;

	const [vendorMetadata, setVendorMetadata] = useValueSelector({
		defaultValue: () => {
			return [] as MetadataObject[];
		},
		vendorValue: (vendorData) => {
			return vendorData.vendorMetadata;
		},
		venueValue: () => {
			return [];
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue)
			};
		},
		updateVenueValue: (prevValue, partialValue) => {
			return prevValue;
		}
	});

	const currentMetadataValue = selectMetadataValue(vendorMetadata, vendorMetadataKey, vendorMetadataDefaultValue as boolean);

	const unBoolean = (someBoolValue: boolean) => {
		return `##${someBoolValue}##` as const;
	};

	const toBoolean = (someUnBooledValue: ReturnType<typeof unBoolean>) => {
		const middleValue = someUnBooledValue.slice(2, -2);
		return middleValue === "true";

	};

	return (
		<>
			<Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
				<Typography>
					<b>
						{questionText}
					</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					fullWidth
					value={
						unBoolean(currentMetadataValue as boolean)
					}
					onChange={(e) => {
						setVendorMetadata([
							// @ts-ignore
							...vendorMetadata,
							{
								propertyName: vendorMetadataKey,
								propertyType: "BOOLEAN",
								// @ts-ignore
								propertyValue: toBoolean(e.target.value)
							}
						]);
					}}
				>
					{
						questionOptions.map((questionOpt) => {
							let { optionText, optionValue } = questionOpt;

							if (typeof optionValue === "boolean") {
								optionValue = unBoolean(optionValue);
							}

							return (
								<MenuItem
									value={optionValue}
									key={`${optionText}.${optionValue}`}
								>
									{optionText}
								</MenuItem>
							);
						})
					}
				</Select>
			</Grid>
		</>
	);

}