import {
	MetadataName,
	MetadataNameMapType,
	MetadataObject,
	MetadataPrefix,
	MetadataType,
	MetadataValue
} from "./mongoSchema";

type MetadataValidationOptions = {
	restrictMetadataKeys?: MetadataPrefix[];
};

export const METADATA_TYPENAME_MAP: { [metaKey in MetadataName]: MetadataNameMapType[metaKey] } = {
	VENDOR_ADVANCE_PAYMENT_PERCENT: "NUMBER",
	VENDOR_TRAVEL_COSTS: "BOOLEAN",
	VENDOR_SHOOT_LOCATION: "BOOLEAN",
	VENDOR_TRAVEL_LODGING: "BOOLEAN",
	VENDOR_RESTRICTED_TRAVEL: "BOOLEAN",
	VENDOR_OUTSIDE_HOMETOWN_CHARGES: "ARRAY_STRING",
	VENDOR_MAKEUP_AIRBRUSH: "BOOLEAN",
	VENDOR_MAKEUP_HD: "BOOLEAN",
	VENDOR_SERVICE_TRIAL: "BOOLEAN",
	VENDOR_TRIAL_PAID: "BOOLEAN",
	VENDOR_TRIAL_ADJUSTMENT: "BOOLEAN",
	VENDOR_PRODUCTS_USED: "ARRAY_STRING",
	VENDOR_BEAUTY_SALON_MAKEUP_BITFIELD: "NUMBER",
	VENDOR_FREELANCE_BRIDAL_MAKEUP_BITFIELD: "NUMBER",
	VENDOR_SERVICE_PRICING_RELATIVE: "BOOLEAN",
	VENDOR_PLANNER_DECOR_POLICY: "STRING",
	VENDOR_PLANNER_USER_CANCELLATION: "STRING",
	VENDOR_PLANNER_VENDOR_CANCELLATION: "STRING",
	VENDOR_DATE_ADJUSTMENT: "BOOLEAN",
	VENDOR_EXPERIENCE_LIST: "ARRAY_STRING",
	VENDOR_SMALL_FUNCTION_PACKAGE_PRICE: "NUMBER",
	VENDOR_WED_PACKAGE_SERVICES_BITFIELD: "NUMBER",
	VENDOR_WED_DECOR_MIN_OUT_PRICE: "NUMBER",
	VENDOR_WED_DECOR_MAX_OUT_PRICE: "NUMBER",
	VENDOR_WED_DECOR_MIN_IN_PRICE: "NUMBER",
	VENDOR_WED_DECOR_MAX_IN_PRICE: "NUMBER",
	VENDOR_REFUND_OFFERED: "BOOLEAN",
	VENDOR_PARTIAL_REFUND: "BOOLEAN",
	VENDOR_CONSULTATION_OFFERED: "BOOLEAN",
	VENDOR_CONSULTATION_PAID: "BOOLEAN",
	VENDOR_HONEYMOON_PACKAGES_SERVICES_LIST: "ARRAY_STRING",
	VENDOR_TRAVEL_AGENTS_LIST: "ARRAY_STRING",
	VENDOR_STORE_TYPE_BITFIELD: "NUMBER",
	VENDOR_TRAVEL_AGENTS_TRIPS: "STRING",
	VENDOR_CUSTOMIZATION_OPTION: "BOOLEAN",
	VENDOR_TAILORED_SERVICE: "BOOLEAN",
	VENDOR_SHIPPING: "BOOLEAN",
	VENDOR_BRIDAL_WEAR_TYPE_BITFIELD: "NUMBER",
	VENDOR_INVITATION_SHIPPING: "STRING",
	VENDOR_MIN_QUANTITY: "NUMBER",
	VENDOR_INVITES_ECARD_CHARGES: "NUMBER",
	VENDOR_DELIVERY_TIME_DAYS: "NUMBER",
	VENDOR_INVITATION_BASE_PRICE_RANGE: "STRING",
	VENDOR_SHIPPING_INTERNATIONAL: "BOOLEAN",
	VENDOR_SANGEET_CHOREOGRAPHER_CHARGES: "NUMBER",
	VENDOR_WED_PACKAGE_INCLUDES_BITFIELD: "NUMBER",
	VENDOR_CAKE_SPECIALIZE_BITFIELD: "NUMBER",
	VENDOR_ACCESSORIES_TYPE_BITFIELD: "NUMBER",
	VENDOR_ACCESSORIES_RENTAL: "BOOLEAN",
	VENDOR_ACCESSORIES_START_PRICE: "STRING",
	VENDOR_ACCESSORIES_SHIPPING: "STRING",
	VENDOR_ACCESSORIES_CHARGES: "STRING",
	VENDOR_JEWELLER_PRODUCTS: "ARRAY_STRING",
	VENDOR_ARTIFICIAL_JEWELLERY_TYPES_BITFIELD: "NUMBER",
	VENDOR_ARTIFICIAL_JEWELLERY_CUSTOMIZATION: "BOOLEAN",
	VENDOR_ARTIFICIAL_JEWELLERY_BRIDAL_PACKAGE: "NUMBER",
	VENDOR_BARTENDERS_RANGE: "STRING",
	VENDOR_ARTIFICIAL_JEWELLERY_B_TYPE: "STRING",
	VENDOR_FLORISTS_PRODUCTS: "ARRAY_STRING",
	VENDOR_WEIGHT_LOSS_CLINICS_PRODUCTS: "ARRAY_STRING",
	VENDOR_BAND_PRODUCTS: "ARRAY_STRING",
	VENDOR_FOOD_BARTENDER_TYPES: "NUMBER",
	VENDOR_FOOD_BARTENDER_AVG_CHARGES: "NUMBER",
	VENDOR_MEHENDI_ARTIST_CHARGES: "NUMBER",
	VENDOR_MEHENDI_ARTIST_FAMILY_CHARGES: "NUMBER",
	VENDOR_MAX_QUANTITY: "NUMBER",
	VENDOR_FOOD_VEG_PLATE_PRICE: "NUMBER",
	VENDOR_FOOD_NON_VEG_PLATE_PRICE: "NUMBER",
	VENDOR_FOOD_CUISINE_BITFIELD: "NUMBER",
	VENDOR_FOOD_SPECIALIZATION_BITFIELD: "NUMBER",
	VENDOR_CATERING_SERVICES_USER_CANCELLATION: "STRING",
	VENDOR_CATERING_SERVICES_VENDOR_CANCELLATION: "STRING",
	VENDOR_FOOD_ONSITE_PREPARATION: "BOOLEAN",
	VENDOR_FOOD_ADDITIONAL_SERVICE_BITFIELD: "NUMBER",
	VENDOR_MITHAI_LIST: "ARRAY_STRING",
	VENDOR_ASSOCIATION_LIST: "ARRAY_STRING",
	VENDOR_WEIGHT_LOSS_CLINICS_CHARGES: "STRING",
	VENUE_SMALL_SIZE_GATHERINGS: "BOOLEAN",
	VENUE_BOOKING_PAYMENT_PERCENTAGE: "NUMBER",
	VENDOR_HOME_CATERING_FOOD_CUISINE_BITFIELD: "NUMBER",
	VENUE_REFUND_OFFERED: "BOOLEAN",
	// VENUE_PARTIAL_REFUND: "BOOLEAN",
	VENUE_DATE_ADJUSTMENT: "BOOLEAN",
	VENUE_BASE_ROOM_COST: "NUMBER",
	VENUE_DECOR_COST: "NUMBER",
	VENUE_PRIMARY_TYPE: "STRING",
	VENUE_WHEELCHAIR_ACCESSIBLE: "BOOLEAN",
	VENUE_ALCOHOL_INTERNAL: "BOOLEAN",
	VENUE_CATERING_INTERNAL: "BOOLEAN",
	VENUE_DECOR_INTERNAL: "BOOLEAN",
	VENUE_DJ_INTERNAL: "BOOLEAN",
	VENUE_ROOM_COUNT: "NUMBER",
	VENUE_PARKING_AVAILABLE: "BOOLEAN",
	VENUE_PREMIUM_DATE: "STRING",
	VENDOR_PREMIUM_DATE: "STRING",
	VENUE_LISTING_DATE: "STRING",
	VENUE_SOCIAL_FB_LINK: "STRING",
	VENUE_SOCIAL_YT_LINK: "STRING",
	VENUE_SOCIAL_IG_LINK: "STRING",
	VENUE_SOCIAL_WS_LINK: "STRING",
	VENDOR_SOCIAL_FB_LINK: "STRING",
	VENDOR_SOCIAL_YT_LINK: "STRING",
	VENDOR_SOCIAL_IG_LINK: "STRING",
	VENDOR_SOCIAL_WS_LINK: "STRING",
	VENDOR_LISTING_DATE: "STRING",
	VENDOR_SERVICE_DATE: "STRING",
	VENDOR_RENTAL_SERVICE: "BOOLEAN",
	VENDOR_STAFF_COUNT_MIN: "NUMBER",
	VENDOR_STAFF_COUNT_MAX: "NUMBER",
	VENDOR_CATERING_NON_VEG: "BOOLEAN",
	VENDOR_PRIMARY_PACKAGE_INCLUDED_SERVICES_BITFIELD: "NUMBER",
	VENDOR_SERVICE_RELATIVE_PERCENTAGE: "NUMBER",
	VENDOR_WED_DECOR_USER_CANCELLATION: "STRING",
	VENDOR_WED_DECOR_VENDOR_CANCELLATION: "STRING",
	VENDOR_INVITATION_GIFTS_STORE_BITFIELD: "NUMBER",
	VENDOR_INVITATIONS_STORE_TYPE_BITFIELD: "NUMBER",
	VENDOR_INVITATIONS_SERVICES_TYPE_BITFIELD: "NUMBER",
	VENDOR_INVITATIONS_ECO_CUSTOMIZATION_OPTION: "BOOLEAN",
	VENDOR_WED_ENT_TRAVEL: "BOOLEAN",
	VENDOR_TROUSSEAU_ECO_MATERIAL: "BOOLEAN",
	VENDOR_TROUSSEAU_SAMPLES: "BOOLEAN",
	VENDOR_TROUSSEAU_COST_ADJ: "BOOLEAN",
	VENDOR_TROUSSEAU_FREE_SAMPLES: "BOOLEAN",
	VENDOR_TROUSSEAU_STORE_TYPE_BITFIELD: "NUMBER",
	VENDOR_INVITATION_GIFTS_SAMPLES: "BOOLEAN",
	VENDOR_DJS_PACKAGE_INCLUDES_BITFIELD: "NUMBER",
	VENDOR_DJS_CONCELLATION_POLICY: "BOOLEAN",
	VENDOR_DJS_RESTRICTED_TRAVEL: "BOOLEAN",
	VENDOR_DHOL_RESTRICTED_TRAVEL: "BOOLEAN",
	VENDOR_MAANGAL_GEET_RESTRICTED_TRAVEL: "BOOLEAN",
	VENDOR_DERMATOLOGY_CLINICS_CHARGES: "STRING",
	VENDOR_WEIGHT_LOSS_CLINICS_EXP: "STRING",
	VENDOR_TRAVEL_AGENTS_SPECIALIZE_BITFIELD: "NUMBER",
	VENDOR_SANGEET_CHOREO_RESTRICTED_TRAVEL: "BOOLEAN",
	VENDOR_GROOM_WEAR_STORE_TYPE_BITFIELD: "NUMBER",
	VENDOR_TRAVEL_AGENTS_SERVICES_BITFIELD: "NUMBER",
	VENDOR_INVITATIONS_SAMPLE_PAID: "BOOLEAN"
};

export const VALID_METADATA_NAMES = Object.keys(METADATA_TYPENAME_MAP) as MetadataName[];

export function validateValueWithType(propertyValue: MetadataObject["propertyValue"], propertyType: MetadataType) {
	if (propertyType.startsWith("ARRAY_")) {
		if (!Array.isArray(propertyValue)) {
			return false;
		}
		const propValueMatchTypes = propertyValue.map((propVal) => {
			switch (propertyType) {
				case "ARRAY_NUMBER":
					if (typeof propVal !== "number") {
						return false;
					}
					if (Number.isNaN(propVal)) {
						return false;
					}
					return true;
				case "ARRAY_STRING":
					if (typeof propVal !== "string") {
						return false;
					}
					return true;
				default:
					return false;
			}
		});

		const propertyValueValid = propValueMatchTypes.every((propValueMatch) => {
			return propValueMatch;
		});

		return propertyValueValid;
	} else {
		switch (propertyType) {
			case "BOOLEAN":
				if (typeof propertyValue !== "boolean") {
					return false;
				}
				return true;
			case "NUMBER":
				if (typeof propertyValue !== "number") {
					return false;
				}
				if (Number.isNaN(propertyValue)) {
					return false;
				}
				return true;
			case "STRING":
				if (typeof propertyValue !== "string") {
					return false;
				}
				return true;
			default:
				return false;
		}
	}
}

/**
 * @description Merges metadata based off name
 * */
export function zipMetadata(oldMetadata: MetadataObject[], newMetadata: MetadataObject[]): MetadataObject[] {
	const metadataMap: Map<MetadataName, MetadataObject> = new Map<MetadataName, MetadataObject>();
	oldMetadata.forEach((metadataStruct) => {
		const { propertyName } = metadataStruct;
		metadataMap.set(propertyName, metadataStruct);
	});
	newMetadata.forEach((metadataStruct) => {
		const { propertyName } = metadataStruct;
		metadataMap.set(propertyName, metadataStruct);
	});
	const finalMetadataArray = Array.from(metadataMap.values());
	return finalMetadataArray;
}

/**
 * @description Validates a given MetadataObject
 * @description First level check for valid property name
 * @description Second check for expected & received type name
 * @description Third check for type matching with received value
 * */
export function VALID_METADATA_CHECK(metadataOptions: MetadataValidationOptions) {
	return function (metaObject: MetadataObject) {
		const { restrictMetadataKeys } = metadataOptions;
		const { propertyName, propertyType, propertyValue } = metaObject;
		// @ts-ignore
		if ([propertyName, propertyType, propertyValue].includes(undefined)) {
			return false;
		}
		if (restrictMetadataKeys && restrictMetadataKeys.length > 0) {
			const keyInRestrictedSet = restrictMetadataKeys.some((metaPrefix) => {
				return propertyName.startsWith(metaPrefix);
			});
			if (!keyInRestrictedSet) {
				return false;
			}
		}
		const isValidPropertyName = VALID_METADATA_NAMES.includes(propertyName);
		if (!isValidPropertyName) {
			return false;
		}
		const expectedMetadataType = METADATA_TYPENAME_MAP[propertyName];
		if (propertyType !== expectedMetadataType) {
			return false;
		}
		const typeMatchesValue = validateValueWithType(propertyValue, propertyType);
		return typeMatchesValue;
	};
}

/**
 * Returns [metadataChanged, metadataDeleted]
 * */
export function diffMetadata(initialObj?: MetadataObject, newObj?: MetadataObject): [boolean, boolean] {
	if (initialObj === undefined) {
		if (newObj === undefined) {
			return [false, false];
		}
		return [true, false];
	}
	if (newObj === undefined) {
		return [false, true];
	}

	const { propertyName: initialName, propertyType: initialType, propertyValue: initialValue } = initialObj;
	const { propertyName: newName, propertyType: newType, propertyValue: newValue } = newObj;

	if (initialName !== newName) {
		return [true, false];
	}
	if (initialType !== newType) {
		return [true, false];
	}
	if (initialValue !== newValue) {
		return [true, false];
	}
	return [false, false];
}

export function compareMultipleMetadata(prevMetadata: MetadataObject[], newMetadata: MetadataObject[]) {
	if (prevMetadata.length !== newMetadata.length) {
		return false;
	}

	const metadataValuesChanged = prevMetadata.map((metaObj) => {
		const { propertyName } = metaObj;

		const complementaryMetadata = selectMetadata(newMetadata, propertyName);

		const [metadataChanged, metadataDeleted] = diffMetadata(metaObj, complementaryMetadata);

		return metadataChanged || metadataDeleted;
	});

	const anyMetadataChanged = metadataValuesChanged.some((changedValue) => {
		return changedValue;
	});

	// Note, return false if NOT equal, true if equal
	return !anyMetadataChanged;
}

export function selectMetadata(metadataArr: MetadataObject[], metadataKey: MetadataName): MetadataObject | undefined {
	const zippedMetadata = zipMetadata([], metadataArr);

	const metadataExists = zippedMetadata.find((metadataObj) => {
		return metadataObj.propertyName === metadataKey;
	});

	return metadataExists;
}

export function selectMetadataValue(
	metadataArr: MetadataObject[],
	metadataKey: MetadataName,
	defaultValue: MetadataValue[MetadataNameMapType[typeof metadataKey]]
) {
	const selectedMetadata = selectMetadata(metadataArr, metadataKey);
	return selectedMetadata ? selectedMetadata.propertyValue : defaultValue;
}

export function removeMetadata(metadataArr: MetadataObject[], metadataKeys: MetadataName[]): MetadataObject[] {
	return metadataArr.filter((metaObj) => {
		return !metadataKeys.includes(metaObj.propertyName);
	});
}
