// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

// @ts-ignore
export const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// COPY
const _ = [
	{
		title: "dashboard",
		path: "/dashboard",
		icon: icon("ic_analytics"),
	},
	{
		title: "my profile",
		path: "/dashboard/profile",
		icon: icon("ic_user")
	},
	{
		title: "features",
		path: "/dashboard/features",
		icon: icon("ic_analytics")
	},
	{
		title: "portfolio",
		path: "/dashboard/portfolio",
		icon: icon("ic_albums"),
		end: false
	},
	{
		title: "photos",
		path: "/dashboard/portfolio/photos",
		icon: icon("ic_photos"),
		inset: true
	},
	{
		title: "albums",
		path: "/dashboard/portfolio/albums",
		icon: icon("ic_albums"),
		inset: true
	},
	{
		title: "videos",
		path: "/dashboard/portfolio/videos",
		icon: icon("ic_videos"),
		inset: true
	},
	{
		title: "pricing",
		path: "/dashboard/pricing",
		icon: icon("ic_pricing")
	},
	{
		title: "chat",
		path: "/dashboard/chat",
		icon: icon("ic_chat"),
		inset: false
	}
];