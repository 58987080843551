import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useServiceRender } from "../utils/hooks";
import React from "react";
import { VendorQuestionnaire } from "../sections/features/VendorQuestionnaire";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Helmet } from "react-helmet-async";
import VenueQuestionnaire from "../sections/features/VenueQuestionnaire";

export default function FeaturesPage() {
	const serviceFeatureElements = useServiceRender({
		fallbackElement: null,
		venueElement: (
			<LocalizationProvider>
				<VenueQuestionnaire />
			</LocalizationProvider>
		),
		vendorElement: (
			<LocalizationProvider>
				<VendorQuestionnaire />
			</LocalizationProvider>
		)
	});

	const featureDescription = useServiceRender({
		fallbackElement: (
			<>
				<Typography variant={"h4"}>
					Service Features
				</Typography>
				<Typography sx={{ pl: 2 }}>
					<ul>
						<li>Service Features are additional services or policies enforced by you.</li>
						<li>These additional features may enhance your listing</li>
						<li>Input or Select the appropriate policy for a given service</li>
					</ul>
				</Typography>
			</>
		),
		vendorElement: (
			<>
				<Typography variant={"h4"}>
					Service Features Questionnaire
				</Typography>
				<Typography sx={{ pl: 2 }}>
					<ul>
						<li>Service Features are additional services or policies enforced by you.</li>
						<li>These additional features may enhance your listing</li>
						<li>Answer the questionnaire regarding your service policies</li>
					</ul>
				</Typography>
			</>
		)
	});

	return (
		<>
			<Helmet>
				<title> Features | Mangal Weddings Dashboard </title>
			</Helmet>
			<Stack spacing={2}
				   sx={{
					   height: "100%",
					   flexGrow: 1,
				   }}
			>
				<Stack spacing={2}>
					{featureDescription}
				</Stack>
				<Divider sx={{ my: 2 }} />
				<Grid container spacing={1} sx={{ alignItems: "center", width: '100%'}}>
					{serviceFeatureElements}
				</Grid>
			</Stack>
		</>
	);
}