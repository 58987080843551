import { forwardRef, ReactNode } from "react";
// icons
import { Icon } from "@iconify/react";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

type IconProps = {
	sx?: any,
	width?: string | number,
	icon: ReactNode
}

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }: IconProps, ref) => (
	// @ts-ignore
	<Box ref={ref} component={Icon} icon={icon} sx={{ width, height: (width), ...sx }} {...other} />
));


export default Iconify;
