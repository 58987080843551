import { ServiceType } from "../../utils/mongoSchema";
import { useValueSelector } from "../../utils/hooks";
import { selectMetadataValue, zipMetadata } from "../../utils/schemaMetadata";
import { Grid, TextField } from "@mui/material";
import { RequiredText } from "../../components/common";

export type SocialLinkType = "IG" | "WS" | "FB" | "YT"

const socialStringMap: Record<SocialLinkType, string> = {
	YT: "YouTube Channel Link",
	WS: "Website Link",
	IG: "Instagram Profile Link",
	FB: "Facebook Profile Link"
}

const socialStringPlaceholders: Record<SocialLinkType, string> = {
	YT: "YouTube Channel Link",
	WS: "Website Link",
	IG: "Instagram Profile Link",
	FB: "Facebook Profile Link"
}

type SocialLinkProps = {
	linkType: SocialLinkType
}

export default function SocialLink(props: SocialLinkProps){
	const {linkType} = props
	const [socialLinkValue, setSocialLinkValue] = useValueSelector({
		defaultValue: () => {
			return ""
		},
		venueValue: (venueData) => {
			const selectedValue = selectMetadataValue(
				venueData.venueMetadata,
				`VENUE_SOCIAL_${linkType}_LINK`,
				""
			) as string
			return selectedValue
		},
		vendorValue: (vendorData) => {
			const selectedValue = selectMetadataValue(
				vendorData.vendorMetadata,
				`VENDOR_SOCIAL_${linkType}_LINK`,
				""
			) as string
			return selectedValue
		},
		updateVenueValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				venueMetadata: zipMetadata(
					prevValue.venueMetadata,
					[
						{
							propertyName: `VENUE_SOCIAL_${linkType}_LINK`,
							propertyType: "STRING",
							propertyValue: partialValue
						}
					]
				)
			}
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(
					prevValue.vendorMetadata,
					[
						{
							propertyName: `VENDOR_SOCIAL_${linkType}_LINK`,
							propertyType: "STRING",
							propertyValue: partialValue
						}
					]
				)
			}
		}
	})

	return (
		<>
			<Grid item xs={12} md={4}>
				<b>
					{socialStringMap[linkType]}
				</b>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					fullWidth
					value={socialLinkValue}
					onChange={(e) => {
						setSocialLinkValue(e.target.value)
					}}
					placeholder={
						socialStringPlaceholders[linkType]
					}
				/>
			</Grid>
		</>
	)
}
