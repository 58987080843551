import { createContext } from "react";

export type AuthData = {
	authStatus: "AUTHENTICATED",
	authData: {
		userMail: string,
		userType: "SERVICE" | "USER" | "ADMIN"
	}
} | {
	authStatus: "UNAUTHENTICATED"
}

export type AuthContextType = AuthData & {
	setAuthValue: (updatedAuthData: AuthData) => void
	logoutUser: () => Promise<void>
}

const AuthContext = createContext<AuthContextType>(
	{
		authStatus: "UNAUTHENTICATED",
		setAuthValue: (updatedAuthData) => {
			throw new Error("Attempted to update auth context before initialization!");
		},
		logoutUser: async () => {
			throw new Error("Attempted to logout user before context initialization!");
		}
	}
);

export default AuthContext;