import React from "react";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function stringToColor(string) {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
}

export function stringAvatar(name) {
	const nameSplit = name.split(" ");
	return {
		sx: {
			bgcolor: stringToColor(name)
		},
		children: `${(nameSplit[0] || "M")[0]}${(nameSplit[1] || [nameSplit[1] || " "])[0]}`
	};
}

const ChatCard = ({ conversationId, conversationData, onChatClick }) => {
	const {
		userMail,
		userName,
		serviceType,
		serviceSlug,
		serviceMail,
		serviceName,
		serviceLastReadMessage,
		userLastReadMessage,
		conversationStatus,
		conversationLastActivityTimestamp
	} = conversationData;

	const parsedConversationTimestamp = new Date(conversationLastActivityTimestamp);


	const conversationStatusMap = {
		OPEN: "Active ",
		BOOKED: "Booked ",
		CLOSED: "Closed "
	};

	const theme = useTheme();

	return (
		<Button onClick={onChatClick} sx={{ border: "1px dotted darkgrey" }}>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					<Avatar {...stringAvatar(userName)} />
				</Grid>
				<Grid item container xs={10} spacing={0}>
					<Grid item xs={6}>
						<Typography
							color={theme.palette.text.primary}
							align={"left"}
						>
							{userName}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							color={theme.palette.text.secondary}
							align={"right"}
						>{parsedConversationTimestamp.toLocaleDateString("en-IN")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color={theme.palette.text.secondary} align={"left"}>
							{
								conversationStatusMap[conversationStatus]
							}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Button>
	);
};

export default ChatCard;
