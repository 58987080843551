import { MetadataObject, VendorQuestionnaireSchema } from "../../utils/mongoSchema";
import { useValueSelector } from "../../utils/hooks";
import { selectMetadataValue, zipMetadata } from "../../utils/schemaMetadata";
import { Grid, TextField, Typography } from "@mui/material";

type NumericInputQuestionProps =
	Omit<VendorQuestionnaireSchema, "vendorCategory" | "vendorSubcategory" | "questionType" | "vendorMetadataType" | "vendorMetadataDefaultValue">
	& {
	questionType: "NUMERIC_INPUT",
	vendorMetadataType: "NUMBER",
	vendorMetadataDefaultValue: number,
	questionOptions: never[]
}

export function NumericInputQuestion(props: NumericInputQuestionProps) {
	const {
		questionText,
		vendorMetadataType,
		vendorMetadataDependencies,
		vendorMetadataKey,
		vendorMetadataDefaultValue
	} = props;

	const [vendorMetadata, setVendorMetadata] = useValueSelector({
		defaultValue: () => {
			return [] as MetadataObject[];
		},
		vendorValue: (vendorData) => {
			return vendorData.vendorMetadata;
		},
		venueValue: () => {
			return [];
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue)
			};
		},
		updateVenueValue: (prevValue, partialValue) => {
			return prevValue;
		}
	});

	const currentMetadataValue = selectMetadataValue(vendorMetadata, vendorMetadataKey, vendorMetadataDefaultValue as number);

	return (
		<>
			<Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
				<Typography>
					<b>
						{questionText}
					</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					fullWidth
					placeholder={(currentMetadataValue as number).toString()}
					defaultValue={
						currentMetadataValue as number
					}
					onChange={(e) => {
				
					
						let parsedValue = Number.parseInt(e.target.value);

						if (Number.isNaN(parsedValue)) {
							parsedValue = 0;
						}

						setVendorMetadata([
							// @ts-ignore
							...vendorMetadata,
							{
								propertyName: vendorMetadataKey,
								propertyType: "NUMBER",
								// @ts-ignore
								propertyValue: parsedValue
							}
						]);
					}}
				>
				</TextField>
			</Grid>
		</>
	);

}