import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	ListItemText,
	Stack,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { StyledNavItem, StyledNavItemIcon } from "../components/nav-section/styles";
import { icon } from "../layouts/dashboard/nav/config";
import Iconify from "../components/iconify";
import { AddVideoResponse, APIResponseData, GetPortfolioVideosResponse, VideoResponseObj } from "../utils/apiResponses";
import { makeAPIRequest, usePreparedRequest } from "../utils/apiHandler";
import {
	AddVideoToPortfolioBody,
	DeleteVideoFromPortfolioParams,
	FetchVendorPortfolioVideosParams,
	FetchVenuePortfolioVideosParams,
	NoParams
} from "../utils/apiRequests";
import ProfileContext from "../utils/profileContext";
import PortfolioContext from "../utils/portfolioContext";
import { RequiredText } from "../components/common";
import { Helmet } from "react-helmet-async";
import DeleteIcon from "@mui/icons-material/Delete";
import ServiceContext from "../utils/serviceContext";
import { LoadingButton } from "@mui/lab";

type VideoItemProps = {
	videoName: string;
	videoId: string;
	isActive: boolean;
	setActive: (videoId: string) => void;
	refetchVideos: () => void;
};

function VideoItem({ videoName, videoId, isActive, setActive, refetchVideos }: VideoItemProps) {
	const theme = useTheme();

	const { setPortfolioData } = useContext(PortfolioContext);
	const [deleteActive, setDeleteActive] = useState(false);

	const handleDeleteVideo = async (videoId: string) => {
		setDeleteActive(true);
		const { isSuccess, responseData } = await makeAPIRequest<APIResponseData, DeleteVideoFromPortfolioParams>({
			requestEndpoint: "/portfolio/videos/:videoId",
			requestMethod: "DELETE",
			urlParams: {
				videoId: videoId
			}
		});
		if (isSuccess && responseData.responseStatus === "SUCCESS") {
			refetchVideos();
			setPortfolioData((prevState) => {
				return {
					...prevState,
					portfolioVideoCount: prevState.portfolioVideoCount - 1
				};
			});
		}
		setDeleteActive(true);
	};

	return (
		<StyledNavItem
			className={isActive && "active"}
			onClick={() => {
				setActive(videoId);
			}}
			sx={{
				color: theme.palette.text.primary,
				border: "1px dotted darkgrey",
				cursor: "pointer",
				display: "flex",
				alignItems: "center",
				"&.active": {
					color: "text.primary",
					bgcolor: "action.selected",
					fontWeight: "fontWeightBold"
				},
				pl: 2
			}}
		>
			<StyledNavItemIcon>{icon("ic_videos")}</StyledNavItemIcon>
			<ListItemText disableTypography primary={videoName} />
			<LoadingButton
				loading={deleteActive}
				aria-label="delete"
				color="error"
				onClick={(event) => {
					event.stopPropagation();
					handleDeleteVideo(videoId);
				}}
			>
				<DeleteIcon />
			</LoadingButton>
		</StyledNavItem>
	);
}

type VideoViewProps = VideoResponseObj;

function VideoView({ videoName, videoId, videoURL }: VideoViewProps) {
	try {
		let videoURLId;
		if (videoURL.includes("youtu.be")) {
			const videoIdSegments = videoURL.split("/");
			videoURLId = videoIdSegments[videoIdSegments.length - 1].split("?")[0];
		} else {
			const videoURLObject = new URL(videoURL);
			const videoSearchParams = videoURLObject.searchParams;
			videoURLId = videoSearchParams.get("v");
		}

		if (!videoURLId) {
			return null;
		}

		return (
			<Stack
				spacing={1}
				sx={{
					flexGrow: 1
				}}
			>
				<Typography variant={"h6"}>{videoName}</Typography>
				<Divider sx={{ py: 0 }} />
				<Box
					component={"iframe"}
					src={`https://youtube.com/embed/${videoURLId}`}
					title={videoName}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
					sx={{
						minHeight: "50vh"
					}}
				/>
			</Stack>
		);
	} catch (e) {
		return null;
	}
}

type VideoFormProps = {
	isOpen: boolean;
	onClose: () => void;
	onVideoSuccess: (videoData: VideoResponseObj) => void;
};

function VideoForm({ isOpen, onClose, onVideoSuccess }: VideoFormProps) {
	const [videoName, setVideoName] = useState("");
	const [videoURL, setVideoURL] = useState("");

	const [invalidName, setInvalidName] = useState(true);

	// const invalidURL = useMemo(() => {
	// 	try {
	// 		const urlObj = new URL(videoURL);
	// 		const { protocol, searchParams, host } = urlObj;
	// 		if (protocol !== "https:" || host !== "www.youtube.com" || searchParams.get("v") === null) {
	// 			return true;
	// 		}
	// 		return false;
	// 	} catch (e) {
	// 		return true;
	// 	}
	// }, [videoURL]);

	// const addVideo = usePreparedRequest<AddVideoResponse, NoParams, AddVideoToPortfolioBody>({
	// 	requestEndpoint: "/portfolio/videos",
	// 	requestMethod: "POST",
	// 	bodyParams: {
	// 		videoURL: videoURL,
	// 		videoName: videoName
	// 	}
	// });

	let normalizedVideoURL = videoURL;
	if (videoURL.includes("youtu.be")) {
		const videoIdSegments = videoURL.split("/");
		const videoId = videoIdSegments[videoIdSegments.length - 1].split("?")[0];
		normalizedVideoURL = `https://www.youtube.com/watch?v=${videoId}`;
	}

	// Send the normalized video URL to the backend
	const videoData = {
		videoName,
		videoURL: normalizedVideoURL
	};

	const addVideoToPortfolio = async () => {
		const { isSuccess, isError, requestError, responseCode, responseData } = await makeAPIRequest<
			AddVideoResponse,
			NoParams,
			AddVideoToPortfolioBody
		>({
			requestEndpoint: "/portfolio/videos",
			requestMethod: "POST",
			bodyParams: videoData // Send the video data to the backend
		});
		if (isSuccess) {
			const { responseStatus } = responseData;
			if (responseStatus === "SUCCESS") {
				const { videoId } = responseData;
				onVideoSuccess({
					videoId,
					videoURL: normalizedVideoURL,
					videoName
				});
			} else if (responseStatus === "ERR_INVALID_BODY_PARAMS") {
				setInvalidName(true);
			}
		}
		onClose();
	};

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Upload New Video</DialogTitle>
			<Divider sx={{ py: 0 }} />
			<DialogContent>
				<Grid container sx={{ alignItems: "center" }} spacing={1}>
					<Grid item xs={12} md={3}>
						<RequiredText>
							<b>Video Name</b>
						</RequiredText>
					</Grid>
					<Grid item xs={12} md={9}>
						<TextField
							fullWidth
							error={invalidName}
							placeholder={"New Video"}
							onChange={(e) => {
								setVideoName(e.target.value);
								if (e.target.value.trim().length === 0) {
									setInvalidName(true);
								} else {
									setInvalidName(false);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box sx={{ py: 1 }} />
					</Grid>
					<Grid item xs={12} md={3}>
						<RequiredText>
							<b>Video Link</b>
						</RequiredText>
					</Grid>
					<Grid item xs={12} md={9}>
						<TextField
							fullWidth
							// error={invalidURL}
							placeholder={"https://www.youtube.com/watch?v=your-youtube-video-id-here"}
							onChange={(e) => {
								setVideoURL(e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: "space-between",
					px: 4
				}}
			>
				<Button variant={"contained"} onClick={onClose} color={"error"}>
					<Iconify icon={"eva:close-fill"} />
					Close Form
				</Button>
				<Button
					variant={"contained"}
					sx={{
						my: 2
					}}
					onClick={addVideoToPortfolio}
				>
					<Iconify icon={"eva:checkmark-fill"} />
					Add Video
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default function PortfolioVideos() {
	const [showForm, setShowForm] = useState<boolean>(false);
	const [activeVideoId, setActiveVideoId] = useState<string | null>(null);
	const serviceCtx = useContext(ServiceContext);

	const { serviceInfo } = serviceCtx;
	const [videoList, setVideoList] = useState<VideoResponseObj[]>([]);

	const selectedVideo =
		videoList.filter((videoObj) => {
			return videoObj.videoId === activeVideoId;
		})[0] || null;

	const profileCtx = useContext(ProfileContext);
	const { portfolioData, setPortfolioData } = useContext(PortfolioContext);

	const fetchVideos = async () => {
		if (profileCtx === null) {
			return;
		}
		const { userType } = profileCtx;
		if (userType !== "SERVICE") {
			return;
		}

		const { linkedServiceType, linkedServiceSlug } = profileCtx;

		if (linkedServiceType === null || linkedServiceSlug === null) {
			return;
		}
		if (linkedServiceType === "VENDOR") {
			const { isSuccess, responseCode, responseData, requestError, isError } = await makeAPIRequest<
				GetPortfolioVideosResponse,
				FetchVendorPortfolioVideosParams
			>({
				requestMethod: "GET",
				requestEndpoint: "/vendors/:vendorSlug/portfolio/videos",
				urlParams: {
					vendorSlug: linkedServiceSlug
				}
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioVideos } = responseData;
					setVideoList(portfolioVideos);
				}
			}
		} else {
			const { isSuccess, responseCode, responseData, requestError, isError } = await makeAPIRequest<
				GetPortfolioVideosResponse,
				FetchVenuePortfolioVideosParams
			>({
				requestMethod: "GET",
				requestEndpoint: "/venues/:venueSlug/portfolio/videos",
				urlParams: {
					venueSlug: linkedServiceSlug
				}
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioVideos } = responseData;
					setVideoList(portfolioVideos);
				}
			}
		}
	};

	useEffect(() => {
		fetchVideos();
	}, [profileCtx]);

	return (
		<>
			<Helmet>
				<title> Videos | Mangal Weddings Dashboard </title>
			</Helmet>
			<Stack spacing={2}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant={"h4"}>
							{`Portfolio - Videos (${portfolioData.portfolioVideoCount})`}
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							justifyContent: "flex-end",
							display: "flex"
						}}
					>
						<Button
							variant={"contained"}
							onClick={() => {
								setShowForm(true);
							}}
							sx={{
								boxShadow: 0
							}}
						>
							<Iconify icon={"eva:plus-fill"} />
							Add a Video
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							<ul>
								<li>Videos help immerse the user in the exact environment provided by you</li>
								<li>You can add videos to your profile by adding them as youtube links</li>
								<li>
									Make sure to use the full link from URL of the youtube
									(https://www.youtube.com/watch?)
								</li>
							</ul>
						</Typography>
					</Grid>
					<VideoForm
						isOpen={showForm}
						onClose={() => {
							setShowForm(false);
						}}
						onVideoSuccess={(videoData) => {
							setVideoList((prevList) => {
								return [videoData, ...prevList];
							});
							setPortfolioData((prevData) => {
								return { ...prevData, portfolioVideoCount: prevData.portfolioVideoCount + 1 };
							});
							setActiveVideoId(videoData.videoId);
						}}
					/>
				</Grid>
				<Divider />
				<Grid container spacing={2} sx={{ width: "100%" }}>
					{videoList.length > 0 ? (
						<Grid item xs={12} md={4}>
							<Stack spacing={1}>
								{videoList.map((videoData) => {
									return (
										<VideoItem
											key={videoData.videoId}
											videoName={videoData.videoName}
											videoId={videoData.videoId}
											isActive={activeVideoId === videoData.videoId}
											setActive={(videoId: string) => {
												setActiveVideoId(videoId);
											}}
											refetchVideos={() => {
												fetchVideos();
											}}
										/>
									);
								})}
							</Stack>
						</Grid>
					) : null}
					<Grid
						item
						xs={12}
						md={videoList.length > 0 ? 8 : 12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						{selectedVideo === null ? (
							<Box>
								<Typography variant={"h6"}>
									{videoList.length > 0 ? (
										<i>Select a video to get started</i>
									) : (
										<i>Add a video to get started</i>
									)}
								</Typography>
							</Box>
						) : (
							<VideoView {...selectedVideo} />
						)}
					</Grid>
				</Grid>
			</Stack>
		</>
	);
}
