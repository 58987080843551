import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Container, Divider, Link as MaterialLink, Typography } from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
// sections
import { LoginForm } from "../sections/auth/login";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../utils/authContext";
import { FrameImage } from "../components/frame/imageFrame";
import { loginLoaderSlice } from "../redux/slice/loginLoderSlice";
import openerloading from "../assets/lottie/instagram-button.json"
import Lottie from "lottie-react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex"
	}
}));

const StyledSection = styled("div")(({ theme }) => ({
	width: "100%",
	maxWidth: 480,
	display: "flex",
	flexDirection: "column",
	justifyContent: "start",
	// @ts-ignore
	boxShadow: theme.customShadows.card,
	backgroundColor: theme.palette.background.default
}));

const StyledContent = styled("div")(({ theme }) => ({
	maxWidth: 480,
	margin: "auto",
	minHeight: "100vh",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	padding: theme.spacing(12, 0)
}));

const LoaderDiv = styled("div")(({ theme }) => ({
	width: "100vw",
	height: "100vh",
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
}))

// ----------------------------------------------------------------------

export default function LoginPage() {
	const dispatch = useDispatch();
	const authCtx = useContext(AuthContext);

	const navigate = useNavigate();
	const mdUp = useResponsive("up", "md");

	const loginLoader = useSelector((state: any) => {
		return state.loginLoader.loader;
	})
	console.log(loginLoader, "check this")
	useEffect(() => {
		const { authStatus } = authCtx;
		let timerId: string | number | NodeJS.Timeout | undefined;

		timerId = setTimeout(() => {
			if (authStatus === "AUTHENTICATED") {
				navigate("/dashboard");
			} else {
				changeGlobalStateLoginLoder()
			}
		}, 800);

		return () => clearTimeout(timerId);
	}, [authCtx, navigate]);

	function changeGlobalStateLoginLoder() {
		dispatch(loginLoaderSlice.actions.deactivateLoader())
	}

	return (
		<>
			<Helmet>
				<title> Login | Mangal Weddings Dashboard </title>
			</Helmet>
			{
				loginLoader ? <LoaderDiv>
					<Lottie
						animationData={openerloading}
						loop={true}
						autoplay={true}
						style={{ width: '300px', height: '300px' }}
					/>


				</LoaderDiv> : <StyledRoot>
					{mdUp && (
						<StyledSection>
							<Typography variant="h3" sx={{ px: 5, mt: 7, mb: 1 }}>
								Hi, Welcome Back
							</Typography>
							{/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
							<FrameImage />
						</StyledSection>
					)}

					<Container maxWidth="sm">
						<StyledContent>
							<Typography variant="h4" gutterBottom>
								Sign in
							</Typography>
							<Typography variant="body2">
								Don’t have an account? {""}
								<MaterialLink onClick={() => changeGlobalStateLoginLoder()} variant={"subtitle2"}>
									<Link to={"/signup"}>Create one</Link>
								</MaterialLink>
							</Typography>
							<Divider sx={{ my: 2 }} />
							<LoginForm />
						</StyledContent>
					</Container>
				</StyledRoot>
			}

		</>
	);
}
