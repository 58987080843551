import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Breathing, Image } from "react-shimmer";
import Iconify from "../components/iconify";
import {
	APIResponseData,
	GetPortfolioPhotosResponse,
	PhotoResponseObj,
	UploadImageResponse
} from "../utils/apiResponses";
import ServiceContext from "../utils/serviceContext";
import { makeAPIRequest } from "../utils/apiHandler";
import {
	AddPhotoToPortfolioBody,
	DeletePhotoFromPortfolioParams,
	FetchVendorPortfolioPhotosParams,
	FetchVenuePortfolioPhotosParams,
	NoParams
} from "../utils/apiRequests";
import { manageMedia } from "../utils/mediaHandler";
import ProfileContext from "../utils/profileContext";
import { S3ObjectMethods } from "../utils/commonTypes";
import { checkMultipleImageFileDimensions, createSlugFromIdentifiers, IMAGE_DIMENSIONS } from "../utils/commonUtils";
import PortfolioContext from "../utils/portfolioContext";
import { Helmet } from "react-helmet-async";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import ReactCrop, { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type PhotoFormProps = {
	isOpen: boolean;
	onClose: () => void;
	onFilesUpload: (uploadedFiles: PhotoResponseObj[]) => void;
};

function CropDemo({ src }: { src: any }) {
	const [crop, setCrop] = useState<Crop>();
	return (
		<ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
			<img src={src} alt="" />
		</ReactCrop>
	);
}

function PhotoForm({ isOpen, onClose, onFilesUpload }: PhotoFormProps) {
	const [imageLoader,setImageLoader]=useState(false)
	const [imageErrorSuccess,setImageErrorSuccess]=useState({error:false,success:false})
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [crop, setCrop] = useState<Crop>({
		unit: "%", // Can be 'px' or '%'
		x: 25,
		y: 25,
		width: 50,
		height: 50
	});

	const profileCtx = useContext(ProfileContext);

	const uploadFiles = useCallback(async () => {
		setImageLoader(true)
		if (profileCtx === null) {
			setImageLoader(false)
			setImageErrorSuccess({error:true,success:false})
			return;
		}

		const [allImagesPassed, imageResults] = await checkMultipleImageFileDimensions(
			selectedFiles,
			IMAGE_DIMENSIONS.PORTFOLIO_PHOTO
		);

		// if (!allImagesPassed) {
		// 	alert(`Please upload images of size (${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgWidth}x${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgHeight})`);
		// 	return;
		// }

		const { userType } = profileCtx;

		if (userType !== "SERVICE") {
			setImageLoader(false)
			setImageErrorSuccess({error:true,success:false})
			return;
		}

		const { linkedServiceType, linkedServiceSlug } = profileCtx;

		const objectKeyGenFn = (fileHandle: File, fileIdx: number, reqMethod: S3ObjectMethods) => {
			const { name } = fileHandle;

			const fileNameSegments = name.split(".");
			const fileName = fileNameSegments.slice(0, -1).join("");
			const fileExt = fileNameSegments[fileNameSegments.length - 1] || "";

			const fileSlug = createSlugFromIdentifiers(
				linkedServiceSlug,
				new Date().toISOString(),
				...fileNameSegments
			);

			if (linkedServiceType === "VENUE") {
				return `media/venues/${linkedServiceSlug}/portfolio/photos/${fileSlug}.${fileExt}`;
			} else {
				return `media/vendors/${linkedServiceSlug}/portfolio/photos/${fileSlug}.${fileExt}`;
			}
		};

		const mediaResponses = await manageMedia({
			mediaFiles: selectedFiles,
			objectKeyGenFn: objectKeyGenFn,
			fileMethod: "PUT"
		});

		const photoResponses = await Promise.all(
			mediaResponses.map(async (mediaObj) => {
				const { isSuccess, isError, requestError, responseCode, responseData } = await makeAPIRequest<
					UploadImageResponse,
					NoParams,
					AddPhotoToPortfolioBody
				>({
					requestMethod: "POST",
					requestEndpoint: "/portfolio/photos",
					bodyParams: {
						objectKey: mediaObj.objectKey
					}
				});

				if (isSuccess) {
					const { responseStatus } = responseData;
					if (responseStatus === "SUCCESS") {
						const { photoId } = responseData;
						return {
							objectKey: mediaObj.objectKey,
							photoId: photoId
						};
					}
				}
				return undefined;
			})
		);

		const filteredPhotoResponses = photoResponses.filter((respObj) => {
			return respObj !== undefined;
		});

		const fetchedPhotos = await manageMedia({
			mediaFiles: selectedFiles,
			objectKeyGenFn: objectKeyGenFn,
			fileMethod: "GET"
		});

		const photoEntries = filteredPhotoResponses.map((photoResp) => {
			return [photoResp!.objectKey, photoResp!.photoId];
		});

		const photoKeyIdMap = Object.fromEntries(photoEntries) as { [objectKey: string]: string };

		const uploadConfirmedPhotos = fetchedPhotos.map((photoResp) => {
			const { objectKey, requestStatus, requestAcknowledged, presignedURL } = photoResp;
			if (!requestAcknowledged || requestStatus !== "ACK") {
				return undefined;
			}
			const photoId = photoKeyIdMap[objectKey];

			if (photoId === undefined) {
				return undefined;
			}

			return {
				photoId: photoId,
				photoURL: presignedURL,
				photoObjectKey: photoResp.objectKey
			} satisfies PhotoResponseObj;
		});

		const filteredPhotos = uploadConfirmedPhotos.filter((photo) => {
			return photo !== undefined;
		});

		onFilesUpload(filteredPhotos as PhotoResponseObj[]);
		setImageLoader(false)
        setImageErrorSuccess({error:false,success:true})
		onClose();
		setImageErrorSuccess({error:false,success:false})
	}, [onClose, onFilesUpload, profileCtx, selectedFiles]);

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<ToastContainer />
			<DialogTitle>Upload New Photos</DialogTitle>
			<DialogContent>
				<Grid container sx={{ alignItems: "center" }} spacing={2}>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						{/* react image  */}
						<ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
							{/* <img src={selectedFiles && selectedFiles[0].name} alt=""/> */}
						</ReactCrop>
						<CropDemo src={selectedFiles && selectedFiles[0] && selectedFiles[0].name} />
						<TextField
							type={"file"}
							inputProps={{
								accept: ".jpg, .jpeg, .png",
								multiple: true
							}}
							fullWidth
							onChange={(e) => {
								setImageLoader(false)
								setImageErrorSuccess({error:false,success:false})
								// @ts-ignore
								const fileList = e.target.files as FileList;

								const fileArray = Array.from(fileList);
								// const Oldcount = fileArray.length
								// fileArray = fileArray.filter((data, index) => data.size < 3000000)
								// if (Oldcount !== fileArray.length) {
								// 	console.log("check error")
								// 	toast.error(`${Math.floor(Oldcount - fileArray.length)} File Exceed The Size Limit , File Should Be Less Than 3 MB`)
								// }

								setSelectedFiles(fileArray);
							}}
						/>
						<div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"15px"}}><p style={{ color: "red", fontSize: "13px"}}>Allowed file types: JPG, JPEG, PNG </p>{imageLoader?<CircularProgress style={{width:"20px",height:"20px"}} />:null}{imageErrorSuccess.error?<ErrorIcon style={{width:"25px",height:"25px",color:"red"}}/>:null}{imageErrorSuccess.success?<CheckCircleIcon style={{width:"25px",height:"25px",color:"green"}}/>:null}</div>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: "space-between",
					px: 4
				}}
			>
				<Button variant={"contained"} onClick={onClose} color={"error"}>
					<Iconify icon={"eva:close-fill"} />
					Close Form
				</Button>
				<Button
					variant={"contained"}
					sx={{
						my: 2
					}}
					onClick={uploadFiles}
					disabled={selectedFiles.length === 0}
				>
					<Iconify icon={"eva:checkmark-fill"} />
					Upload Photos
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default function PortfolioPhotos() {
	const [showForm, setShowForm] = useState(false);
	const serviceCtx = useContext(ServiceContext);

	const [portfolioPhotos, setPortfolioPhotos] = useState<PhotoResponseObj[]>([]);

	const { portfolioData, setPortfolioData } = useContext(PortfolioContext);

	const onFilesUpload = (uploadedFiles: PhotoResponseObj[]) => {
		setPortfolioPhotos((existingPhotos) => {
			return [...uploadedFiles, ...existingPhotos];
		});
		setPortfolioData((prevData) => {
			return {
				...prevData,
				portfolioPhotoCount: prevData.portfolioPhotoCount + 1
			};
		});
	};

	const fetchPhotos = async () => {
		const { serviceInfo } = serviceCtx;
		const { serviceType, serviceData } = serviceInfo;

		if (serviceType === null || serviceData === null) {
			return;
		}

		if (serviceType === "VENDOR") {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetPortfolioPhotosResponse,
				FetchVendorPortfolioPhotosParams
			>({
				requestEndpoint: "/vendors/:vendorSlug/portfolio/photos",
				requestMethod: "GET",
				urlParams: {
					vendorSlug: serviceData.vendorSlug
				}
			});
			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioPhotos: responsePhotos } = responseData;
					setPortfolioPhotos(responsePhotos);
				}
			}
		} else {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetPortfolioPhotosResponse,
				FetchVenuePortfolioPhotosParams
			>({
				requestEndpoint: "/venues/:venueSlug/portfolio/photos",
				requestMethod: "GET",
				urlParams: {
					venueSlug: serviceData.venueSlug
				}
			});
			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioPhotos: responsePhotos } = responseData;
					setPortfolioPhotos(responsePhotos);
				}
			}
		}
	};

	const [deletePhotoActive, setDeletePhotoActive] = useState(false);

	useEffect(() => {
		fetchPhotos();
	}, [serviceCtx]);

	const handleDeletePhoto = async (photoId: string) => {
		setDeletePhotoActive(true);
		const { isSuccess, responseData } = await makeAPIRequest<APIResponseData, DeletePhotoFromPortfolioParams>({
			requestEndpoint: "/portfolio/photos/:photoId",
			requestMethod: "DELETE",
			urlParams: {
				photoId: photoId
			}
		});
		if (isSuccess && responseData.responseStatus === "SUCCESS") {
			setPortfolioData((prevData) => {
				return {
					...prevData,
					portfolioPhotoCount: prevData.portfolioPhotoCount - 1
				};
			});
			await fetchPhotos();
		}
		setDeletePhotoActive(false);
	};

	return (
		<>
			<Helmet>
				<title> Photos | Mangal Weddings Dashboard </title>
			</Helmet>
			<Stack spacing={2}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant={"h4"}>{`Portfolio - Photos (${portfolioPhotos.length})`}</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						sx={{
							justifyContent: "flex-end",
							display: "flex"
						}}
					>
						<Button
							variant={"contained"}
							onClick={() => {
								setShowForm(true);
							}}
							sx={{
								boxShadow: 0
							}}
						>
							<Iconify icon={"eva:plus-fill"} />
							Upload Photo
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							Your portfolio brings out the best works of art from your services. Here, you can upload
							select photos that will be shown on your profile
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<PhotoForm
						isOpen={showForm}
						onClose={() => {
							setShowForm(false);
						}}
						onFilesUpload={onFilesUpload}
					/>
				</Grid>
				<Grid container spacing={2} sx={{ width: "100%" }}>
					{portfolioPhotos.map((photoObj) => {
						const { photoId, photoURL } = photoObj;
						return (
							<Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={photoId}>
								<Stack direction={"column"} sx={{}}>
									{/*// @ts-ignore*/}
									<Image
										src={photoURL}
										delay={0}
										fallback={<Breathing width={480} height={480} />}
										NativeImgProps={{
											alt: `Portfolio ${photoId}`,
											style: {
												borderRadius: 16
											}
										}}
									/>
									<LoadingButton
										loading={deletePhotoActive}
										color="error"
										onClick={() => handleDeletePhoto(photoId)}
									>
										Delete Photo <DeleteIcon />
									</LoadingButton>
								</Stack>
							</Grid>
						);
					})}
				</Grid>
			</Stack>
		</>
	);
}
