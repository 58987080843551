import React, { useEffect, useState } from "react";
import { useAPIRequest } from "../../utils/apiHandler";
import { Box, Divider, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import ChatCard from "./ChatCard";
import ChatScreen from "./ChatScreen";
import { useTheme } from "@mui/material/styles";
import { GetConversationsResponse } from "../../utils/apiResponses";
import { GetConversationQuery, NoParams } from "../../utils/apiRequests";
import { ConversationSchema } from "../../utils/mongoSchema";

export type Conversation = GetConversationsResponse["userConversations"][number]

const Chat = () => {
	const [conversationData, setConversationData] = useState<Conversation[]>([]);
	const [activeConversation, setActiveConversation] = useState<string | null>(null);
	const [conversationStatus, setConversationStatus] = useState<"ALL" | "BOOKED">("ALL");

	const theme = useTheme();

	const conversationStatusMap = {
		"ALL": {
			_: "_"
		},
		"BOOKED": {
			conversationStatus: "BOOKED" satisfies ConversationSchema["conversationStatus"]
		}
	};

	const {
		isLoading,
		isSuccess,
		isError,
		requestError,
		responseCode,
		responseData
	} = useAPIRequest<GetConversationsResponse, NoParams, NoParams, GetConversationQuery>({
		requestEndpoint: "/conversations",
		requestMethod: "GET",
		queryParams: conversationStatusMap[conversationStatus],
		requestDeps: [conversationStatus]
	});

	useEffect(() => {
		if (isSuccess && responseData) {
			const { responseStatus } = responseData;
			if (responseStatus === "SUCCESS") {
				const { userConversations } = responseData;
				setConversationData(userConversations);
			}
		}
	}, [isLoading, isSuccess, responseData]);

	const activeConversationObject = (activeConversation !== null && (
		conversationData.filter((conversationDat) => {
			return conversationDat.conversationId === activeConversation;
		})[0]
	)) || null;

	return (
		<Stack spacing={4}>
			<Typography variant={"h4"}>
				Chats
			</Typography>
			<Divider sx={{ py: 0 }} />
			<Grid container>
				<Grid item xs={12} md={
					conversationData.length ? 4 : 12
				} sx={{
					overflowY: "scroll"
				}}>
					<Stack spacing={1}>
						<Select value={conversationStatus} onChange={(e) => {
							setActiveConversation(null);
							setConversationStatus(e.target.value as ("ALL" | "BOOKED"));
						}}>
							<MenuItem value="ALL">All Chats</MenuItem>
							<MenuItem value="BOOKED">Confirmed Booking</MenuItem>
						</Select>
						<Divider sx={{ py: 0 }} />
						{
							conversationData.length ? (
								conversationData.map((conversationObject) => {
									const { conversationId, conversationData } = conversationObject;
									return (
										<ChatCard
											conversationId={conversationId}
											key={conversationId}
											conversationData={conversationData}
											onChatClick={() => {
												setActiveConversation(conversationId);
											}}
										/>
									);
								})
							) : (
								<Box>
									<Typography
										color={theme.palette.text.secondary}
										align={"center"}
									>
										<i>No chats to display</i>
									</Typography>
								</Box>
							)
						}
						<Divider sx={{ py: 0 }} />
					</Stack>
				</Grid>
				{
					activeConversation !== null && activeConversationObject !== null ? (
						<Grid
							item xs={12}
							md={8}
							sx={{
								minHeight: "40vh",
								maxHeight: "60vh",
								overflowY: "scroll",
								overflowX: "none",
								paddingLeft: 1,
								paddingTop: 1
							}}
						>
							<ChatScreen
								conversationData={activeConversationObject.conversationData}
								conversationId={activeConversationObject.conversationId}
							/>
						</Grid>
					) : (
						null
					)
				}
			</Grid>
		</Stack>
	);
};

export default Chat;
