import { VendorSchema, VenueSchema } from "./mongoSchema";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type ServiceData = ({
	serviceType: "VENUE",
	serviceData: VenueSchema,
	invalidFields: (keyof VenueSchema)[]
} | {
	serviceType: "VENDOR",
	serviceData: VendorSchema,
	invalidFields: (keyof VendorSchema)[]
} | {
	serviceType: null,
	serviceData: null,
	invalidFields: never[]
}) & {
	isPartialInfo: boolean,
}

export type ServiceContextType = {
	serviceInfo: ServiceData,
	setServiceInfo: Dispatch<SetStateAction<ServiceData>>,
	saveServiceInfo: () => Promise<boolean>,
}

const ServiceContext = createContext<ServiceContextType>({
	serviceInfo: {
		serviceData: null,
		serviceType: null,
		isPartialInfo: true,
		invalidFields: []
	},
	setServiceInfo: (data) => {
		throw new Error("Attempted to access service data before initialization");
	},
	saveServiceInfo: async () => {
		return false;
	}
});

type InvalidFieldCheckArgs = {
	venueField: (keyof VenueSchema) | false,
	vendorField: (keyof VendorSchema) | false
}

export function useInvalidField(args: InvalidFieldCheckArgs): boolean {
	const { venueField, vendorField } = args;
	const { serviceInfo, setServiceInfo } = useContext(ServiceContext);
	const { serviceType, invalidFields } = serviceInfo;

	if (serviceType === "VENDOR") {
		if (vendorField === false) {
			return false;
		}

		const removeInvalidField = () => {
			const otherFields = invalidFields.filter((existingInvField) => {
				return existingInvField !== vendorField;
			});

			setServiceInfo({
				...serviceInfo,
				invalidFields: otherFields
			});
		};

		return invalidFields.includes(vendorField);
	} else if (serviceType === "VENUE") {
		if (venueField === false) {
			return false;
		}

		const removeInvalidField = () => {
			const otherFields = invalidFields.filter((existingInvField) => {
				return existingInvField !== venueField;
			});

			setServiceInfo({
				...serviceInfo,
				invalidFields: otherFields
			});
		};

		return invalidFields.includes(venueField);
	} else {
		return false;
	}
}

export default ServiceContext;