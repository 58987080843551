import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Container, Divider, Link as MaterialLink, Typography } from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import SignupForm from "../sections/auth/signup/SignupForm";
// sections

// ----------------------------------------------------------------------
import { FrameImage } from "../components/frame/imageFrame";
const StyledRoot = styled("div")(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex"
	}
}));

const StyledSection = styled("div")(({ theme }) => ({
	width: "100%",
	maxWidth: 480,
	display: "flex",
	flexDirection: "column",
	justifyContent: "start",
	boxShadow: theme.customShadows.card,
	backgroundColor: theme.palette.background.default
}));

const StyledContent = styled("div")(({ theme }) => ({
	maxWidth: 480,
	margin: "auto",
	minHeight: "100vh",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function SignupPage() {
	const mdUp = useResponsive("up", "md");

	return (
		<>
			<Helmet>
				<title> Sign Up | Mangal Weddings Dashboard </title>
			</Helmet>

			<StyledRoot>
				{mdUp && (
					<StyledSection >
						<Typography variant="h3" sx={{ px: 5, mt: 7, mb: 1 }}>
							Let's get started
						</Typography>
						{/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
						<FrameImage/>
						{/* <img src={loginsignupimage} alt="..." /> */}
					</StyledSection>
				)}

				<Container maxWidth="sm">
					<StyledContent>
						<Typography variant="h4" gutterBottom>
							Sign up as a Service Provider
						</Typography>

						<Typography variant="body2">
							Already have an account? {""}
							<MaterialLink variant={"subtitle2"}>
								<Link to={"/login"}>Log In</Link>
							</MaterialLink>
						</Typography>
						<Divider sx={{ my: 2 }} />
						<SignupForm />
					</StyledContent>
				</Container>
			</StyledRoot>
		</>
	);
}
