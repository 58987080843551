import { MetadataObject, VendorQuestionnaireSchema } from "../../utils/mongoSchema";
import { useValueSelector } from "../../utils/hooks";
import { selectMetadataValue, zipMetadata } from "../../utils/schemaMetadata";
import { Grid, MenuItem, Select, Typography } from "@mui/material";

type NumericSelectQuestionProps =
	Omit<VendorQuestionnaireSchema, "vendorCategory" | "vendorSubcategory" | "questionType" | "vendorMetadataType" | "vendorMetadataDefaultValue">
	& {
	questionType: "NUMERIC_SELECT",
	vendorMetadataType: "NUMBER",
	vendorMetadataDefaultValue: number
}

export function NumericSelectQuestion(props: NumericSelectQuestionProps) {
	const {
		questionText,
		questionOptions,
		vendorMetadataType,
		vendorMetadataDependencies,
		vendorMetadataKey,
		vendorMetadataDefaultValue
	} = props;

	const [vendorMetadata, setVendorMetadata] = useValueSelector({
		defaultValue: () => {
			return [] as MetadataObject[];
		},
		vendorValue: (vendorData) => {
			return vendorData.vendorMetadata;
		},
		venueValue: () => {
			return [];
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue)
			};
		},
		updateVenueValue: (prevValue, partialValue) => {
			return prevValue;
		}
	});

	const currentMetadataValue = selectMetadataValue(vendorMetadata, vendorMetadataKey, vendorMetadataDefaultValue as number);

	return (
		<>
			<Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
				<Typography>
					<b>
						{questionText}
					</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					fullWidth
					value={
						currentMetadataValue as number
					}
					onChange={(e) => {
						setVendorMetadata([
							// @ts-ignore
							...vendorMetadata,
							{
								propertyName: vendorMetadataKey,
								propertyType: "NUMBER",
								// @ts-ignore
								propertyValue: e.target.value as number
							}
						]);
					}}
				>
					{
						questionOptions.map((questionOpt) => {
							const { optionText, optionValue } = questionOpt;

							return (
								<MenuItem
									value={optionValue as number}
									key={`${optionText}.${optionValue}`}
								>
									{optionText}
								</MenuItem>
							);
						})
					}
				</Select>
			</Grid>
		</>
	);

}