import { useContext } from "react";
import ServiceContext from "../../utils/serviceContext";
import SocialLink, { SocialLinkType } from "./SocialLink";
import { Divider, Grid } from "@mui/material";

export default function SocialQuestions(){
	const {serviceInfo} = useContext(ServiceContext)
	const {serviceType} = serviceInfo

	if (serviceType === null) return null

	return (
		<>
			{
				(["FB", "IG", "WS", "YT"] satisfies SocialLinkType[]).map((socialLinkType) => {
					return (
						<>
							<SocialLink linkType={socialLinkType} key={socialLinkType} />
						</>
					)
				})
			}
		</>
	)
}