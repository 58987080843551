import ReactDOM from "react-dom/client";

//
import App from "./App";
import ThemeProvider from "./theme";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<HelmetProvider>
		<BrowserRouter>
			<ThemeProvider>
				<App />
			</ThemeProvider>
		</BrowserRouter>
	</HelmetProvider>
);

