// @ts-ignore
import loginSignUpImage from "../../assets/loginsignuppage/vendorloginPage.jpg";
import styled from "styled-components";
const MainFrameDiv=styled.div`
padding: 15px;
max-height: 70vh;
`;
const SecondFrameDiv=styled.div`
padding: 15px;
/* background-color: orange; */
border-radius: 8px;  
max-height: 70vh;
`;

const Image=styled.img`
  box-shadow:
    -50px -50px 0 -40px tomato,
    50px 50px 0 -40px orange;

    cursor: none;
    &:hover{
        box-shadow:
    -50px -50px 0 -40px orange,
    50px 50px 0 -40px tomato;
    }
    max-height: 60vh;
    transition: all 0.3s linear;

`
export function FrameImage() {
    return (
        <MainFrameDiv>
            <SecondFrameDiv>
                <Image src={loginSignUpImage} alt="..." />
            </SecondFrameDiv>

        </MainFrameDiv>
    )
}


