import { VendorSchema, VenueSchema } from "./mongoSchema";

export function createSlugFromIdentifiers(...identifierList: string[]) {
	const objectName = identifierList.join(" ");
	const specialStrippedObjectName = objectName.replace(
		/[^\w\s-]/gi,
		""
	);

	const whitespaceReplacedObjectName = specialStrippedObjectName.replace(
		/\s/gi,
		"-"
	);

	const lowercaseSlug = whitespaceReplacedObjectName.toLowerCase();

	const slugChars = Array.from(lowercaseSlug);
	const slugCharCodes = slugChars.map((slugChar) => {
		return slugChar.charCodeAt(0);
	});
	const slugCharSum = slugCharCodes.reduce((previousValue, currentValue) => {
		return previousValue + currentValue;
	});

	return lowercaseSlug + "-" + slugCharSum;
}

export enum ServicePremiumStatus {
	PREMIUM,
	HIGHLIGHT,
	BASIC
}

type CreateErrorStringArgs = {
	serviceType: "VENUE"
	invalidFields: (keyof VenueSchema)[]
} | {
	serviceType: "VENDOR",
	invalidFields: (keyof VendorSchema)[]
}

export function createErrorString({ invalidFields, serviceType }: CreateErrorStringArgs) {
	if (serviceType === "VENUE") {
		const fieldMap: Record<keyof VenueSchema, string | null> = {
			venueBaseRate: "Pricing - Base Rate",
			venueDescription: "My Profile - Description",
			venueEnvironment: "Locations",
			venueListingStatus: null,
			venueLocationTypes: null,
			venueLocations: "Locations",
			venueMaxCapacity: "Locations - Capacity",
			venueMetadata: "Features",
			venueMinCapacity: "Locations - Capacity",
			venueName: "My Profile - Name",
			venuePremiumStatus: null,
			venuePricingRate: "Pricing - Rates",
			venueRatingAverage: null,
			venueRatingCount: null,
			venueSlug: null,
			venueCity: "My Profile - City",
			venueCoverImageObjectKey: "My Profile - Cover Image",
			venueViewCount: null,
			venueCoordinates: null,
			venueLongitude: "My Profile - Location",
			venueLatitude: "My Profile - Location"
		};

		const invalidPages = invalidFields.map((invalidField) => {
			return fieldMap[invalidField];
		});

		const invalidFilteredPages = invalidPages.filter((pageName) => {
			return pageName !== null;
		}) as string[];

		if (invalidFilteredPages.length === 0) {
			return `An error occurred when updating your listing`;
		}

		const deduplicatedPages = [...new Set(invalidFilteredPages)];

		return `Please check the following fields: ${deduplicatedPages.join(", ")}`;
	} else {
		const fieldMap: Record<keyof VendorSchema, string | null> = {
			vendorCategory: "Dashboard",
			vendorCity: "My Profile - ",
			vendorDescription: "My Profile - Description",
			vendorListingStatus: null,
			vendorMetadata: "Features",
			vendorName: "My Profile - Name",
			vendorPackages: "Pricing - Packages",
			vendorPremiumStatus: null,
			vendorRatingAverage: null,
			vendorRatingCount: null,
			vendorServiceRate: "Pricing - Base Rate",
			vendorServiceTypes: "Features",
			vendorSlug: null,
			vendorSubcategory: "Dashboard",
			vendorCoverImageObjectKey: "My Profile - Cover Image",
			vendorViewCount: null,
			vendorCoordinates: null,
			vendorLongitude: "My Profile - Map",
			vendorLatitude: "My Profile - Map"
		};

		const invalidPages = invalidFields.map((invalidField) => {
			return fieldMap[invalidField];
		});

		const invalidFilteredPages = invalidPages.filter((pageName) => {
			return pageName !== null;
		}) as string[];

		if (invalidFilteredPages.length === 0) {
			return `An error occurred when updating your listing`;
		}

		const deduplicatedPages = [...new Set(invalidFilteredPages)];

		return `Please check the following fields: ${deduplicatedPages.join(", ")}`;
	}
}

export const IMAGE_DIMENSIONS = {
	PORTFOLIO_PHOTO: {
		imgHeight: 1080,
		imgWidth: 1600
	} as ImageDimensions,
	COVER_IMAGE: {
		imgHeight: 600,
		imgWidth: 1600
	} as ImageDimensions
} as const;

export const IMAGE_FLEX_PX = 50

export type ImageDimensions = {
	imgHeight: number,
	imgWidth: number
}

export function checkImageFileDimensions(fileObj: File, imageDimensions: ImageDimensions): Promise<[boolean, ImageDimensions]> {
	return new Promise((resolve, reject) => {
		try {
			const tempImage = new Image();
			tempImage.style.display = "none";
			tempImage.src = window.URL.createObjectURL(fileObj);

			tempImage.onload = () => {
				const { imgHeight, imgWidth } = imageDimensions;
				const heightValid =
					tempImage.naturalHeight >= (imgHeight - IMAGE_FLEX_PX) &&
					tempImage.naturalHeight <= (imgHeight + IMAGE_FLEX_PX)


				const widthValid =
					tempImage.naturalWidth >= (imgWidth - IMAGE_FLEX_PX) &&
					tempImage.naturalWidth <= (imgWidth + IMAGE_FLEX_PX)

				resolve([
					(heightValid && widthValid),
					{
						imgHeight: tempImage.naturalHeight,
						imgWidth: tempImage.naturalWidth
					}
				]);
			};
		} catch (e) {
			reject(e);
		}
	});
}

export async function checkMultipleImageFileDimensions(fileCheck: File[], imageDimensions: ImageDimensions | ImageDimensions[]): Promise<[boolean, [number, boolean, ImageDimensions][]]> {
	let finalImgDimensions: ImageDimensions[] = [];
	if (Array.isArray(imageDimensions) && imageDimensions.length === fileCheck.length) {
		finalImgDimensions = [
			...imageDimensions
		];
	} else {
		if (Array.isArray(imageDimensions)) {
			finalImgDimensions = Array(fileCheck.length).fill(imageDimensions[0]) as ImageDimensions[];
		} else {
			finalImgDimensions = Array(fileCheck.length).fill(imageDimensions) as ImageDimensions[];
		}
	}

	const mappedResults = await Promise.all(
		fileCheck.map(async (fileObj, fileIdx) => {
			const [dimensionsPassed, imgDim] = await checkImageFileDimensions(fileObj, finalImgDimensions[fileIdx]);
			return [fileIdx, dimensionsPassed, imgDim] satisfies [number, boolean, ImageDimensions];
		})
	);

	const everyImagePassed = mappedResults.every((imgResult) => {
		const [_1, imgPassed, _2] = imgResult;
		return imgPassed === true;
	});

	return [
		everyImagePassed,
		mappedResults
	];
}

export const AUTH_TOKEN_LS_NAME = "mw-auth-token" as const