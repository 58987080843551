import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
// mock
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
//
import { icon } from "./config";
import ServiceContext from "../../../utils/serviceContext";
import { useValueSelector } from "../../../utils/hooks";
import PortfolioContext from "../../../utils/portfolioContext";
import { VendorSchema, VenueSchema } from "../../../utils/mongoSchema";
import { GetPortfolioPhotosResponse, PhotoResponseObj } from "../../../utils/apiResponses";
import { makeAPIRequest } from "../../../utils/apiHandler";
import { FetchVendorPortfolioPhotosParams, FetchVenuePortfolioPhotosParams } from "../../../utils/apiRequests";

// ----------------------------------------------------------------------

const NAV_WIDTH = "20vw";

const StyledAccount = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: alpha(theme.palette.grey[500], 0.12)
}));

// ----------------------------------------------------------------------

type NavProps = {
	openNav: boolean;
	onCloseNav: () => void;
};

type NavConfigType = {
	title: string;
	path: string;
	icon: JSX.Element | React.ReactNode;
	end?: boolean;
	inset?: boolean;
	enableForPartial?: boolean;
	isInvalid: boolean;
};

export default function Nav({ openNav, onCloseNav }: NavProps) {
	const { serviceInfo } = useContext(ServiceContext);
	const serviceCtx = useContext(ServiceContext);
	const { isPartialInfo, serviceType, serviceData, invalidFields } = serviceInfo;

	const { portfolioData } = useContext(PortfolioContext);
	const [portfolioPhotos, setPortfolioPhotos] = useState<PhotoResponseObj[]>([]);
	const { portfolioPhotoCount, portfolioAlbumCount, portfolioVideoCount } = portfolioData;

	const { pathname } = useLocation();

	const isDesktop = useResponsive("up", "lg");

	const fetchPhotos = async () => {
		const { serviceInfo } = serviceCtx;
		const { serviceType, serviceData } = serviceInfo;

		if (serviceType === null || serviceData === null) {
			return;
		}

		if (serviceType === "VENDOR") {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetPortfolioPhotosResponse,
				FetchVendorPortfolioPhotosParams
			>({
				requestEndpoint: "/vendors/:vendorSlug/portfolio/photos",
				requestMethod: "GET",
				urlParams: {
					vendorSlug: serviceData.vendorSlug
				}
			});
			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioPhotos: responsePhotos } = responseData;
					setPortfolioPhotos(responsePhotos);
				}
			}
		} else {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetPortfolioPhotosResponse,
				FetchVenuePortfolioPhotosParams
			>({
				requestEndpoint: "/venues/:venueSlug/portfolio/photos",
				requestMethod: "GET",
				urlParams: {
					venueSlug: serviceData.venueSlug
				}
			});
			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioPhotos: responsePhotos } = responseData;
					setPortfolioPhotos(responsePhotos);
				}
			}
		}
	};

	const baseNavConfig: NavConfigType[] = [
		{
			title: "dashboard",
			path: "/dashboard",
			icon: icon("ic_analytics"),
			enableForPartial: true,
			isInvalid: false
		},
		{
			title: "my profile",
			path: "/dashboard/profile",
			icon: icon("ic_user"),
			isInvalid: (() => {
				if (isPartialInfo) {
					return true;
				}
				if (serviceType === null) {
					return false;
				}
				if (serviceType === "VENDOR") {
					return (["vendorName", "vendorDescription", "vendorCity"] satisfies (keyof VendorSchema)[]).some(
						(someKey) => {
							return invalidFields.includes(someKey);
						}
					);
				} else {
					return (["venueName", "venueDescription", "venueCity"] satisfies (keyof VenueSchema)[]).some(
						(someKey) => {
							return invalidFields.includes(someKey);
						}
					);
				}
			})()
		}
	];

	const extendedNavConfig: NavConfigType[] = [
		{
			title: "features",
			path: "/dashboard/features",
			icon: icon("ic_analytics"),
			isInvalid: (() => {
				if (isPartialInfo) {
					return false;
				}
				if (serviceType === null) {
					return false;
				}
				if (serviceType === "VENDOR") {
					return (["vendorServiceTypes", "vendorMetadata"] satisfies (keyof VendorSchema)[]).some(
						(someKey) => {
							return invalidFields.includes(someKey);
						}
					);
				} else {
					return (["venueMetadata"] satisfies (keyof VenueSchema)[]).some((someKey) => {
						return invalidFields.includes(someKey);
					});
				}
			})()
		},
		{
			title: "portfolio",
			path: "/dashboard/portfolio",
			icon: icon("ic_albums"),
			end: false,
			isInvalid: (() => {
				if (isPartialInfo) {
					return false;
				}
				if (serviceType === null) {
					return false;
				}
				return portfolioPhotoCount === 0 || portfolioAlbumCount === 0 || portfolioVideoCount === 0;
			})()
		},
		{
			title: `photos (${portfolioPhotos.length})`,
			path: "/dashboard/portfolio/photos",
			icon: icon("ic_photos"),
			inset: true,
			isInvalid: (() => {
				if (isPartialInfo) {
					return false;
				}
				if (serviceType === null) {
					return false;
				}
				return portfolioPhotoCount === 0;
			})()
		},
		{
			title: `albums (${portfolioAlbumCount})`,
			path: "/dashboard/portfolio/albums",
			icon: icon("ic_albums"),
			inset: true,
			isInvalid: (() => {
				if (isPartialInfo) {
					return false;
				}
				if (serviceType === null) {
					return false;
				}
				return portfolioAlbumCount === 0;
			})()
		},
		{
			title: `videos (${portfolioVideoCount})`,
			path: "/dashboard/portfolio/videos",
			icon: icon("ic_videos"),
			inset: true,
			isInvalid: (() => {
				if (isPartialInfo) {
					return false;
				}
				if (serviceType === null) {
					return false;
				}
				return portfolioVideoCount === 0;
			})()
		},
		{
			title: "pricing",
			path: "/dashboard/pricing",
			icon: icon("ic_pricing"),
			isInvalid: (() => {
				if (isPartialInfo) {
					return false;
				}
				if (serviceType === null) {
					return false;
				}
				if (serviceType === "VENDOR") {
					return (["vendorPackages"] satisfies (keyof VendorSchema)[]).some((someKey) => {
						return invalidFields.includes(someKey);
					});
				} else {
					return (["venueBaseRate", "venuePricingRate"] satisfies (keyof VenueSchema)[]).some((someKey) => {
						return invalidFields.includes(someKey);
					});
				}
			})()
		},
		{
			title: "chat",
			path: "/dashboard/chat",
			icon: icon("ic_chat"),
			inset: false,
			isInvalid: false
		}
	];

	const commonNavConfig = [
		...baseNavConfig,
		...extendedNavConfig
		// {
		//   title: 'Not found',
		//   path: '/404',
		//   icon: icon('ic_disabled'),
		// },
	];

	const venueNavConfig: NavConfigType[] = [
		...baseNavConfig,
		{
			title: "locations",
			path: "/dashboard/locations",
			icon: icon("ic_locations"),
			inset: false,
			isInvalid: (() => {
				if (isPartialInfo) {
					return false;
				}
				if (serviceType === null) {
					return false;
				}
				if (serviceType === "VENDOR") {
					return false;
				} else {
					return (["venueLocations"] satisfies (keyof VenueSchema)[]).some((someKey) => {
						return invalidFields.includes(someKey);
					});
				}
			})()
		},
		...extendedNavConfig
	];

	const [navConfig, setNavConfig] = useValueSelector({
		defaultValue: () => {
			return commonNavConfig;
		},
		vendorValue: () => {
			return commonNavConfig;
		},
		venueValue: () => {
			return venueNavConfig;
		}
	});

	useEffect(() => {
		if (openNav) {
			onCloseNav();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	useEffect(() => {
		fetchPhotos();
	}, [serviceCtx]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				"& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" }
			}}
		>
			<Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
				<Logo />
			</Box>

			<NavSection data={navConfig} />
		</Scrollbar>
	);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV_WIDTH }
			}}
		>
			{isDesktop ? (
				<Drawer
					open
					variant="permanent"
					PaperProps={{
						sx: {
							width: NAV_WIDTH,
							bgcolor: "background.default",
							borderRightStyle: "dashed",
							borderRightColor: "darkgray"
						}
					}}
				>
					{renderContent}
				</Drawer>
			) : (
				<Drawer
					open={openNav}
					onClose={onCloseNav}
					ModalProps={{
						keepMounted: true
					}}
					PaperProps={{
						sx: { width: "70vw" }
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</Box>
	);
}
