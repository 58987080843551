import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Nav from "./nav";
import { Button, CircularProgress, Divider, Grid, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import ServiceContext from "../../utils/serviceContext";
import { useValueSelector } from "../../utils/hooks";
import PortfolioContext from "../../utils/portfolioContext";
import useResponsive from "../../hooks/useResponsive";
import MenuIcon from "@mui/icons-material/Menu";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
	display: "flex",
	minHeight: "100%",
	overflow: "hidden"
});

const Main = styled("div")(({ theme }) => ({
	flexGrow: 1,
	overflow: "auto",
	minHeight: "100%",
	// paddingTop: APP_BAR_MOBILE + 24,
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.up("lg")]: {
		// paddingTop: APP_BAR_DESKTOP + 24,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	margin: theme.spacing(2)
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
	const [open, setOpen] = useState(false);

	const { serviceInfo, saveServiceInfo } = useContext(ServiceContext);

	const { portfolioData } = useContext(PortfolioContext);

	const { isPartialInfo, serviceData, serviceType } = serviceInfo;

	const { portfolioVideoCount, portfolioAlbumCount, portfolioPhotoCount } = portfolioData;

	const [requestActive, setRequestActive] = useState(false);

	const [progressValue, setProgressValue] = useValueSelector({
		defaultValue: () => {
			return 0;
		},
		venueValue: (venueData) => {
			const {
				venueName,
				venueLocations,
				venueDescription,
				venueCity,
				venueMetadata,
				venueBaseRate,
				venuePricingRate
			} = venueData;

			let completionPercentage = 0;

			if (isPartialInfo) {
				if (venueName.length > 0 && venueDescription.length > 0 && venueCity.length > 0) {
					completionPercentage += 100;
				}
				return completionPercentage;
			} else {
				if (venueName.length > 0 && venueDescription.length > 0 && venueCity.length > 0) {
					completionPercentage += 20;
				}
				if (venueLocations.length > 0) {
					completionPercentage += 20;
				}

				if (venueBaseRate > -1 || venuePricingRate.some((priceRate) => priceRate > 0)) {
					completionPercentage += 20;
				}

				if (portfolioPhotoCount > 0) {
					completionPercentage += 10;
				}

				if (portfolioAlbumCount > 0) {
					completionPercentage += 20;
				}

				if (portfolioVideoCount > 0) {
					completionPercentage += 10;
				}

				return completionPercentage;
			}
		},
		vendorValue: (vendorData) => {
			const {
				vendorCategory,
				vendorCity,
				vendorName,
				vendorDescription,
				vendorSubcategory,
				vendorMetadata,
				vendorPackages,
				vendorServiceRate,
				vendorServiceTypes
			} = vendorData;

			let completionPercentage = 0;

			if (isPartialInfo) {
				if (vendorCategory.length > 0 && vendorSubcategory.length > 0) {
					completionPercentage += 40;
				}

				if (vendorName.length > 0 && vendorDescription.length > 0 && vendorCity.length > 0) {
					completionPercentage += 60;
				}

				return completionPercentage;
			} else {
				if (vendorCategory.length > 0 && vendorSubcategory.length > 0) {
					completionPercentage += 20;
				}

				if (vendorName.length > 0 && vendorDescription.length > 0 && vendorCity.length > 0) {
					completionPercentage += 30;
				}

				if (vendorServiceRate > -2) {
					completionPercentage += 20;
				}

				if (portfolioPhotoCount > 0) {
					completionPercentage += 10;
				}

				if (portfolioAlbumCount > 0) {
					completionPercentage += 10;
				}

				if (portfolioVideoCount > 0) {
					completionPercentage += 10;
				}

				return completionPercentage;
			}
		}
	});
	const isDesktop = useResponsive("up", "lg");

	return (
		<>
		{!isDesktop && (
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={() => setOpen(!open)}
					sx={{ m: 1 }}
				>
					<MenuIcon />
				</IconButton>
			)}
			<StyledRoot>
				<Nav openNav={open} onCloseNav={() => setOpen(false)} />
				<Main>
					<Stack
						spacing={2}
						sx={{
							minHeight: "100%"
						}}
					>
						<Grid container sx={{ alignItems: "center" }} spacing={1}>
							{isPartialInfo ? null : (
								<Grid item xs={12} md={2}>
									<Button
										variant={"contained"}
										fullWidth
										color={"warning"}
										onClick={() => {
											window.location.reload();
										}}
									>
										Cancel Changes
									</Button>
								</Grid>
							)}
							<Grid item xs={12} md={isPartialInfo ? 9 : 7}>
								<LinearProgress variant={"determinate"} value={progressValue} />
							</Grid>
							<Grid item xs={12} md={1}>
								<Typography align={"center"}>{progressValue}%</Typography>
							</Grid>
							<Grid item xs={12} md={2}>
								<Button
									variant={"contained"}
									fullWidth
									disabled={requestActive}
									onClick={() => {
										setRequestActive(true);
										saveServiceInfo().then(() => {
											setRequestActive(false);
										}).catch(()=>{
											setRequestActive(false);
										});
									}}
								>
									{requestActive ? (
										<CircularProgress size={"1.5rem"} />
									) : isPartialInfo ? (
										"Create Listing"
									) : (
										"Save Listing"
									)}
								</Button>
							</Grid>
						</Grid>
						<Divider />
						<Outlet />
					</Stack>
				</Main>
			</StyledRoot>
		</>
	);
}
