import { useServiceRender, useValueSelector } from "../utils/hooks";
import { VenueLocationSchema } from "../utils/mongoSchema";
import {
	Box,
	Button,
	Divider,
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import Iconify from "../components/iconify";
import { useInvalidField } from "../utils/serviceContext";
import { RequiredText } from "../components/common";
import { Helmet } from "react-helmet-async";
import React from "react";

export default function LocationsPage() {
	const [locationData, setLocationData] = useValueSelector({
		defaultValue: () => {
			return [] satisfies VenueLocationSchema[];
		},
		venueValue: (venueData) => {
			return venueData.venueLocations;
		},
		vendorValue: (vendorData) => {
			return [];
		},
		updateVenueValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				venueLocations: partialValue
			};
		},
		updateVendorValue: (prevValue, partialValue) => {
			return prevValue;
		}
	});

	const isLocationsInvalid = useInvalidField({
		vendorField: false,
		venueField: "venueLocations"
	});
	// const isLocationsInvalid = useInvalidField({
	// 	vendorField: false,
	// 	venueField: "venueLocations"
	// });

	const pageContent = useServiceRender({
		fallbackElement: null,
		vendorElement: null,
		venueElement: (
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Stack
					direction={"row"}
					sx={{
						justifyContent: "space-between",
						width: "100%"
					}}
				>
					<Typography variant={"h4"}>Venue Types</Typography>
					<Button
						variant={"contained"}
						onClick={() => {
							setLocationData([
								...locationData,
								{
									locationType: "HALL",
									locationName: "",
									locationCapacitySeated: 0,
									locationCapacityFloating: 0,
									locationEnvironment: "INDOORS"
								}
							]);
						}}
					>
						<Iconify icon={"eva:plus-fill"} />
						Add Venue Type
					</Button>
				</Stack>
				<Typography>
					<ul>
						<li>A venue type is a banquet hall, wedding hall, or common area fit for gatherings</li>
						<li>
							Select the type of venue and enter the name, approximate seated and floating capacity for
							each
						</li>
					</ul>
				</Typography>
				<Divider sx={{ py: 0 }} />
				<Grid container spacing={1} sx={{ width: "100%" }}>
					{locationData.map((locationObj, locationIdx) => {
						const {
							locationType,
							locationCapacityFloating,
							locationCapacitySeated,
							locationName,
							locationEnvironment
						} = locationObj;

						const setCurrentLocationData = (locationDat: VenueLocationSchema) => {
							const beforeData = locationData.slice(0, locationIdx);
							const afterData = locationData.slice(locationIdx + 1);

							setLocationData([...beforeData, locationDat, ...afterData]);
						};

						return (
							<Grid item xs={12} md={6} sx={{ width: "100%" }}>
								<Box
									sx={{
										border: isLocationsInvalid ? "1px solid red" : "1px solid darkgrey",
										padding: 2,
										borderRadius: 2
									}}
								>
									<Grid container spacing={1} sx={{ width: "100%" }}>
										<Grid item xs={12}>
											<Typography variant={"h6"}>{`Venue #${locationIdx + 1}`}</Typography>
										</Grid>
										<Grid item xs={12}>
											<Divider sx={{ py: 0 }} />
										</Grid>
										<Grid item xs={12} md={6} sx={{ width: "100%" }}>
											<>
												<RequiredText>Venue Name</RequiredText>
												<TextField
													fullWidth
													value={locationName}
													placeholder={"Venue Name"}
													onChange={(e) => {
														setCurrentLocationData({
															...locationObj,
															locationName: e.target.value
														});
													}}
												/>
											</>
										</Grid>
										<Grid item xs={12} md={6}>
											<>
												<Typography>Venue Type</Typography>
												<Select
													fullWidth
													value={locationType}
													onChange={(e) => {
														setCurrentLocationData({
															...locationObj,
															// @ts-ignore
															locationType: e.target.value
														});
													}}
												>
													{/* <MenuItem value={"HALL"}>Hall</MenuItem>
														<MenuItem value={"LAWN"}>Hotel</MenuItem> */}
													<MenuItem value={"LAWN"}>Lawn</MenuItem>
													<MenuItem value={"BANQUET"}>Banquet</MenuItem>
													<MenuItem value={"RESORT"}>Resort</MenuItem>
													<MenuItem value={"SMALL_FUNCTION_LOCATION"}>
														Small Function / Party Halls
													</MenuItem>
													{/* <MenuItem value={"KALYANA_MANDAPAM"}>Kalyana Mandapam</MenuItem> */}
													<MenuItem value={"EXOTIC_HOMESTAY"}>Exotic Homestay</MenuItem>
													<MenuItem value={"4_STAR_PLUS_HOTEL"}>4-Star+ Hotel</MenuItem>
													<MenuItem value={"DESTINATION_WEDDING"}>
														Destination Wedding
													</MenuItem>
												</Select>
											</>
										</Grid>
										<Grid item xs={12} md={6}>
											<>
												<RequiredText>Venue Capacity (Seated)</RequiredText>
												<TextField
													fullWidth
													value={locationCapacitySeated}
													placeholder={"Venue Capacity (Seated)"}
													onChange={(e) => {
														const parsedValue = Number.parseInt(e.target.value);

														if (Number.isNaN(parsedValue)) return;

														setCurrentLocationData({
															...locationObj,
															locationCapacitySeated: parsedValue
														});
													}}
												/>
											</>
										</Grid>
										<Grid item xs={12} md={6}>
											<>
												<RequiredText>Venue Capacity (Floating)</RequiredText>
												<TextField
													fullWidth
													value={locationCapacityFloating}
													placeholder={"Venue Capacity (Floating)"}
													onChange={(e) => {
														const parsedValue = Number.parseInt(e.target.value);

														if (Number.isNaN(parsedValue)) return;

														setCurrentLocationData({
															...locationObj,
															locationCapacityFloating: parsedValue
														});
													}}
												/>
											</>
										</Grid>
										<Grid item xs={12}>
											<>
												<RequiredText>Venue Environment</RequiredText>
												<RadioGroup
													row
													value={locationEnvironment}
													onChange={(e) => {
														setCurrentLocationData({
															...locationObj,
															locationEnvironment: e.target.value as
																| "INDOORS"
																| "OUTDOORS"
														});
													}}
												>
													<FormControlLabel
														control={<Radio />}
														label={"Indoor Venue "}
														value={"INDOORS"}
													/>
													<FormControlLabel
														control={<Radio />}
														label={"Outdoor Venue "}
														value={"OUTDOORS"}
													/>
												</RadioGroup>
											</>
										</Grid>
										<Grid item xs={12}>
											<Button
												variant={"contained"}
												color={"error"}
												fullWidth
												onClick={() => {
													const prevLocations = locationData.slice(0, locationIdx);
													const nextLocations = locationData.slice(locationIdx + 1);

													setLocationData([...prevLocations, ...nextLocations]);
												}}
											>
												Delete Venue Type
											</Button>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</Stack>
		)
	});

	return (
		<>
			<Helmet>
				<title> Locations | Mangal Weddings Dashboard </title>
			</Helmet>
			{pageContent}
		</>
	);
}
