import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Snackbar,
	TextField
} from "@mui/material";
import React, { useState } from "react";
import { RequiredText } from "../../../components/common";
import Iconify from "../../../components/iconify";
import { makeAPIRequest } from "../../../utils/apiHandler";
import { APIResponseData, ResetPasswordResponse } from "../../../utils/apiResponses";
import { AuthForgotPasswordBody, AuthResetPasswordBody, NoParams } from "../../../utils/apiRequests";
import { LoadingButton } from "@mui/lab";
import { useResolvedPath } from "react-router-dom";

type ForgotPasswordModalProps = {
	isModalOpen: boolean,
	setIsModalOpen: (openState: boolean) => void
}

enum TOTPStage {
	RequiresUserMail = 0,
	RequiresUserTOTP = 1,
	RequiresUserPassword = 2
}

export default function ForgotPasswordModal(props: ForgotPasswordModalProps) {
	const { isModalOpen, setIsModalOpen } = props;

	const [progressStage, setProgressStage] = useState<TOTPStage>(TOTPStage.RequiresUserMail);

	const [userMail, setUserMail] = useState("");
	const [userData, setUserData] = useState({ userTOTP: "", userId: "" });
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [anyRequestActive, setAnyRequestActive] = useState(false);

	const [alertData, setAlertData] = useState<{
		showAlert: boolean,
		alertText: string,
		alertType: "error" | "success"
	}>({
		showAlert: false,
		alertText: "",
		alertType: "error"
	});

	const onClose = () => {
		setIsModalOpen(false);
		setProgressStage(TOTPStage.RequiresUserMail);
		setUserMail("");
		setUserData({
			userId: "",
			userTOTP: ""
		});
		setNewPassword("");
		setConfirmPassword("");
	};

	const onNextStageClick = async () => {
		if (progressStage === 0) {
			if (userMail.trim().length === 0) {
				setAlertData({
					showAlert: true,
					alertText: "Please enter a valid Email!",
					alertType: "error"
				});
				return;
			}

			setAnyRequestActive(true);

			const {
				isSuccess,
				isError,
				requestError,
				responseCode,
				responseData
			} = await makeAPIRequest<ResetPasswordResponse, NoParams, AuthForgotPasswordBody>({
				requestEndpoint: "/auth/forgot-password",
				requestMethod: "POST",
				bodyParams: {
					userMail: userMail
				}
			});

			setAnyRequestActive(false);

			if (isSuccess) {
				if (responseData.responseStatus === "SUCCESS") {
					setAlertData({
						showAlert: true,
						alertText: "OTP Sent Successfully",
						alertType: "success"
					});
					const { userID } = responseData;
					setUserData({
						userTOTP: "",
						userId: userID
					});
					setProgressStage(TOTPStage.RequiresUserTOTP);
					return;
				} else if ((
					(responseData.invalidParams || []) as (keyof AuthForgotPasswordBody)[]
				).includes("userMail")) {
					setAlertData({
						showAlert: true,
						alertText: "Invalid Email Entered!",
						alertType: "error"
					});
					return;
				} else {
					setAlertData({
						showAlert: true,
						alertText: "An error occurred when processing your request!",
						alertType: "error"
					});
					return;
				}
			} else {
				setAlertData({
					showAlert: true,
					alertText: "An error occurred when processing your request!",
					alertType: "error"
				});
				return;
			}
		} else if (progressStage === 1) {
			const trimmedTOTP = userData.userTOTP.trim();
			if (trimmedTOTP.length !== 6) {
				setAlertData({
					showAlert: true,
					alertText: "Invalid OTP Entered!",
					alertType: "error"
				});
				return;
			}

			const parsedTOTP = Number.parseInt(trimmedTOTP);

			if (parsedTOTP >= 1000000 || parsedTOTP <= -1) {
				setAlertData({
					showAlert: true,
					alertText: "Invalid OTP Entered",
					alertType: "error"
				});
				return;
			}

			setProgressStage(TOTPStage.RequiresUserPassword);
			return;
		} else {
			const trimmedPassword = newPassword.trim();
			const trimmedConfirm = confirmPassword.trim();
			if (trimmedPassword !== trimmedConfirm) {
				setAlertData({
					showAlert: true,
					alertText: "Passwords do not match!",
					alertType: "error"
				});
				return;
			}

			if (trimmedPassword.length < 8) {
				setAlertData({
					showAlert: true,
					alertText: "Input a strong password!",
					alertType: "error"
				});
				return;
			}

			setAnyRequestActive(true);

			const {
				isSuccess,
				requestError,
				isError,
				responseData,
				responseCode
			} = await makeAPIRequest<APIResponseData, NoParams, AuthResetPasswordBody>({
				requestEndpoint: "/auth/reset-password",
				requestMethod: "POST",
				bodyParams: {
					userId: userData.userId,
					userPassword: trimmedPassword,
					resetToken: userData.userTOTP
				}
			});

			setAnyRequestActive(false);

			if (isSuccess) {
				if (responseData.responseStatus === "SUCCESS") {
					setAlertData({
						showAlert: true,
						alertText: "Password Reset Successfully",
						alertType: "success"
					});
					setTimeout(() => {
						onClose();
					}, 5000);
					return;
				} else if ((
					(responseData.invalidParams || []) as (keyof AuthResetPasswordBody)[]
				).includes("resetToken")) {
					setAlertData({
						showAlert: true,
						alertText: "The OTP you entered has expired!",
						alertType: "error"
					});
					return;
				} else if ((
					(responseData.invalidParams || []) as (keyof AuthResetPasswordBody)[]
				).includes("userPassword")) {
					setAlertData({
						showAlert: true,
						alertText: "Input a strong password!!",
						alertType: "error"
					});
					return;
				} else {
					setAlertData({
						showAlert: true,
						alertText: "An error occurred when processing your request!",
						alertType: "error"
					});
					return;
				}
			} else {
				setAlertData({
					showAlert: true,
					alertText: "An error occurred when processing your request!",
					alertType: "error"
				});
				return;
			}
		}
	};

	return (
		<>
			<Dialog open={isModalOpen} onClose={onClose}>
				<DialogTitle>Reset your Password</DialogTitle>
				<DialogContent>
					<Grid container sx={{ alignItems: "center" }} spacing={1}>
						<Grid item xs={12}>
							<Divider sx={{ py: 0 }} />
						</Grid>
						<Grid item xs={12}>
							<RequiredText>Email Address</RequiredText>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								placeholder={"Email Address"}
								name={"email"}
								type={"text"}
								value={userMail}
								onChange={(e) => {
									setUserMail(e.target.value);
								}}
								disabled={progressStage !== TOTPStage.RequiresUserMail}
							/>
						</Grid>
						{
							progressStage !== TOTPStage.RequiresUserMail ? (
								<>
									<Grid item xs={12}>
										<RequiredText>Enter OTP</RequiredText>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											placeholder={"OTP"}
											value={userData.userTOTP}
											onChange={(e) => {
												setUserData((prevState) => {
													return {
														...prevState,
														userTOTP: e.target.value
													};
												});
											}}
											disabled={progressStage !== TOTPStage.RequiresUserTOTP}
										/>
									</Grid>
									{
										progressStage === TOTPStage.RequiresUserPassword ? (
											<>
												<Grid item xs={12}>
													<RequiredText>New Password</RequiredText>
												</Grid>
												<Grid item xs={12}>
													<TextField
														fullWidth
														type={showPassword ? "text" : "password"}
														placeholder={"New Password"}
														value={newPassword}
														onChange={(e) => {
															setNewPassword(e.target.value);
														}}
														disabled={anyRequestActive}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		onClick={() => setShowPassword(!showPassword)}
																		edge="end">
																		{/* @ts-ignore*/}
																		<Iconify
																			icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
																	</IconButton>
																</InputAdornment>
															)
														}}
													/>
												</Grid>
												<Grid item xs={12}>
													<RequiredText>Confirm Password</RequiredText>
												</Grid>
												<Grid item xs={12}>
													<TextField
														fullWidth
														type={"password"}
														placeholder={"Confirm Password"}
														value={confirmPassword}
														onChange={(e) => {
															setConfirmPassword(e.target.value);
														}}
														disabled={anyRequestActive}
													/>
												</Grid>
											</>
										) : (
											null
										)
									}
								</>
							) : (
								null
							)
						}
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						justifyContent: "space-between",
						px: 4
					}}
				>
					<Button variant={"contained"} onClick={onClose} color={"error"}>
						<Iconify icon={"eva:close-fill"} />
						Cancel
					</Button>
					<LoadingButton
						loading={anyRequestActive}
						variant={"contained"}
						sx={{
							my: 2
						}}
						onClick={onNextStageClick}
						disabled={
							(progressStage === TOTPStage.RequiresUserMail ? userMail.length === 0 : false)
							||
							(progressStage === TOTPStage.RequiresUserTOTP ? userData.userTOTP.length !== 6 : false)
							||
							((progressStage === TOTPStage.RequiresUserPassword) ? (
								newPassword.length < 8 || confirmPassword !== newPassword
							) : false)
						}
					>
						Next
						<Iconify icon={"eva:arrow-forward-fill"} />
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				autoHideDuration={10000}
				open={alertData.showAlert}
				onClose={() => {
					setAlertData((prevState) => {
						return {
							...prevState,
							showAlert: false
						};
					});
				}}
			>
				<Alert
					severity={alertData.alertType}
					variant={"filled"}
				>
					{alertData.alertText}
				</Alert>
			</Snackbar>
		</>
	);
};
