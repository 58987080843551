import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
//
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import Page404 from "./pages/Page404";
import ProfilePage from "./pages/ProfilePage";
import FeaturesPage from "./pages/FeaturesPage";
import PricingPage from "./pages/PricingPage";
import PortfolioPhotos from "./pages/PortfolioPhotosPage";
import PortfolioAlbums from "./pages/PortfolioAlbumsPage";
import PortfolioVideos from "./pages/PortfolioVideosPage";
import ChatPage from "./pages/ChatPage";
import DashboardPage from "./pages/DashboardPage";
import { useServiceRender } from "./utils/hooks";
import LocationsPage from "./pages/LocationsPage";

// ----------------------------------------------------------------------

export default function Router() {
	const VenueLocationsPage = useServiceRender({
		venueElement: <LocationsPage />,
		fallbackElement: null
	});

	const routes = useRoutes([
		{
			path: "/",
			element: <Navigate to="/dashboard" />
		},
		{
			path: "/dashboard",
			element: <DashboardLayout />,
			children: [
				{
					path: "",
					element: <DashboardPage />
				},
				{
					path: "profile",
					element: <ProfilePage />
				},
				{
					path: "pricing",
					element: <PricingPage />
				},
				{
					path: "features",
					element: <FeaturesPage />
				},
				{
					path: "chat",
					element: <ChatPage />
				},
				{
					path: "locations",
					element: VenueLocationsPage
				},
				{
					path: "portfolio",
					children: [
						{
							path: "",
							element: <Navigate to={"photos"} />
						},
						{
							path: "photos",
							element: <PortfolioPhotos />
						},
						{
							path: "albums",
							element: <PortfolioAlbums />
						},
						{
							path: "videos",
							element: <PortfolioVideos />
						}
					]
				}
			]
		},
		{
			path: "login",
			element: <LoginPage />
		},
		{
			path: "signup",
			element: <SignupPage />
		},
		{
			path: "404",
			element: <Page404 />
		},
		{
			path: "*",
			element: <Navigate to="/404" replace />
		}
	]);

	return routes;
}
