import { VendorSchema, VenueSchema } from "./mongoSchema";
import { ReactNode, useContext, useMemo, useState } from "react";
import ServiceContext from "./serviceContext";

type ValueSelectorArgs<T> = {
	venueValue?: (venueData: VenueSchema) => T,
	vendorValue?: (vendorData: VendorSchema) => T,
	defaultValue: () => T,
	updateVendorValue?: (prevValue: VendorSchema, partialValue: T) => VendorSchema,
	updateVenueValue?: (prevValue: VenueSchema, partialValue: T) => VenueSchema,
}

// TODO: Remove null returns after demo

export function useValueSelector<T>(args: ValueSelectorArgs<T>): [T, (newValue: T) => void] {
	const { venueValue, defaultValue, vendorValue, updateVenueValue, updateVendorValue } = args;

	const serviceContext = useContext(ServiceContext);
	const { serviceInfo, setServiceInfo } = serviceContext;
	const { serviceType, serviceData, isPartialInfo, invalidFields } = serviceInfo;
	const defaultUpdater = (newValue: T) => {
		console.warn("Contextual value was updated without a proper Venue or Vendor context");
		console.warn(`Updated value: ${JSON.stringify(newValue, null, 4)}`);
	};

	const venueUpdater = (newValue: T) => {
		if (updateVenueValue && serviceType === "VENUE") {
			const updatedData = updateVenueValue(
				serviceData,
				newValue
			);
			setServiceInfo({
				serviceType: "VENUE",
				serviceData: updatedData,
				isPartialInfo: isPartialInfo,
				invalidFields: invalidFields
			});
		}
	};

	const vendorUpdater = (newValue: T) => {
		if (updateVendorValue && serviceType === "VENDOR") {
			const updatedData = updateVendorValue(
				serviceData,
				newValue
			);
			setServiceInfo({
				serviceType: "VENDOR",
				serviceData: updatedData,
				isPartialInfo: isPartialInfo,
				invalidFields: invalidFields
			});
		}
	};

	if (serviceType === null) {
		return [
			defaultValue(),
			defaultUpdater
		];
	} else if (serviceType === "VENDOR") {
		if (vendorValue) {
			return [
				vendorValue(serviceData),
				vendorUpdater
			];
		} else {
			return [
				defaultValue(),
				defaultUpdater
			];
		}
	} else {
		if (venueValue) {
			return [
				venueValue(serviceData),
				venueUpdater
			];
		} else {
			return [
				defaultValue(),
				defaultUpdater
			];
		}
	}
}

type ServiceRenderArgs = {
	venueElement?: ReactNode
	vendorElement?: ReactNode,
	fallbackElement?: ReactNode
}

export function useServiceRender(args: ServiceRenderArgs) {
	const { vendorElement = null, fallbackElement = null, venueElement = null } = args;
	const serviceCtx = useContext(ServiceContext);
	const { serviceInfo } = serviceCtx;
	const { serviceType } = serviceInfo;
	if (serviceType === null) {
		return fallbackElement;
	}
	if (serviceType === "VENDOR") {
		return vendorElement ?? fallbackElement;
	}
	return venueElement ?? fallbackElement;
}


type UseInvalidFieldArgs<T extends {} = {}> = {
	fieldMapping: Record<keyof T, string>,
	defaultValue?: Partial<Record<keyof T, boolean>>,
	fieldTextGenFn?: (invalidFields: (keyof T)[], fieldMapping: Record<keyof T, string>) => string,
	removeDuplicates?: boolean
}

type UseInvalidFieldReturn<T> = {
	invalidFields: Record<keyof T, boolean>,
	setInvalidFields: (invalidFieldNames: (keyof T)[] | Partial<Record<keyof T, boolean>>) => void
	fieldText: string | null,
}

export function useInvalidFields<T extends {} = {}>(args: UseInvalidFieldArgs<T>): UseInvalidFieldReturn<T> {
	const { fieldMapping, fieldTextGenFn, defaultValue, removeDuplicates } = args;

	const initialMappedKeys = useMemo(() => {
		const initialEntries = Object.entries(fieldMapping) as [keyof T, T[keyof T]][];

		const mappedEntries = initialEntries.map((mappingKey) => {
			const [elemKey, _] = mappingKey;
			return ([
				elemKey, false
			] satisfies [keyof T, boolean]) as [keyof T, boolean];
		});

		const restructuredEntries = Object.fromEntries(mappedEntries);

		return {
			...restructuredEntries,
			...(defaultValue || {})
		};
	}, [defaultValue, fieldMapping]) as Record<keyof T, boolean>;

	const [invalidFieldData, setInvalidFieldData] = useState(initialMappedKeys);

	const invalidFieldSetter: UseInvalidFieldReturn<T>["setInvalidFields"] = (setterArg) => {
		if (Array.isArray(setterArg)) {
			const mappedRecords = setterArg.map((fieldName) => {
				return [fieldName, true] as [keyof T, boolean];
			});

			const objectRecords = Object.fromEntries(mappedRecords) as Partial<Record<keyof T, boolean>>;

			// Assume other props as valid
			const initEntries = Object.entries(fieldMapping) as [keyof T, T[keyof T]][];
			const allValidEntries = initEntries.map((entryPair) => {
				const [key, _] = entryPair;
				return [
					key,
					false
				] as [keyof T, boolean];
			});

			const allValidObj = Object.fromEntries(allValidEntries) as Record<keyof T, boolean>;

			const validInvalidMerged = {
				...allValidObj,
				...objectRecords
			};

			setInvalidFieldData({
				...initialMappedKeys,
				...validInvalidMerged
			});
		} else {
			setInvalidFieldData({
				...initialMappedKeys,
				...setterArg
			});
		}
	};

	const invalidValues = Object.values(invalidFieldData) as boolean[];

	const anyIsInvalid = invalidValues.some((isInvalidValue) => {
		return isInvalidValue;
	});

	if (!anyIsInvalid) {
		return {
			fieldText: null,
			setInvalidFields: invalidFieldSetter,
			invalidFields: invalidFieldData
		};
	}

	const dataKeys = Object.keys(invalidFieldData);

	const invalidKeys = dataKeys.filter((keyName) => {
		return invalidFieldData[keyName as keyof T] === true;
	});

	const formattedFields = removeDuplicates ? [...new Set(invalidKeys)] : invalidKeys;

	if (fieldTextGenFn) {
		const invalidFieldText = fieldTextGenFn(formattedFields as (keyof T)[], fieldMapping);
		return {
			fieldText: invalidFieldText,
			setInvalidFields: invalidFieldSetter,
			invalidFields: invalidFieldData
		};
	}

	const mappedStrings = formattedFields.map((fieldName) => {
		const mappedString: string = fieldMapping[fieldName as keyof T];
		return mappedString;
	});

	return {
		fieldText: mappedStrings.join(", "),
		setInvalidFields: invalidFieldSetter,
		invalidFields: invalidFieldData
	};
}