import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";
import { selectMetadataValue, zipMetadata } from "../../utils/schemaMetadata";
import { useValueSelector } from "../../utils/hooks";
import { MetadataName, MetadataObject, MetadataType, MetadataValue, VenueMetadataName } from "../../utils/mongoSchema";
import SocialQuestions from "./SocialQuestions";

const VENUE_METADATA_NAME_MAP: Record<VenueMetadataName, string> = {
	VENUE_PARKING_AVAILABLE: "Is sufficient parking available?",
	VENUE_DJ_INTERNAL: "What is your policy for DJs?",
	VENUE_CATERING_INTERNAL: "What is your policy for Catering?",
	VENUE_DECOR_INTERNAL: "What is your policy for Decor?",
	VENUE_ALCOHOL_INTERNAL: "What is your policy for Alcohol?",
	VENUE_ROOM_COUNT: "How many rooms are available at the location?",
	VENUE_PREMIUM_DATE: "Listing Active Since",
	VENUE_LISTING_DATE: "Listed on",
	VENUE_BASE_ROOM_COST: "What is the cost for a basic room",
	VENUE_BOOKING_PAYMENT_PERCENTAGE: "What (%) Advance Payment do you take",
	VENUE_WHEELCHAIR_ACCESSIBLE: "Is the location accessible by wheelchair?",
	VENUE_DATE_ADJUSTMENT: "Do you adjust dates?",
	VENUE_DECOR_COST: "What is the cost for basic decor?",
	// VENUE_PARTIAL_REFUND: "Do you offer partial or full refunds?",
	VENUE_PRIMARY_TYPE: "What is the main location of your venue?",
	VENUE_REFUND_OFFERED: "Do you offer refunds?",
	VENUE_SMALL_SIZE_GATHERINGS: "Is the venue suitable for small gatherings?",
	// Handled by <SocialLink> component
	VENUE_SOCIAL_FB_LINK: "",
	VENUE_SOCIAL_IG_LINK: "",
	VENUE_SOCIAL_WS_LINK: "",
	VENUE_SOCIAL_YT_LINK: ""
};

export default function VenueQuestionnaire() {
	const [metadataValues, setMetadataValues] = useValueSelector<MetadataObject[]>({
		defaultValue: () => {
			return [] as MetadataObject[];
		},
		vendorValue: (vendorData) => {
			return vendorData.vendorMetadata;
		},
		venueValue: (venueData) => {
			return venueData.venueMetadata;
		},
		updateVenueValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				venueMetadata: zipMetadata(prevValue.venueMetadata, partialValue)
			};
		},
		updateVendorValue: (prevValue, partialValue) => {
			return {
				...prevValue,
				vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue)
			};
		}
	});

	const toBoolean = (unBooled: string) => {
		const slicedString = unBooled.slice(2, -2);
		return slicedString === "true";
	};

	const unBoolean = (boolVal: true | false) => {
		return `##${boolVal}##`;
	};

	const generateMetadataSetter = (metadataName: MetadataName, metadataType: MetadataType) => {
		return (metadataValue: MetadataValue[typeof metadataType]) => {
			const metadataObj: MetadataObject = {
				propertyName: metadataName,
				propertyType: metadataType
			} as MetadataObject;
			switch (metadataType) {
				case "STRING":
					metadataObj.propertyValue = metadataValue.toString();
					break;
				case "BOOLEAN":
					metadataObj.propertyValue = Boolean(metadataValue);
					break;
				case "NUMBER":
					// @ts-ignore
					const parsedValue = Number.parseInt(metadataValue);
					if (Number.isNaN(parsedValue)) {
						metadataObj.propertyValue = 0;
					} else {
						metadataObj.propertyValue = parsedValue;
					}
					break;
				default:
					metadataObj.propertyValue = metadataValue;
			}
			setMetadataValues(zipMetadata(metadataValues, [metadataObj]));
		};
	};

	const VENUE_PARKING_AVAILABLE = selectMetadataValue(metadataValues, "VENUE_PARKING_AVAILABLE", false) as boolean;
	const VENUE_DJ_INTERNAL = selectMetadataValue(metadataValues, "VENUE_DJ_INTERNAL", true) as boolean;
	const VENUE_CATERING_INTERNAL = selectMetadataValue(metadataValues, "VENUE_CATERING_INTERNAL", true) as boolean;
	const VENUE_ALCOHOL_INTERNAL = selectMetadataValue(metadataValues, "VENUE_ALCOHOL_INTERNAL", true) as boolean;
	const VENUE_DECOR_INTERNAL = selectMetadataValue(metadataValues, "VENUE_DECOR_INTERNAL", true) as boolean;
	// const VENUE_PARTIAL_REFUND = selectMetadataValue(metadataValues, "VENUE_PARTIAL_REFUND", false) as boolean;
	const VENUE_REFUND_OFFERED = selectMetadataValue(metadataValues, "VENUE_REFUND_OFFERED", false) as boolean;
	const VENUE_SMALL_SIZE_GATHERINGS = selectMetadataValue(
		metadataValues,
		"VENUE_SMALL_SIZE_GATHERINGS",
		false
	) as boolean;
	const VENUE_PRIMARY_TYPE = selectMetadataValue(metadataValues, "VENUE_PRIMARY_TYPE", false) as boolean;
	const VENUE_DATE_ADJUSTMENT = selectMetadataValue(metadataValues, "VENUE_DATE_ADJUSTMENT", false) as boolean;
	const VENUE_WHEELCHAIR_ACCESSIBLE = selectMetadataValue(
		metadataValues,
		"VENUE_WHEELCHAIR_ACCESSIBLE",
		false
	) as boolean;

	const VENUE_ROOM_COUNT = selectMetadataValue(metadataValues, "VENUE_ROOM_COUNT", 0) as number;
	const VENUE_BASE_ROOM_COST = selectMetadataValue(metadataValues, "VENUE_BASE_ROOM_COST", 0) as number;
	const VENUE_BOOKING_PAYMENT_PERCENTAGE = selectMetadataValue(
		metadataValues,
		"VENUE_BOOKING_PAYMENT_PERCENTAGE",
		0
	) as number;
	const VENUE_DECOR_COST = selectMetadataValue(metadataValues, "VENUE_DECOR_COST", 0) as number;

	const dateValue = (inputVal: string | Date) => {
		const parsedInputDate = new Date(inputVal);
		const dateElements = [
			parsedInputDate.getFullYear(),
			parsedInputDate.getMonth().toString().padStart(2, "0"),
			parsedInputDate.getDay().toString().padStart(2, "0")
		];
		return dateElements.join("-");
	};

	return (
		<>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_ROOM_COUNT"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					defaultValue={VENUE_ROOM_COUNT}
					type={"number"}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_ROOM_COUNT", "NUMBER");
						metadataFn(e.target.value);
					}}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_BASE_ROOM_COST"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					defaultValue={VENUE_BASE_ROOM_COST}
					type={"number"}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_BASE_ROOM_COST", "NUMBER");
						metadataFn(e.target.value);
					}}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_BOOKING_PAYMENT_PERCENTAGE"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					defaultValue={VENUE_BOOKING_PAYMENT_PERCENTAGE}
					type={"number"}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_BOOKING_PAYMENT_PERCENTAGE", "NUMBER");
						metadataFn(e.target.value);
					}}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_DECOR_COST"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<TextField
					defaultValue={VENUE_DECOR_COST}
					type={"number"}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_DECOR_COST", "NUMBER");
						metadataFn(e.target.value);
					}}
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_REFUND_OFFERED"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_REFUND_OFFERED)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_REFUND_OFFERED", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>We offer Partial or Full Refunds</MenuItem>
					<MenuItem value={unBoolean(false)}>We do not offer refunds</MenuItem>
				</Select>
			</Grid>
			{/* {VENUE_REFUND_OFFERED ? (
				<> */}
					{/* <Grid item xs={12} md={4}>
						<Typography>
							<b>{VENUE_METADATA_NAME_MAP["VENUE_PARTIAL_REFUND"]}</b>
						</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Select
							defaultValue={unBoolean(VENUE_PARTIAL_REFUND)}
							fullWidth
							onChange={(e) => {
								const metadataFn = generateMetadataSetter("VENUE_PARTIAL_REFUND", "BOOLEAN");
								metadataFn(toBoolean(e.target.value as string));
							}}
						>
							<MenuItem value={unBoolean(true)}>We offer Partial Refund</MenuItem>
							<MenuItem value={unBoolean(false)}>We offer Full Refund</MenuItem>
						</Select>
					</Grid> */}
				{/* </>
			) : (
				<> */}
					<Grid item xs={12} md={4}>
						<Typography>
							<b>{VENUE_METADATA_NAME_MAP["VENUE_DATE_ADJUSTMENT"]}</b>
						</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Select
							defaultValue={unBoolean(VENUE_DATE_ADJUSTMENT)}
							fullWidth
							onChange={(e) => {
								const metadataFn = generateMetadataSetter("VENUE_DATE_ADJUSTMENT", "BOOLEAN");
								metadataFn(toBoolean(e.target.value as string));
							}}
						>
							<MenuItem value={unBoolean(true)}>We adjust dates</MenuItem>
							<MenuItem value={unBoolean(false)}>We do not adjust dates</MenuItem>
						</Select>
					</Grid>
				{/* </> 
			 )} */}
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_SMALL_SIZE_GATHERINGS"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_SMALL_SIZE_GATHERINGS)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_SMALL_SIZE_GATHERINGS", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>We host small gatherings</MenuItem>
					<MenuItem value={unBoolean(false)}>We do not host small gatherings</MenuItem>
				</Select>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_WHEELCHAIR_ACCESSIBLE"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_WHEELCHAIR_ACCESSIBLE)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_WHEELCHAIR_ACCESSIBLE", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>The venue is accessible by wheelchair</MenuItem>
					<MenuItem value={unBoolean(false)}>The venue is not accessible by wheelchair</MenuItem>
				</Select>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_PRIMARY_TYPE"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={VENUE_PRIMARY_TYPE}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_PRIMARY_TYPE", "STRING");
						metadataFn(e.target.value as string);
					}}
				>
					<MenuItem value={"LAWN"}>Lawn</MenuItem>
					<MenuItem value={"BANQUET"}>Banquet</MenuItem>
					<MenuItem value={"RESORT"}>Resort</MenuItem>
					<MenuItem value={"SMALL_FUNCTION_LOCATION"}>Small Function Location</MenuItem>
					{/* <MenuItem value={"KALYANA_MANDAPAM"}>Kalyana Mandapam</MenuItem> */}
					<MenuItem value={"EXOTIC_HOMESTAY"}>Exotic Homestay</MenuItem>
					<MenuItem value={"4_STAR_PLUS_HOTEL"}>4-Star+ Hotel</MenuItem>
					<MenuItem value={"DESTINATION_WEDDING"}>Destination Wedding</MenuItem>
				</Select>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_ALCOHOL_INTERNAL"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_ALCOHOL_INTERNAL)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_ALCOHOL_INTERNAL", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>Internal Only</MenuItem>
					<MenuItem value={unBoolean(false)}>External Allowed</MenuItem>
				</Select>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_CATERING_INTERNAL"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_CATERING_INTERNAL)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_CATERING_INTERNAL", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>Internal Only</MenuItem>
					<MenuItem value={unBoolean(false)}>External Allowed</MenuItem>
				</Select>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_DECOR_INTERNAL"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_DECOR_INTERNAL)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_DECOR_INTERNAL", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>Internal Only</MenuItem>
					<MenuItem value={unBoolean(false)}>External Allowed</MenuItem>
				</Select>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_DJ_INTERNAL"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_DJ_INTERNAL)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_DJ_INTERNAL", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>Internal Only</MenuItem>
					<MenuItem value={unBoolean(false)}>External Allowed</MenuItem>
				</Select>
			</Grid>
			<Grid item xs={12} md={4}>
				<Typography>
					<b>{VENUE_METADATA_NAME_MAP["VENUE_PARKING_AVAILABLE"]}</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Select
					defaultValue={unBoolean(VENUE_PARKING_AVAILABLE)}
					fullWidth
					onChange={(e) => {
						const metadataFn = generateMetadataSetter("VENUE_PARKING_AVAILABLE", "BOOLEAN");
						metadataFn(toBoolean(e.target.value as string));
					}}
				>
					<MenuItem value={unBoolean(true)}>Yes, Sufficient Parking is Available</MenuItem>
					<MenuItem value={unBoolean(false)}>No, We may not be able to provide sufficient parking</MenuItem>
				</Select>
			</Grid>
			<SocialQuestions />
		</>
	);
}
