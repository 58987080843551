import { useValueSelector } from "../../utils/hooks";
import { Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";

import ServiceQuestions from "../features/service-questions.json";
import { useContext, useEffect, useState } from "react";
import ServiceContext from "../../utils/serviceContext";
import { VendorPackage } from "../../utils/mongoSchema";

export function ServiceTypes() {
	const { serviceInfo } = useContext(ServiceContext);

	const [questionOptions, setQuestionOptions] = useState<string[]>([]);

	const [serviceTypesAndPackages, setServiceTypesAndPackages] = useValueSelector({
		defaultValue: () => {
			return {
				serviceTypes: [] as string[],
				servicePackages: [] as VendorPackage[]
			} ;
		},
		venueValue: () => {
			return {
				serviceTypes: [] as string[],
				servicePackages: [] as VendorPackage[]
			} ;
		},
		vendorValue: (vendorData) => {
			return {
				serviceTypes: vendorData.vendorServiceTypes,
				servicePackages: vendorData.vendorPackages
			} ;
		},
		updateVenueValue: (prevValue, partialValue) => {
			return prevValue;
		},
		updateVendorValue: (prevValue, partialValue) => {
			const {serviceTypes, servicePackages} = partialValue

			return {
				...prevValue,
				vendorServiceTypes: serviceTypes,
				vendorPackages: servicePackages
			};
		}
	});

	const {serviceTypes, servicePackages} = serviceTypesAndPackages

	const addServiceType = (serviceType: string) => {
		setServiceTypesAndPackages({
			servicePackages: [
				...servicePackages,
				{
					packageName: serviceType,
					packagePrice: 0
				}
			],
			serviceTypes: [
				...serviceTypes,
				serviceType
			]
		})
	}

	const deleteServiceType = (serviceType: string) => {
		const filteredPackages = servicePackages.filter((eachPackage) => {
			return eachPackage.packageName !== serviceType
		})

		const filteredTypes = serviceTypes.filter((eachType) => {
			return eachType !== serviceType
		})

		setServiceTypesAndPackages({
			servicePackages: filteredPackages,
			serviceTypes: filteredTypes
		})
	}

	useEffect(() => {
		const { serviceType, serviceData } = serviceInfo;

		if (serviceType !== "VENDOR") {
			return;
		}

		const filteredQuestions = ServiceQuestions.filter((questionObj) => {
			return questionObj.vendorCategory === serviceData.vendorCategory
				&&
				questionObj.vendorSubcategory === serviceData.vendorSubcategory;
		});

		if (filteredQuestions.length > 0) {
			const selectedTypes = filteredQuestions[0].serviceTypes || [];
			setQuestionOptions(selectedTypes);
		}
	}, [serviceInfo]);

	if (questionOptions.length === 0) {
		return null;
	}

	return (
		<>
			<Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
				<Typography>
					<b>
						Which of the following services do you provide?
					</b>
				</Typography>
			</Grid>
			<Grid item xs={12} md={8}>
				<Grid container spacing={1}>
					{
						questionOptions.map((optionValue, optionIdx) => {
							return (
								<Grid item xs={12} sm={6} md={4} key={optionValue}>
									<FormControlLabel
										control={
											<Checkbox
												checked={serviceTypes.includes(optionValue)}
												onChange={(e) => {
													if (e.target.checked) {
														addServiceType(optionValue)
													} else {
														deleteServiceType(optionValue)
													}
												}}
											/>
										}
										label={optionValue}
									/>
								</Grid>
							);
						})
					}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider sx={{ my: 2 }} />
			</Grid>
		</>
	);
}