import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	ListItemText,
	Stack,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import { Breathing, Image } from "react-shimmer";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Iconify from "../components/iconify";
import { StyledNavItem, StyledNavItemIcon } from "../components/nav-section/styles";
import { icon } from "../layouts/dashboard/nav/config";
import { makeAPIRequest } from "../utils/apiHandler";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	AlbumResponseObj,
	APIResponseData,
	CreateAlbumResponse,
	GetAlbumPhotosResponse,
	GetPortfolioAlbumsResponse,
	PhotoResponseObj,
	UploadImageResponse
} from "../utils/apiResponses";
import {
	AddPhotoToAlbumBody,
	AddPhotoToAlbumParams,
	CreateAlbumBody,
	DeleteAlbumParams,
	DeletePhotoFromAlbumParams,
	FetchVendorPortfolioAlbumPhotosParams,
	FetchVendorPortfolioAlbumsParams,
	FetchVenuePortfolioAlbumPhotosParams,
	FetchVenuePortfolioAlbumsParams,
	NoParams
} from "../utils/apiRequests";
import ProfileContext from "../utils/profileContext";
import { S3ObjectMethods } from "../utils/commonTypes";
import { checkMultipleImageFileDimensions, createSlugFromIdentifiers, IMAGE_DIMENSIONS } from "../utils/commonUtils";
import { manageMedia } from "../utils/mediaHandler";
import PortfolioContext from "../utils/portfolioContext";
import { RequiredText } from "../components/common";
import { Helmet } from "react-helmet-async";
import ServiceContext from "../utils/serviceContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
interface ServiceData {
	vendorSlug?: string;
	venueSlug?: string;
}

type AlbumItemProps = {
	albumName: string;
	albumId: string;
	setActive: () => void
	refetchAlbums: () => void
};

type PhotoFormProps = {
	isOpen: boolean;
	albumId: string;
	onClose: () => void;
	onFilesUpload: (uploadedFiles: PhotoResponseObj[]) => void;
};

function PhotoForm({ isOpen, albumId, onClose, onFilesUpload }: PhotoFormProps) {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [imageLoader,setImageLoader]=useState(false)
	const [imageErrorSuccess,setImageErrorSuccess]=useState({error:false,success:false})

	const profileCtx = useContext(ProfileContext);

	const uploadFiles = useCallback(async () => {
		setImageLoader(true)
		if (profileCtx === null) {
			setImageLoader(false)
			setImageErrorSuccess({error:true,success:false})
			return;
		}

		const [allImagesPassed, imageResults] = await checkMultipleImageFileDimensions(selectedFiles, IMAGE_DIMENSIONS.PORTFOLIO_PHOTO);

		// if (!allImagesPassed) {
		// 	alert(`Please upload images of size (${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgWidth}x${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgHeight})`);
		// 	return;
		// }


		const { userType } = profileCtx;

		if (userType !== "SERVICE") {
			setImageLoader(false)
			setImageErrorSuccess({error:true,success:false})
			return;
		}

		const { linkedServiceType, linkedServiceSlug } = profileCtx;

		const objectKeyGenFn = (fileHandle: File, fileIdx: number, reqMethod: S3ObjectMethods) => {
			const { name } = fileHandle;

			const fileNameSegments = name.split(".");
			const fileName = fileNameSegments.slice(0, -1).join("");
			const fileExt = fileNameSegments[fileNameSegments.length - 1] || "";

			const fileSlug = createSlugFromIdentifiers(linkedServiceSlug, ...fileNameSegments);

			if (linkedServiceType === "VENUE") {
				return `media/venues/${linkedServiceSlug}/portfolio/albums/${albumId}/photos/${fileSlug}.${fileExt}`;
			} else {
				return `media/vendors/${linkedServiceSlug}/portfolio/albums/${albumId}/photos/${fileSlug}.${fileExt}`;
			}
		};

		const mediaResponse = await manageMedia({
			mediaFiles: selectedFiles,
			objectKeyGenFn: objectKeyGenFn,
			fileMethod: "PUT"
		});

		const photoResponses = await Promise.all(
			mediaResponse.map(async (mediaObj) => {
				const { isSuccess, isError, requestError, responseCode, responseData } = await makeAPIRequest<
					UploadImageResponse,
					AddPhotoToAlbumParams,
					AddPhotoToAlbumBody
				>({
					requestMethod: "POST",
					requestEndpoint: "/portfolio/albums/:albumId/photos",
					bodyParams: {
						objectKey: mediaObj.objectKey
					},
					urlParams: {
						albumId: albumId
					}
				});

				if (isSuccess) {
					const { responseStatus } = responseData;
					if (responseStatus === "SUCCESS") {
						const { photoId } = responseData;
						return {
							objectKey: mediaObj.objectKey,
							photoId: photoId
						};
					}
				}
				return undefined;
			})
		);

		const filteredPhotoResponses = photoResponses.filter((respObj) => {
			return respObj !== undefined;
		});

		const fetchedPhotos = await manageMedia({
			mediaFiles: selectedFiles,
			objectKeyGenFn: objectKeyGenFn,
			fileMethod: "GET"
		});

		const photoEntries = filteredPhotoResponses.map((photoResp) => {
			return [photoResp!.objectKey, photoResp!.photoId];
		});

		const photoKeyIdMap = Object.fromEntries(photoEntries) as { [objectKey: string]: string };

		const uploadConfirmedPhotos = fetchedPhotos.map((photoResp) => {
			const { objectKey, requestStatus, requestAcknowledged, presignedURL } = photoResp;
			if (!requestAcknowledged || requestStatus !== "ACK") {
				return undefined;
			}
			const photoId = photoKeyIdMap[objectKey];

			if (photoId === undefined) {
				return undefined;
			}

			return {
				photoId: photoId,
				photoURL: presignedURL,
				photoObjectKey: objectKey
			} satisfies PhotoResponseObj;
		});

		const filteredPhotos = uploadConfirmedPhotos.filter((photo) => {
			return photo !== undefined;
		});

		onFilesUpload(filteredPhotos as PhotoResponseObj[]);
		setImageLoader(false)
        setImageErrorSuccess({error:false,success:true})
		onClose();
		setImageErrorSuccess({error:false,success:false})
		onClose();
	}, [albumId, onClose, onFilesUpload, profileCtx, selectedFiles]);

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<ToastContainer/>
			<DialogTitle>Upload New Photos</DialogTitle>
			<DialogContent>
				<Grid container sx={{ alignItems: "center" }} spacing={2}>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<TextField
							type={"file"}
							inputProps={{
								accept: ".jpg, .jpeg, .png",
								multiple: true
							}}
							fullWidth
							onChange={(e) => {
								setImageLoader(false)
								setImageErrorSuccess({error:false,success:false})
								// @ts-ignore
								const fileList = e.target.files as FileList;

								const fileArray = Array.from(fileList);
								// const Oldcount = fileArray.length
								// fileArray = fileArray.filter((data, index) => data.size < 3000000)
								// if (Oldcount !== fileArray.length) {
								// 	console.log("check error")
								// 	toast.error(`${Math.floor(Oldcount - fileArray.length)} File Exceed The Size Limit , File Should Be Less Than 3 MB`)
								// }

								setSelectedFiles(fileArray);
							}}
						/>
						<div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"15px"}}><p style={{ color: "red", fontSize: "13px"}}>Allowed file types: JPG, JPEG, PNG </p>{imageLoader?<CircularProgress style={{width:"20px",height:"20px"}} />:null}{imageErrorSuccess.error?<ErrorIcon style={{width:"25px",height:"25px",color:"red"}}/>:null}{imageErrorSuccess.success?<CheckCircleIcon style={{width:"25px",height:"25px",color:"green"}}/>:null}</div>
					
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: "space-between",
					px: 4
				}}
			>
				<Button variant={"contained"} onClick={onClose} color={"error"}>
					<Iconify icon={"eva:close-fill"} />
					Close Form
				</Button>
				<Button
					variant={"contained"}
					sx={{
						my: 2
					}}
					onClick={uploadFiles}
					disabled={selectedFiles.length === 0}
				>
					<Iconify icon={"eva:checkmark-fill"} />
					Upload Photos
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function AlbumItem({ albumName, albumId, setActive, refetchAlbums }: AlbumItemProps) {
	const theme = useTheme();

	const [deleteActive, setDeleteActive] = useState(false);

	const { setPortfolioData } = useContext(PortfolioContext);

	const handleDeleteAlbum = async (albumId: string) => {
		setDeleteActive(true)
		const { isSuccess, responseData } = await makeAPIRequest<APIResponseData, DeleteAlbumParams>({
			requestEndpoint: "/portfolio/albums/:albumId",
			requestMethod: "DELETE",
			urlParams: {
				albumId: albumId
			}
		});
		if (isSuccess && responseData.responseStatus === "SUCCESS") {
			refetchAlbums();
			setPortfolioData((prevState) => {
				return {
					...prevState,
					portfolioAlbumCount: prevState.portfolioAlbumCount - 1
				};
			});
		}
		setDeleteActive(false)
	};

	return (
		<Grid item lg={3} md={6} sm={6} xs={12}>
			<ToastContainer />
			<StyledNavItem
				sx={{
					height: "auto",
					display: "flex",
					border: "1px dotted darkgrey",
					color: theme.palette.text.primary,
					cursor: "pointer",
					alignItems: "center",
					justifyContent: "space-between"
				}}
				onClick={() => {
					setActive();
				}}
			>
				<StyledNavItemIcon>{icon("ic_folder")}</StyledNavItemIcon>

				<ListItemText disableTypography primary={albumName} />
				<LoadingButton
					loading={deleteActive}
					aria-label="delete"
					color="error"
					onClick={(event) => {
						event.stopPropagation();
						handleDeleteAlbum(albumId);
					}}
				>
					<DeleteIcon />
				</LoadingButton>
			</StyledNavItem>
		</Grid>
	);
}

type AlbumFormProps = {
	isOpen: boolean;
	onClose: () => void;
	onAlbumCreate: (albumId: string, albumName: string) => void;
};

function AlbumForm({ isOpen, onClose, onAlbumCreate }: AlbumFormProps) {
	const [albumName, setAlbumName] = useState("");

	const [albumInvalid, setAlbumInvalid] = useState<boolean>(true);

	const createAlbum = useCallback(async () => {
		const { isSuccess, isError, requestError, responseCode, responseData } = await makeAPIRequest<
			CreateAlbumResponse,
			NoParams,
			CreateAlbumBody
		>({
			requestEndpoint: "/portfolio/albums",
			requestMethod: "POST",
			bodyParams: {
				albumName: albumName
			}
		});

		if (isSuccess) {
			const { responseStatus } = responseData;
			if (responseStatus === "SUCCESS") {
				const { albumId } = responseData;
				onAlbumCreate(albumId, albumName);
				onClose();
			} else if (responseStatus === "ERR_INVALID_BODY_PARAMS") {
				setAlbumInvalid(true);
			}
		}
	}, [albumName, onAlbumCreate, onClose]);

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Create New Album</DialogTitle>
			<DialogContent>
				<Grid container sx={{ alignItems: "center" }} spacing={2}>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<RequiredText>Album Name</RequiredText>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							placeholder={"New Album"}
							error={albumInvalid}
							onChange={(e) => {
								setAlbumName(e.target.value);
								if (e.target.value.trim().length === 0) {
									setAlbumInvalid(true);
								} else {
									setAlbumInvalid(false);
								}
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: "space-between",
					px: 4
				}}
			>
				<Button variant={"contained"} onClick={onClose} color={"error"}>
					<Iconify icon={"eva:close-fill"} />
					Close Form
				</Button>
				<Button
					variant={"contained"}
					sx={{
						my: 2
					}}
					onClick={createAlbum}
				>
					<Iconify icon={"eva:checkmark-fill"} />
					Create New Album
				</Button>
			</DialogActions>
		</Dialog>
	);
}

type AlbumViewProps = {
	albumId: string;
	albumName: string;
	onReturn: () => void;
};

function AlbumView({ albumId, albumName, onReturn }: AlbumViewProps) {
	const [albumPhotos, setAlbumPhotos] = useState<PhotoResponseObj[]>([]);
	const [showForm, setShowForm] = useState(false);

	const profileCtx = useContext(ProfileContext);

	const fetchAlbumPhotos = async () => {
		if (profileCtx == null) {
			return;
		}

		const { userType } = profileCtx;
		if (userType !== "SERVICE") {
			return;
		}

		const { linkedServiceSlug, linkedServiceType } = profileCtx;

		if (linkedServiceSlug === null || linkedServiceType === null) {
			return;
		}

		if (linkedServiceType === "VENDOR") {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetAlbumPhotosResponse,
				FetchVendorPortfolioAlbumPhotosParams
			>({
				requestEndpoint: "/vendors/:vendorSlug/portfolio/albums/:albumId/photos",
				requestMethod: "GET",
				urlParams: {
					vendorSlug: linkedServiceSlug,
					albumId: albumId
				}
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { albumPhotos: respPhotos } = responseData;
					setAlbumPhotos(respPhotos);
				}
			}
		} else {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetAlbumPhotosResponse,
				FetchVenuePortfolioAlbumPhotosParams
			>({
				requestEndpoint: "/venues/:venueSlug/portfolio/albums/:albumId/photos",
				requestMethod: "GET",
				urlParams: {
					venueSlug: linkedServiceSlug,
					albumId: albumId
				}
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { albumPhotos: respPhotos } = responseData;
					setAlbumPhotos(respPhotos);
				}
			}
		}
	};

	useEffect(() => {
		fetchAlbumPhotos();
	}, [albumId, profileCtx]);

	const handleDeletePhoto = async (photoId: string) => {
		const { isSuccess, responseData } = await makeAPIRequest<APIResponseData, DeletePhotoFromAlbumParams>({
			requestEndpoint: "/portfolio/photos/:photoId",
			requestMethod: "DELETE",
			urlParams: {
				photoId: photoId,
				albumId: albumId
			}
		});
		if (isSuccess && responseData.responseStatus === "SUCCESS") {
			await fetchAlbumPhotos();
		}
	};

	return (
		<>
			<PhotoForm
				isOpen={showForm}
				albumId={albumId}
				onClose={() => {
					setShowForm(false);
				}}
				onFilesUpload={(uploadedFiles) => {
					setAlbumPhotos((prevPhotos) => {
						return [...uploadedFiles, ...prevPhotos];
					});
				}}
			/>
			<Grid item xs={2}>
				<Button variant={"text"} onClick={onReturn}>
					<Iconify icon={"eva:arrow-back-fill"} />
				</Button>
			</Grid>
			<Grid
				item
				xs={4}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start"
				}}
			>
				<Typography variant={"h4"}>{albumName}</Typography>
			</Grid>
			<Grid
				item
				xs={6}
				sx={{
					justifyContent: "flex-end",
					display: "flex"
				}}
			>
				<Button
					variant={"contained"}
					onClick={() => {
						setShowForm(true);
					}}
					sx={{
						boxShadow: 0
					}}
				>
					<Iconify icon={"eva:plus-fill"} />
					Upload Photo
				</Button>
			</Grid>
			<Grid xs={12}>
				<Divider sx={{ my: 2 }} />
			</Grid>
			<Grid xs={12} sx={{ pt: 2 }}>
				<Grid container spacing={2}>
					{albumPhotos.map((photoObj) => {
						const { photoId, photoURL } = photoObj;
						return (
							<Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={photoId}>
								<Stack direction={"column"} sx={{}}>
									{/*// @ts-ignore*/}
									<Image
										src={photoURL}
										delay={0}
										fallback={<Breathing width={480} height={480} />}
										NativeImgProps={{
											alt: `Portfolio ${photoId}`,
											style: {
												borderRadius: 16
											}
										}}
									/>
									<Button
										color="error"
										onClick={() => handleDeletePhoto(photoId)}
									>
										Delete Photo <DeleteIcon />
									</Button>
								</Stack>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
		</>
	);
}

export default function PortfolioAlbums() {
	const profileCtx = useContext(ProfileContext);
	const { portfolioData, setPortfolioData } = useContext(PortfolioContext);
	const [showForm, setShowForm] = useState(false);
	const [activeAlbumIdx, setActiveAlbumIdx] = useState(-1);
	const serviceCtx = useContext(ServiceContext);

	const { serviceInfo } = serviceCtx;
	const { serviceType, serviceData } = serviceInfo;

	const [albumList, setAlbumList] = useState<AlbumResponseObj[]>([]);

	const fetchAlbums = async () => {
		if (profileCtx == null) {
			return;
		}

		const { userType } = profileCtx;
		if (userType !== "SERVICE") {
			return;
		}

		const { linkedServiceSlug, linkedServiceType } = profileCtx;

		if (linkedServiceSlug === null || linkedServiceType === null) {
			return;
		}

		if (linkedServiceType === "VENDOR") {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetPortfolioAlbumsResponse,
				FetchVendorPortfolioAlbumsParams
			>({
				requestEndpoint: "/vendors/:vendorSlug/portfolio/albums",
				requestMethod: "GET",
				urlParams: {
					vendorSlug: linkedServiceSlug
				}
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioAlbums } = responseData;
					setAlbumList(portfolioAlbums);
				}
			}
		} else {
			const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
				GetPortfolioAlbumsResponse,
				FetchVenuePortfolioAlbumsParams
			>({
				requestEndpoint: "/venues/:venueSlug/portfolio/albums",
				requestMethod: "GET",
				urlParams: {
					venueSlug: linkedServiceSlug
				}
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { portfolioAlbums } = responseData;
					setAlbumList(portfolioAlbums);
				}
			}
		}
	};

	useEffect(() => {
		fetchAlbums();
	}, [profileCtx]);

	const noAlbumsToDisplay = Object.keys(albumList).length === 0;

	return (
		<>
			<Helmet>
				<title> Albums | Mangal Weddings Dashboard </title>
			</Helmet>
			<Stack spacing={2}>
				{activeAlbumIdx === -1 ? (
					<>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant={"h4"}>
									{`Portfolio - Albums (${portfolioData.portfolioAlbumCount})`}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									justifyContent: "flex-end",
									display: "flex"
								}}
							>
								<Button
									variant={"contained"}
									onClick={() => {
										setShowForm(true);
									}}
									sx={{
										boxShadow: 0
									}}
								>
									<Iconify icon={"eva:plus-fill"} />
									Create New Album
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									Albums are a collection of photos grouped by events, or locations. Create or add
									photos to your albums to collectively display your best works of art to the user
								</Typography>
							</Grid>
							<AlbumForm
								isOpen={showForm}
								onClose={() => {
									setShowForm(false);
								}}
								onAlbumCreate={(albumId, albumName) => {
									setAlbumList((prevList) => {
										return [
											{
												albumId: albumId,
												albumName: albumName,
												albumImageCount: 0
											},
											...prevList
										];
									});
									setPortfolioData((prevData) => {
										return {
											...prevData,
											portfolioAlbumCount: prevData.portfolioAlbumCount + 1
										};
									});
								}}
							/>
						</Grid>
						<Divider />
					</>
				) : null}
				<Grid container spacing={1} sx={{ width: '100%' }}>
					{noAlbumsToDisplay ? (
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<Box>
								<Typography variant={"h6"}>
									<i>Create an album to get started</i>
								</Typography>
							</Box>
						</Grid>
					) : activeAlbumIdx !== -1 ? (
						<AlbumView
							albumId={albumList[activeAlbumIdx].albumId}
							albumName={albumList[activeAlbumIdx].albumName}
							onReturn={() => {
								setActiveAlbumIdx(-1);
							}}
						/>
					) : (
						albumList.map((albumData, albumIdx) => {
							return (
								<AlbumItem
									key={albumData.albumId}
									albumName={albumData.albumName}
									albumId={albumData.albumId}
									// @ts-ignore
									setActive={() => {
										setActiveAlbumIdx(albumIdx);
									}}
									refetchAlbums={() => {
										fetchAlbums();
									}}
								/>
							);
						})
					)}
				</Grid>
			</Stack>
		</>
	);
}
