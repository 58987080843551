import { S3ObjectMethods } from "./commonTypes";
import { makeAPIRequest } from "./apiHandler";
import { APIResponseData, GetPresignedURLResponse } from "./apiResponses";
import { GetPresignedURLRequestBody, MediaCallbackParams, NoParams } from "./apiRequests";
import { S3RequestSchema } from "./mongoSchema";
import imageCompression from 'browser-image-compression';

type MediaManagerArgs = {
	mediaFiles: File[],
	objectKeyGenFn: (fileHandle: File, fileIdx: number, reqMethod: Exclude<S3ObjectMethods, "GET">) => string
	fileMethod: S3ObjectMethods | S3ObjectMethods[]
}

type FileRequest = {
	fileHandle: File,
	objectKey: string,
	requestId: string,
	requestMethod: S3ObjectMethods
	presignedURL: string,
	requestAcknowledged: boolean,
	requestStatus: S3RequestSchema["requestStatus"],
}

export async function manageMedia(args: MediaManagerArgs) {
	try {
		let { mediaFiles, objectKeyGenFn, fileMethod } = args;
		fileMethod = Array.isArray(fileMethod) ? fileMethod : (Array(mediaFiles.length).fill(fileMethod)) as (Exclude<S3ObjectMethods, "GET">)[];

		const fileRequests: FileRequest[] = [];

		await Promise.all((mediaFiles).map(async (fileObj, fileIdx) => {
			const reqMethod = (fileMethod as (Exclude<S3ObjectMethods, "GET">)[])[fileIdx];

			const objectKey = objectKeyGenFn(
				fileObj,
				fileIdx,
				reqMethod
			);

			const {
				isSuccess: isPresignedSuccess,
				isError: isPresignedError,
				responseCode: presignedResponseCode,
				responseData: presignedResponseData,
				requestError: presignedRequestError
			} = await makeAPIRequest<GetPresignedURLResponse, NoParams, GetPresignedURLRequestBody>({
				requestMethod: "POST",
				requestEndpoint: "/media/presigned-url",
				bodyParams: {
					requestMethod: reqMethod,
					objectKey: objectKey,
					objectSizeBytes: fileObj.size,
					objectFileName: fileObj.name,
					objectContentType: fileObj.type
				}
			});


			if (isPresignedSuccess) {
				const { responseStatus: presignedResponseStatus } = presignedResponseData;
				if (presignedResponseStatus === "SUCCESS") {
					const { presignedUrl, requestId } = presignedResponseData;


					fileRequests.push({
						fileHandle: fileObj,
						objectKey: objectKey,
						presignedURL: presignedUrl,
						requestId: requestId,
						requestMethod: reqMethod,
						requestStatus: "WAIT",
						requestAcknowledged: false
					});
				}
			}
		}));

		if (fileRequests.length !== mediaFiles.length) {
			return fileRequests;
		}

		const mappedRequests = await Promise.all(
			fileRequests.map(
				async (fileRequest, fileIdx): Promise<FileRequest> => {
					const {
						fileHandle,
						requestMethod,
						requestAcknowledged,
						requestId,
						requestStatus,
						presignedURL
					} = fileRequest;

					if (requestMethod === "GET") {
						return {
							...fileRequest,
							requestAcknowledged: true,
							requestStatus: "ACK"
						};
					}

					const { size, name, type } = fileHandle;
                console.log(presignedURL,"presigned url")
					try {
						const compressedFile = await imageCompression(fileHandle, { maxSizeMB: 0.3 });

						const s3Response = await fetch(
							presignedURL,
							{
								body:compressedFile,
								method: requestMethod
							}
						);
						console.log(s3Response,"s3 responsce")
						if (s3Response.ok) {
							const {
								isSuccess,
								requestError,
								isError,
								responseCode,
								responseData
							} = await makeAPIRequest<APIResponseData, MediaCallbackParams, NoParams>({
								requestEndpoint: `/media/requests/:requestId`,
								requestMethod: requestMethod,
								urlParams: {
									requestId: requestId
								}
							});

							if (isSuccess) {
								const { responseStatus } = responseData;
								if (responseStatus === "SUCCESS") {
									return {
										...fileRequest,
										requestStatus: "ACK",
										requestAcknowledged: true
									};
								}
							}

							return {
								...fileRequest,
								requestStatus: "ERR",
								requestAcknowledged: false
							};
						} else {
							throw new Error("Error uploading file to URL");
						}
					} catch (err) {
						console.error(err);

						const {
							isSuccess,
							requestError,
							isError,
							responseCode,
							responseData
						} = await makeAPIRequest<APIResponseData, MediaCallbackParams>({
							requestEndpoint: `/media/requests/:requestId`,
							requestMethod: "POST",
							urlParams: {
								requestId: requestId
							}
						});

						if (isSuccess) {
							const { responseStatus } = responseData;
							if (responseStatus === "SUCCESS") {
								return {
									...fileRequest,
									requestStatus: "ERR",
									requestAcknowledged: true
								};
							}
						}
						return {
							...fileRequest,
							requestStatus: "ERR",
							requestAcknowledged: false
						};
					}
				}
			)
		);

		return mappedRequests;
	} catch (err) {
		return [] as FileRequest[];
	}
}