import Chat from "../sections/chat/Chat";
import { Helmet } from "react-helmet-async";

export default function ChatPage() {
	return (
		<>
			<Helmet>
				<title> Chat | Mangal Weddings Dashboard </title>
			</Helmet>
			<Chat />
		</>
	);
}