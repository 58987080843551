import { PortfolioSchema } from "./mongoSchema";
import { createContext, Dispatch, SetStateAction } from "react";

export type PortfolioData = Omit<PortfolioSchema, "serviceType" | "serviceSlug">

type PortfolioContextType = & {
	portfolioData: PortfolioData,
	setPortfolioData: Dispatch<SetStateAction<PortfolioData>>,
}

const PortfolioContext = createContext<PortfolioContextType>({
	portfolioData: {
		portfolioVideoCount: 0,
		portfolioPhotoCount: 0,
		portfolioAlbumCount: 0
	},
	setPortfolioData: () => {
		throw new Error("Attempted to set portfolio context before initialization");
	}
});

export default PortfolioContext;