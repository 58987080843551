import { useLocation, useNavigate } from "react-router-dom";
// routes
import Router from "./router";
// theme
// components
import ScrollToTop from "./components/scroll-to-top";
import { useCallback, useEffect, useState } from "react";
import AuthContext, { AuthData } from "./utils/authContext";
import { makeAPIRequest } from "./utils/apiHandler";
import {
	APIResponseData,
	AuthCheckResponse,
	CreateVendorResponse,
	CreateVenueResponse,
	GetPortfolioMetadataResponse,
	GetUserProfileResponse,
	GetVendorResponse,
	GetVenueResponse,
	UpdateVendorResponse,
	UpdateVenueResponse
} from "./utils/apiResponses";
import {
	CreateVendorBody,
	CreateVenueBody,
	FetchVendorPortfolioParams,
	FetchVenuePortfolioParams,
	GetVendorParams,
	GetVenueParams,
	LinkServiceBody,
	NoParams,
	UpdateVendorBody,
	UpdateVendorParams,
	UpdateVenueBody,
	UpdateVenueParams
} from "./utils/apiRequests";
import ProfileContext, { ProfileContextType } from "./utils/profileContext";
import ServiceContext, { ServiceData } from "./utils/serviceContext";
import { VendorSchema, VenueSchema } from "./utils/mongoSchema";
import PortfolioContext, { PortfolioData } from "./utils/portfolioContext";
import { AUTH_TOKEN_LS_NAME, createErrorString, ServicePremiumStatus } from "./utils/commonUtils";
import { Alert, Snackbar } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "../src/redux/store";

// ----------------------------------------------------------------------

export default function App() {
	const [authState, setAuthState] = useState<AuthData>({
		authStatus: "UNAUTHENTICATED"
	});

	const [profileState, setProfileState] = useState<ProfileContextType>(null);

	const [serviceState, setServiceState] = useState<ServiceData>({
		serviceType: null,
		serviceData: null,
		isPartialInfo: true,
		invalidFields: []
	});

	const [portfolioState, setPortfolioState] = useState<PortfolioData>({
		portfolioPhotoCount: 0,
		portfolioAlbumCount: 0,
		portfolioVideoCount: 0
	});

	const [profileFetchComplete, setProfileFetchComplete] = useState<boolean>(false);
	const [serviceFetchComplete, setServiceFetchComplete] = useState<boolean>(false);

	const location = useLocation();
	const navigate = useNavigate();

	const [alertData, setAlertData] = useState<{
		showAlert: boolean;
		alertText: string;
		alertType: "error" | "success";
	}>({
		showAlert: false,
		alertText: "",
		alertType: "error"
	});

	useEffect(() => {
		const authRequest = async () => {
			const { isSuccess, isError, responseCode, requestError, responseData } = await makeAPIRequest<
				AuthCheckResponse,
				NoParams,
				NoParams,
				NoParams
			>({
				requestEndpoint: "/auth/refresh",
				requestMethod: "POST"
			});

			if (isSuccess) {
				const { responseStatus } = responseData;
				if (responseStatus === "SUCCESS") {
					const { authStatus } = responseData;
					if (authStatus === "AUTHENTICATED") {
						const { authData } = responseData;
						setAuthState({
							authData: authData,
							authStatus: authStatus
						});
					} else {
						setAuthState({
							authStatus: "UNAUTHENTICATED"
						});
					}
				} else {
					setAuthState({
						authStatus: "UNAUTHENTICATED"
					});
				}
			}
		};
		authRequest().catch((err) => {
			console.error(err);
			setAuthState({
				authStatus: "UNAUTHENTICATED"
			});
		});
	}, [location.pathname]);

	const setAuthValue = useCallback(
		(authValue: AuthData) => {
			console.debug(authValue);
			setAuthState(authValue);
		},
		[setAuthState]
	);

	useEffect(() => {
		return () => {
			window.localStorage.removeItem(AUTH_TOKEN_LS_NAME);
		};
	}, []);

	useEffect(() => {
		const { authStatus } = authState;
		if (authStatus === "UNAUTHENTICATED" && location.pathname !== "/signup") {
			navigate("/login");
			return;
		}

		if (location.pathname === "/signup") {
			return;
		}

		if (!profileFetchComplete) {
			const fetchProfile = async () => {
				const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
					GetUserProfileResponse,
					NoParams,
					NoParams,
					NoParams
				>({
					requestEndpoint: "/profile",
					requestMethod: "GET"
				});

				if (isSuccess) {
					const { responseStatus } = responseData;
					if (responseStatus === "SUCCESS") {
						const { userProfile } = responseData;
						setProfileState(userProfile);
						setProfileFetchComplete(true);
					} else {
						setProfileState(null);
					}
				}
			};

			fetchProfile().catch((err) => {
				setProfileState(null);
				setAuthValue({
					authStatus: "UNAUTHENTICATED"
				});
			});
		}
	}, [authState.authStatus, location.pathname, profileFetchComplete]);

	useEffect(() => {
		if (!serviceFetchComplete && profileFetchComplete) {
			const fetchServiceData = async () => {
				if (profileState) {
					const { userType } = profileState;
					if (userType === "SERVICE") {
						const { linkedServiceSlug, linkedServiceType } = profileState;
						if (linkedServiceType === null && linkedServiceSlug === null) {
							setServiceState({
								serviceData: null,
								serviceType: null,
								isPartialInfo: true,
								invalidFields: []
							});
							return;
						}
						switch (linkedServiceType) {
							case "VENDOR": {
								const { isSuccess, isError, responseCode, responseData, requestError } =
									await makeAPIRequest<GetVendorResponse, GetVendorParams, NoParams, NoParams>({
										requestMethod: "GET",
										requestEndpoint: "/vendors/:vendorSlug/",
										urlParams: {
											vendorSlug: linkedServiceSlug
										}
									});
								if (isSuccess) {
									const { responseStatus } = responseData;
									if (responseStatus === "SUCCESS") {
										const { vendorData } = responseData;

										setServiceState({
											serviceType: "VENDOR",
											// @ts-ignore
											serviceData: {
												...vendorData,
												vendorSlug: linkedServiceSlug
											},
											isPartialInfo: false,
											invalidFields: []
										});

										setServiceFetchComplete(true);
									}
								}
								break;
							}
							case "VENUE": {
								const { isSuccess, isError, responseCode, responseData, requestError } =
									await makeAPIRequest<GetVenueResponse, GetVenueParams, NoParams, NoParams>({
										requestMethod: "GET",
										requestEndpoint: "/venues/:venueSlug/",
										urlParams: {
											venueSlug: linkedServiceSlug
										}
									});
								if (isSuccess) {
									const { responseStatus } = responseData;
									if (responseStatus === "SUCCESS") {
										const { venueData } = responseData;

										setServiceState({
											serviceType: "VENUE",
											// @ts-ignore
											serviceData: {
												...venueData,
												venueSlug: linkedServiceSlug
											},
											isPartialInfo: false,
											invalidFields: []
										});

										setServiceFetchComplete(true);
									}
								}
								break;
							}
						}

						if (linkedServiceType === "VENDOR") {
							const { isSuccess, isError, requestError, responseData, responseCode } =
								await makeAPIRequest<GetPortfolioMetadataResponse, FetchVendorPortfolioParams>({
									requestEndpoint: "/vendors/:vendorSlug/portfolio",
									requestMethod: "GET",
									urlParams: {
										vendorSlug: linkedServiceSlug
									}
								});
							if (isSuccess && responseData?.responseStatus === "SUCCESS") {
								const { portfolioMetadata } = responseData;
								setPortfolioState(portfolioMetadata);
							}
						} else {
							const { isSuccess, isError, requestError, responseData, responseCode } =
								await makeAPIRequest<GetPortfolioMetadataResponse, FetchVenuePortfolioParams>({
									requestEndpoint: "/venues/:venueSlug/portfolio",
									requestMethod: "GET",
									urlParams: {
										venueSlug: linkedServiceSlug
									}
								});
							if (isSuccess && responseData.responseStatus === "SUCCESS") {
								const { portfolioMetadata } = responseData;
								setPortfolioState(portfolioMetadata);
							}
						}
					}
				}

				setServiceFetchComplete(true);
			};

			fetchServiceData().catch((err) => {
				console.error(err);
				setAuthState({
					authStatus: "UNAUTHENTICATED"
				});
			});
		}
	}, [profileFetchComplete, serviceFetchComplete]);

	useEffect(() => {
		const { authStatus } = authState;
		if (authStatus === "AUTHENTICATED") {
			const {
				authData: { userType }
			} = authState;

			if (userType !== "SERVICE") {
				setAuthState({
					authStatus: "UNAUTHENTICATED"
				});
				navigate("/login");
			}
		}
	}, [authState, navigate, setAuthState]);

	const saveServiceInfo = async () => {
		try {
			const { serviceType, serviceData, isPartialInfo, invalidFields } = serviceState;
			if (profileState === null) {
				return false;
			}
			if (isPartialInfo) {
				if (serviceType === null || serviceData === null) {
					return false;
				}
				if (serviceType === "VENDOR") {
					const {
						vendorName,
						vendorDescription,
						vendorCity,
						vendorCategory,
						vendorSubcategory,
						vendorServiceRate,
						vendorServiceTypes,
						vendorMetadata,
						vendorListingStatus,
						vendorPackages
					} = serviceData;

					const { isSuccess, isError, requestError, responseData, responseCode } = await makeAPIRequest<
						CreateVendorResponse,
						NoParams,
						CreateVendorBody
					>({
						requestMethod: "POST",
						requestEndpoint: "/vendors",
						bodyParams: {
							vendorName,
							vendorDescription,
							vendorCity,
							vendorCategory,
							vendorSubcategory,
							vendorServiceRate,
							vendorServiceTypes,
							vendorMetadata,
							vendorListingStatus,
							vendorViewCount: 0,
							vendorCoverImageObjectKey: "static/cover-image.svg",
							vendorPackages
						} satisfies CreateVendorBody
					});
					if (!isSuccess) {
						return false;
					}
					const { responseStatus } = responseData;
					if (responseStatus !== "SUCCESS") {
						return false;
					}
					const { vendorSlug } = responseData;

					const { isSuccess: linkSuccess, responseData: linkData } = await makeAPIRequest<
						APIResponseData,
						NoParams,
						LinkServiceBody
					>({
						requestMethod: "POST",
						requestEndpoint: "/profile/link-service",
						bodyParams: {
							serviceType: "VENDOR",
							serviceSlug: vendorSlug
						}
					});

					if (linkSuccess && linkData?.responseStatus === "SUCCESS") {
						setAlertData({
							showAlert: true,
							alertText: "Listing Created Successfully!",
							alertType: "success"
						});
						setTimeout(() => {
							window.location.reload();
						}, 1000);
						return true;
					}
					return false;
				} else {
					{
						const {
							venueBaseRate,
							venueCity,
							venuePricingRate,
							venueMinCapacity,
							venueMaxCapacity,
							venueDescription,
							venueEnvironment,
							venueListingStatus,
							venueLocations,
							venueLocationTypes,
							venueRatingAverage,
							venueRatingCount,
							venueName,
							venueMetadata
						} = serviceData;

						// console.log("serviceData", serviceData);

						const { isSuccess, isError, requestError, responseData, responseCode } = await makeAPIRequest<
							CreateVenueResponse,
							NoParams,
							CreateVenueBody
						>({
							requestMethod: "POST",
							requestEndpoint: "/venues",
							bodyParams: {
								venueCity,
								venueName,
								venueMetadata,
								venueDescription,
								venueLocations,
								venueBaseRate,
								venuePricingRate,
								venueLatitude: 0,
								venueLongitude: 0
							} satisfies CreateVenueBody
						});
						if (!isSuccess) {
							return false;
						}
						const { responseStatus } = responseData;
						if (responseStatus !== "SUCCESS") {
							return false;
						}
						const { venueSlug } = responseData;

						const { isSuccess: linkSuccess, responseData: linkData } = await makeAPIRequest<
							APIResponseData,
							NoParams,
							LinkServiceBody
						>({
							requestMethod: "POST",
							requestEndpoint: "/profile/link-service",
							bodyParams: {
								serviceType: "VENUE",
								serviceSlug: venueSlug
							}
						});

						if (linkSuccess && linkData?.responseStatus === "SUCCESS") {
							setAlertData({
								showAlert: true,
								alertText: "Listing Created Successfully!",
								alertType: "success"
							});
							setTimeout(() => {
								window.location.reload();
							}, 1000);
						}
					}
				}
			}
			if (serviceType === null || serviceData === null) {
				return false;
			}
			if (serviceType === "VENDOR") {
				const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
					UpdateVendorResponse,
					UpdateVendorParams,
					UpdateVendorBody
				>({
					requestMethod: "PUT",
					requestEndpoint: "/vendors/:vendorSlug/",
					urlParams: {
						vendorSlug: serviceData.vendorSlug
					},
					bodyParams: {
						...serviceData
					} satisfies VendorSchema
				});

				if (isSuccess) {
					const { responseStatus } = responseData;
					if (responseStatus === "SUCCESS") {
						setProfileFetchComplete(false);
						setServiceFetchComplete(false);

						setAlertData({
							showAlert: true,
							alertText: "Your listing was updated successfully!",
							alertType: "success"
						});

						return true;
					} else if (
						responseStatus === "ERR_INVALID_BODY_PARAMS" ||
						responseStatus === "ERR_MISSING_BODY_PARAMS"
					) {
						const invalidParams = (responseData?.invalidParams || []) as (keyof VendorSchema)[];
						const missingParams = (responseData?.missingParams || []) as (keyof VendorSchema)[];
						const mixedParams = [...invalidParams, ...missingParams];

						setServiceState({
							...serviceState,
							invalidFields: mixedParams
						});

						setAlertData({
							showAlert: true,
							alertText: createErrorString({
								serviceType: "VENDOR",
								invalidFields: mixedParams
							}),
							alertType: "error"
						});
					}
				}
				return false;
			} else {
				const { isSuccess, isError, responseCode, responseData, requestError } = await makeAPIRequest<
					UpdateVenueResponse,
					UpdateVenueParams,
					UpdateVenueBody
				>({
					requestMethod: "PUT",
					requestEndpoint: "/venues/:venueSlug/",
					urlParams: {
						venueSlug: serviceData.venueSlug
					},
					bodyParams: {
						...serviceData
					} satisfies VenueSchema
				});

				if (isSuccess) {
					const { responseStatus } = responseData;
					if (responseStatus === "SUCCESS") {
						setProfileFetchComplete(false);
						setServiceFetchComplete(false);

						setAlertData({
							showAlert: true,
							alertText: "Your listing was updated successfully!",
							alertType: "success"
						});

						return true;
					} else if (
						responseStatus === "ERR_INVALID_BODY_PARAMS" ||
						responseStatus === "ERR_MISSING_BODY_PARAMS"
					) {
						const invalidParams = (responseData?.invalidParams || []) as (keyof VenueSchema)[];
						const missingParams = (responseData?.missingParams || []) as (keyof VenueSchema)[];
						const mixedParams = [...invalidParams, ...missingParams];

						setServiceState({
							...serviceState,
							invalidFields: mixedParams
						});

						setAlertData({
							showAlert: true,
							alertText: createErrorString({
								serviceType: "VENUE",
								invalidFields: mixedParams
							}),
							alertType: "error"
						});
					}
				}
				return false;
			}
		} catch (err) {
			return false;
		}
	};

	const logoutUser = useCallback(async () => {
		const { authStatus } = authState;
		if (authStatus === "UNAUTHENTICATED") {
			return;
		}

		const { isSuccess, isError, requestError, responseCode, responseData } = await makeAPIRequest({
			requestMethod: "POST",
			requestEndpoint: "/auth/logout"
		});

		if (isSuccess && responseData) {
			if (responseData.responseStatus === "SUCCESS") {
				localStorage.removeItem(AUTH_TOKEN_LS_NAME);
				setAuthState({
					authStatus: "UNAUTHENTICATED"
				});
			}
		}

		window.location.reload();
	}, [authState]);

	return (
		<AuthContext.Provider
			value={{
				...authState,
				setAuthValue: setAuthValue,
				logoutUser: logoutUser
			}}
		>
			<ProfileContext.Provider value={profileState}>
				<ServiceContext.Provider
					value={{
						serviceInfo: serviceState,
						setServiceInfo: setServiceState,
						saveServiceInfo: saveServiceInfo
					}}
				>
					<PortfolioContext.Provider
						value={{
							portfolioData: portfolioState,
							setPortfolioData: setPortfolioState
						}}
					>
						<ScrollToTop />
						<Provider store={store}>
						<Router />
						</Provider>
						<Snackbar
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center"
							}}
							autoHideDuration={10000}
							open={alertData.showAlert}
							onClose={() => {
								setAlertData((prevState) => {
									return {
										...prevState,
										showAlert: false
									};
								});
							}}
						>
							<Alert severity={alertData.alertType} variant={"filled"}>
								{alertData.alertText}
							</Alert>
						</Snackbar>
					</PortfolioContext.Provider>
				</ServiceContext.Provider>
			</ProfileContext.Provider>
		</AuthContext.Provider>
	);
}
