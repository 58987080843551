import { createSlice } from "@reduxjs/toolkit";

export const loginLoaderSlice = createSlice({
	initialState: {
		loader: true
	},
	name: "loginloader",
	reducers: {
		activateLoader: (state) => {
			return {
				loader: true
			};
		},
		deactivateLoader: (state) => {
			return {
				loader: false
			};
		}
	}
});
